import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { AuthUserApi } from "../../../utils/api";
import { toast } from "react-toastify";
import { UserContext } from "../../../Context/UserContext";
import { getEncryptId } from "../../../utils/secure";
import { useAccount, useDisconnect } from "wagmi";
import WalletConnect from "../../../Components/WalletConnect";

const WallateModal = (props) => {
  const { show, setShow, walletAddr } = props;
  const { address: account } = useAccount();
  const [walletAddress, setWalletAddress] = useState(account);
  const [updateWalletAdd, setUpdateWalletAddress] = useState("");
  const { user } = useContext(UserContext);
  const { id } = user?.user || {};
  const [showIp, setShowIp] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const { disconnect } = useDisconnect();
  const [errors, setErrors] = useState([]);

  const handleClose = () => {
    disconnect();
    setShow(false);
    setShowIp(false);
    setWalletAddress("");
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setWalletAddress(value);
  };

  const handleButton = () => {
    setShowIp(true);
    disconnect();
    setWalletAddress("");
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!walletAddress) {
      formIsValid = false;
      errors["wallet_address"] = "Wallet address is required.";
    }
    setErrors({ ...errors, errors });
    return formIsValid;
  };

  const submit = async (e) => {
    try {
      e.preventDefault();
      if (handleValidation()) {
        var saveResponse = await AuthUserApi.put(
          `/user/user-update/${getEncryptId(id)}`,
          { wallet_address: walletAddress }
        );
        const { status, data } = saveResponse;

        if (status === 201 && data.user !== undefined) {
          localStorage.setItem("wtAddress", data.user.wallet_address);
          window.location.reload();
          setUpdateWalletAddress(data.user.wallet_address);
          disconnect();
          setShow(false);
          setShowIp(false);
          toast.success("Wallet Address Updated Succesfully...");
        }
      }
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
      const { response } = e;
      if (
        response !== undefined &&
        Object.keys(response.data).length &&
        response.data.error !== undefined
      ) {
        setErrors(response.data.error); // if error from server side
      }
    }
  };

  useEffect(() => {
    setWalletAddress(account);
    account ? setReadOnly(true) : setReadOnly(false);
  }, [account]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="text-center"
      >
        <Modal.Body className="p-4">
          <div>
            <WalletConnect />
            <div className="text-center my-3 d-md-inline-block d-block">
              <p className="text-center border d-inline-block mx-auto py-2 px-3 rounded-circle fw-bold bg-light mb-0">
                Or
              </p>
            </div>
            <button
              type="button"
              className="btn bg-gradient-primary mb-0 me-0 ms-md-3"
              onClick={() => handleButton()}
            >
              Add Manually
            </button>
            <p className="mb-0 mt-3 text-capitalize fw-bold">
              current wallet address
            </p>
            <p className="btn bg-succsess-info my-3">
              {updateWalletAdd ? updateWalletAdd : walletAddr}
            </p>
            {(showIp || walletAddress) && (
              <div className="text-start mt-3">
                <input
                  readOnly={readOnly}
                  className="form-control form-control-sm form-control-lg mb-0"
                  placeholder="New Wallet Address"
                  onChange={handleChange}
                  aria-label="New Wallet Address"
                  type="text"
                  value={walletAddress}
                  name=""
                />
                {errors.wallet_address && (
                  <small className="text-danger">{errors.wallet_address}</small>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
        {(showIp || walletAddress) && (
          <Modal.Footer>
            <Button
              variant="danger"
              className="px-4 mb-0"
              onClick={(e) => submit(e)}
            >
              Update
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default WallateModal;
