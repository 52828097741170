import React, { useEffect, useState } from 'react'
import ListTr from './ListTr'
import AdminAuthLayout from '../../../Layout/AdminAuthLayout';
import Pagination from "react-js-pagination";
import { AuthApi } from '../../../utils/api';
import SearchBr from '../../../Components/SearchBr';

const List = () => {

    const [customers, setCustomers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(1);
    const [parameter, setParameter] = useState();

    const getData = async (pgNO) => {
        try {
        const listResponse = await AuthApi.get(`/admin/users?pageNo=${pgNO}`);
        const { status, data } = listResponse;
        if (status === 200 && data.users !== undefined) {
            setCustomers(data.users);
            const { pageData: { per_page, total_record } } = data || {};
            setPerPage(per_page);
            setTotalRecords(total_record);
        }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const getFilterData = async (pgNO) => {
        try {
            const listResponse = await AuthApi.get(`/admin/users?pageNo=${pgNO}`, {
                params: { parameter: parameter }
            });
            const { status, data } = listResponse;
            if (status === 200 && data.users !== undefined) {
                setCustomers(data.users);
                const { pageData: { per_page, total_record } } = data || {};
                setPerPage(per_page);
                setTotalRecords(total_record);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            // setFilterErr();
        }
    }

    const handleSearch = event => {
        const { value } = event.target;
        setParameter(value);
    };

    const getClickedPageNo = (pageNumber) => {
        setCurrentPage(pageNumber);
        parameter !== '' ? getFilterData(pageNumber) : getData(pageNumber);
    }

    useEffect(() => {
        getData(currentPage)
    }, []);

    return (
        <>
            <AdminAuthLayout>
                <SearchBr 
                    Pageheading="Customer list"
                    getFilterData={getFilterData}
                    handleSearch={handleSearch}
                    parameter={parameter}
                />
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header pb-0">
                                    <div className="d-flex align-items-center">
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table align-items-center mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                                                                Sr. No.
                                                            </th>
                                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Name
                                                            </th>
                                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Mobile Number
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Email Address
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Role
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Country
                                                            </th>
                                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                ACTION
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            customers && customers.length > 0 && customers.map((customer, indx) => {
                                                            return(
                                                                <ListTr
                                                                    customer={customer}
                                                                    key={indx}
                                                                    indx={indx}
                                                                />
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center justify-content-md-end pt-3">
                                            {
                                                // totalRecords > 10 &&
                                                <Pagination
                                                    activePage={currentPage}
                                                    prevPageText='prev'
                                                    nextPageText='next'
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    pageRangeDisplayed={5}
                                                    itemsCountPerPage={perPage}
                                                    totalItemsCount={totalRecords}
                                                    onChange={getClickedPageNo}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminAuthLayout>
        </>
    )
}

export default List