import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useParams } from 'react-router-dom';
import UserAuthLayout from '../../Layout/UserAuthLayout';

const TransactionSuccessFull = () => {
    const [copied, setCopied] = useState(false);
    const { id } = useParams();

    return (
        <>
            <UserAuthLayout>
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center mt-5">
                        <div className="col-12 col-md-9">
                            <div className="card mb-4 text-center py-4">
                                <div className="card-header pb-0 px-3">
                                    <h4 className="mb-3">Your escrow request has been sent to your counterparty!</h4>
                                    <p>We will update once they have agreed to the escrow request.</p>
                                </div>
                                <div className="card-body pt-3 p-3">
                                    <div className="card">
                                        <div className="card-body pt-4 p-3">
                                            <h6 className="mb-4">Share the agreement link with your counterparty</h6>
                                            <div className="input-group">
                                                <input type="text" value={`${window.origin}/transaction-request/${id}`} className="form-control" />
                                                <CopyToClipboard className="btn btn-warning text-sm px-2 px-md-3 mb-0" text={`${window.origin}/transaction-request/${id}`} onCopy={() => setCopied(true)}>
                                                    <span>Copy Link</span>
                                                </CopyToClipboard>
                                            </div>
                                            {copied ? <span className="text-end d-block pe-3 pt-2" style={{color: 'red'}}>Copied.</span> : null}
                                            <p className="mt-4">Copy and paste the link below into email, chats or apps.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer pb-0 px-3">
                                    <Link to="/create-eccrow/0" className="btn btn-outline-default me-0 me-md-4">Create Another Escrow</Link>
                                    <Link to="/agreement-listing" className="btn bg-gradient-warning">Back to Transaction</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UserAuthLayout>
        </>
    )
}

export default TransactionSuccessFull