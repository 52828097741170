import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GuestLayout from "../../../Layout/GuestLayout";
import { GuestApi } from "../../../utils/api";
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = (props) => {
    const [users, setUsers] = useState([]);

    const { email } = useParams();

    const getData = async () => {
        try {
            var listResponse = await GuestApi.post('/user/get-users-email', { email });
            const { status, data } = listResponse;
            if (status === 200 && data.users !== undefined) {
                setUsers(data.users);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <GuestLayout
                Title="Attention is the new currency"
                Subtitle="The more effortless the writing looks, the more effort the
                        writer actually put into the process."
                FormTitle={'Reset Password'}
                FormSubTitle={'Enter your password and confirm password'}
            >
                <ResetPasswordForm
                    users={users}
                />
            </GuestLayout>
        </>
    );
}
export default ResetPassword;
