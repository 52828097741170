import React from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import useForm from "../../../useForm";
import { getEncryptId } from "../../../utils/secure";
import { toast } from "react-toastify";
import { AuthApi } from "../../../utils/api";
import validate from "../../../validate/validateOrder";
import moment from "moment";
import { useState } from "react";
import { currencies } from "../../../utils/constant";
import { ethers } from "ethers";
import { TOKENS } from "./../../../Contract";
import { useSigner, useProvider, useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";

const PaymentGatewayForm = (props) => {
    const library = useProvider({
        chainId: 56,
    });
    const { data: signer } = useSigner();
    const { isConnected: active, address: account } = useAccount();

    const { order } = props;
    const [contract, setContract] = useState(null);
    const [txStatus, setTxStatus] = useState("");
    const navigate = useNavigate();

    const formInputObj = {
        noOfHvam: 0,
        priceOfHvam: 0,
        currency: "USD",
        totalPrice: 0,
        hvamAddress: "",
        transactionId: "",
        hashId: "",
        tranStatus: "Transferred",
        tranDate: moment().format("YYYY-MM-DD"),
    };

    const getContract = (address, ABI) => {
        const contract = new ethers.Contract(address, ABI, library);
        return contract.connect(signer);
    };

    useEffect(() => {
        if (library && signer) {
            setContract(getContract(TOKENS.HVAM.ADDRESS, TOKENS.HVAM.ABI));
        } else {
            setContract(null);
        }
    }, [library, account, active, signer]);

    useEffect(() => {
        if (active) {
            setContract(getContract(TOKENS.HVAM.ADDRESS, TOKENS.HVAM.ABI));
        } else {
            setContract(null);
        }
    }, [library, account, active]);

    const submit = async () => {
        try {
            const balance = await contract.balanceOf(account);

            if (ethers.utils.formatEther(balance) >= values.noOfHvam) {
                setTxStatus("Confirm Transaction on Wallet");

                const tx = await contract.transferFrom(
                    account,
                    values.hvamAddress,
                    ethers.utils.parseUnits(values.noOfHvam, TOKENS.HVAM.DECIMALS).toString(),
                    {
                        gasLimit: 210000
                    }
                );

                setTxStatus("Waiting For Confirmation");
                await tx.wait();

                setTxStatus("Transaction Completed");
                let encId = getEncryptId(order.id);
                values.tranStatus = "Transferred";
                values.hashId = ""; //pass value of hash Id here
                let saveResponse = await AuthApi.put(`/admin/order-update/${encId}`, values);
                const { status, data } = saveResponse;
                if (status === 201 && data.order !== undefined) {
                    setValues({ ...formInputObj });
                    navigate("/transfer-hvam-fiat");
                    toast.success("Order Updated Successfully..");
                }

            } else {
                setTxStatus("Insufficient HVAM balance");
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (
                response !== undefined &&
                Object.keys(response.data).length &&
                response.data.error !== undefined
            ) {
                setErrors(response.data.error); // if error from server side
            }
        }
    };

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } =
        useForm(submit, validate, formInputObj);

    useEffect(() => {
        if (order && Object.keys(order).length !== 0) {
            for (let [key] of Object.entries(formInputObj)) {
                formInputObj[key] = order[key];
            }
            setValues({ ...formInputObj });
        }
    }, [order]);

    useEffect(() => {
        var validNumber = new RegExp(/^\d*\.?\d*$/);
        if (validNumber.test(values.noOfHvam) && values.currency) {
            let totPrice =
                parseFloat(values.noOfHvam) * parseFloat(values.priceOfHvam);
            setValues({ ...values, totalPrice: totPrice.toFixed(2) });
        }
    }, [values.noOfHvam, values.priceOfHvam]);

    useEffect(() => {
        if (values.currency) {
            let currency = currencies.find(
                (currency) => currency.label === values.currency
            );
            if (currency !== undefined) {
                setValues({ ...values, priceOfHvam: currency.val });
            }
        }
    }, [values.currency]);

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="col-12 d-flex justify-content-between">
                    <h6 className="text-uppercase mb-3">HVAM</h6>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md-3 mb-3">
                            <label className="text-capitalize">no. of HVAM</label>
                            <input
                                readOnly
                                type="text"
                                onChange={handleChange}
                                name="noOfHvam"
                                value={values.noOfHvam}
                                className="form-control"
                                placeholder="0"
                                aria-label="0"
                            />
                            {errors.noOfHvam && (
                                <small className="text-danger">{errors.noOfHvam}</small>
                            )}
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label className="text-capitalize">price of HVAM</label>
                            <input
                                readOnly
                                type="text"
                                onChange={handleChange}
                                name="priceOfHvam"
                                value={values.priceOfHvam}
                                className="form-control"
                                placeholder="0"
                                aria-label="0"
                                disabled
                            />
                            {errors.priceOfHvam && (
                                <small className="text-danger">{errors.priceOfHvam}</small>
                            )}
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label for="userCountry">Select Currency</label>
                            <div className="form-group selectdropdon">
                                <select
                                    readOnly
                                    className="form-control"
                                    onChange={handleChange}
                                    name="currency"
                                    value={values.currency}
                                >
                                    {currencies &&
                                        currencies.map((currency, i) => (
                                            <option key={i} value={currency.label}>
                                                {currency.label}
                                            </option>
                                        ))}
                                </select>
                                {errors.currency && (
                                    <small className="text-danger">{errors.currency}</small>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label className="text-capitalize">Total price</label>
                            <input
                                readOnly
                                type="text"
                                name="totalPrice"
                                value={values.totalPrice}
                                onChange={handleChange}
                                className="form-control text-bold text-dark"
                                aria-label="0"
                                disabled
                            />
                            {errors.totalPrice && (
                                <small className="text-danger">{errors.totalPrice}</small>
                            )}
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <label className="text-capitalize">transfer HVAM to addres</label>
                            <input
                                readOnly
                                type="text"
                                name="hvamAddress"
                                value={values.hvamAddress}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Wallet Address"
                                aria-label="0"
                            />
                            {errors.hvamAddress && (
                                <small className="text-danger">{errors.hvamAddress}</small>
                            )}
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <label className="text-capitalize">Transaction ID</label>
                            <input
                                readOnly
                                type="text"
                                name="transactionId"
                                value={values.transactionId}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Transaction ID"
                                aria-label="0"
                            />
                            {errors.transactionId && (
                                <small className="text-danger">{errors.transactionId}</small>
                            )}
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label className="text-capitalize" for="date">
                                Current Date
                            </label>
                            <input
                                readOnly
                                type="date"
                                name="tranDate"
                                value={moment(values.tranDate).format("YYYY-MM-DD")}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="0"
                                aria-label="0"
                            />
                            {errors.tranDate && (
                                <small className="text-danger">{errors.tranDate}</small>
                            )}
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label className="text-capitalize">User Name</label>
                            <input
                                readOnly
                                type="text"
                                value={order?.user?.name}
                                className="form-control"
                                placeholder="0"
                                aria-label="0"
                            />
                        </div>
                        <div className="col-12 col-md-3 mt-4">
                            {
                                values.hashId &&
                                <a target="_blank" className="text-xs text-primary font-weight-bold mb-0" href={`https://bscscan.com/tx/${values.hashId}`}>Hash Id</a>
                            }
                        </div>
                        {order?.tranStatus !== "Transferred" ? (
                            <div className="text-end">
                                <button
                                    disabled={!active}
                                    type="submit"
                                    className={`btn bg-gradient-primary text-capitalize mb-0 me-3`}
                                >
                                    transfer
                                </button>
                                <br /><br />
                                <span className="text-success">{txStatus}</span>
                            </div>
                        ) : (
                            <div className="text-end">
                                <p
                                    className={`btn bg-gradient-primary text-capitalize mb-0 me-3 `}
                                >
                                    transfered
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </Form>
        </>
    );
};

export default PaymentGatewayForm;
