import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserAuthLayout from '../../Layout/UserAuthLayout';
import { AuthUserApi } from '../../utils/api';
import TransacForm from './TransacForm';

const Transactions = () => {
    const [order, setOrder] = useState({});
    const { id } = useParams();

    const getData = async () => {
        try {
            const listResponse = await AuthUserApi.get(`/user/transaction-get/${id}`);
            const { status, data } = listResponse;
            if (status === 200 && data.escrow !== undefined) {
                setOrder(data.escrow);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    useEffect(() => {
        if(id != 0){
            getData();
        }
    }, []);

    return (
        <>
            <UserAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-md-7 mt-4">
                            <TransacForm 
                                order={order}
                            />
                        </div>
                    </div>
                </div>
            </UserAuthLayout>
        </>
    )
}

export default Transactions