import React from "react";
import WalletConnect from "../../../Components/WalletConnect";
import BuyerStatus from "../../AllStatus/Buyer/BuyerStatus";
import { getShortAddress } from "../../../utils";
import { useAccount } from "wagmi";

const PaymentSummaryBuyer = (props) => {

    const { SetStableToken, order, dataxd, days, transStatus, loading, toTokens} = props;

    const { isConnected: active } = useAccount();

    return (
        <>
            <div className="card mb-4 mb-md-5">
                <div className="card-body pt-3 p-3">
                    <div className="card">    
                        { order?.escrowStatus == 'In Progress' &&
                            <>
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                    <h5 className="mb-4">Select Payment Method</h5>
                                </div>
                                <div className="card-body py-4 p-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0">Escrow Id</label>
                                        <p className="badge bg-gradient-warning py-2">{order?.escrowNo}</p>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0">Supplier Address</label>
                                        <p className="badge bg-gradient-info py-2">{getShortAddress(order?.userWAddress)}</p>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0">Buyer Address</label>
                                        <p className="badge bg-gradient-info py-2">{getShortAddress(order?.myWAddress)}</p>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0">Deal Amount in USD</label>
                                        <p>{order?.invoiceAmt}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <label className="text-sm ms-0">Escrow Charges In USD</label>
                                            <p className="text-sm">(Pay In HVAM)</p>
                                        </div>
                                        <div className="text-end">
                                            <p className="mb-0">{order?.additionalAmt}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0">Total Amount</label>
                                        <h6>{parseFloat(order?.invoiceAmt) + parseFloat(order?.additionalAmt)}</h6>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0">Deal Day's</label>
                                        <p>{days}</p>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0">Select Coin</label>
                                        <div>
                                            <div className="form-group selectdropdon">
                                                <select onChange={(e) => SetStableToken(e.target.value)} className="form-control" id="exampleFormControlSelect1">
                                                {(toTokens || []).map((t) => (
                                                    <option>{t.tag}</option>)
                                                )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-sm ms-0">
                                        Keep an additional HVAM, BNB, BUSD, USDT in to the wallet to make payment Successful.
                                    </p>                            
                                    <>
                                        {
                                            (active) ?  
                                            <button type="button" className="btn btn-primary btn-lg w-100 mb-2" onClick={dataxd}  disabled={loading}
                                            >
                                                Proceed to Pay
                                            </button> : 
                                            <>
                                                <WalletConnect className="btn btn-lg w-100 mb-2" />
                                            </>
                                        }
                                        <span className="text-success text-center" dangerouslySetInnerHTML={{ __html: transStatus }}></span>
                                    </>
                                </div>
                            </>
                        }              
                        {
                            (order?.escrowStatus == 'Credited' || order?.escrowStatus == 'Dispatched') &&
                            <BuyerStatus
                                order={order}
                                role={'Buyer'}
                            />
                        }                      
                        {
                            (order?.escrowStatus == 'Delivered') &&
                            <div className="card mb-4 mb-md-5">
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                    <h5 className="mb-2">Status</h5>
                                    <p className="mb-4 text-success fw-bold text-capitalize"><span className="badge badge-sm bg-gradient-success me-3">{order?.escrowStatus}</span>Delivery Confirm</p>
                                </div>
                                <div className="card-body py-4 p-3">
                                    <p className="mb-4">You confirmed the delivery.</p>
                                </div>
                            </div>   
                        }
                        {
                            (order?.escrowStatus == 'Deal Cancel') &&
                            <div className="card mb-4 mb-md-5">
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                    <h5 className="mb-2">Status</h5>
                                    <p className="mb-4 text-warning fw-bold text-capitalize"><span className="badge badge-sm bg-gradient-warning me-3">{order?.escrowStatus}</span>Payment refunded</p>
                                </div>
                                <div className="card-body py-4 p-3">
                                    <p className="mb-4">{order?.rejectedBy} reject the deal, Admin refunded the payment to You.</p>
                                </div>
                            </div>   
                        }
                        {
                            (order?.escrowStatus == 'Refunded') &&
                            <div className="card mb-4 mb-md-5">
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                    <h5 className="mb-2">Status</h5>
                                    <p className="mb-4 text-warning fw-bold text-capitalize"><span className="badge badge-sm bg-gradient-warning me-3">{order?.escrowStatus}</span>Payment refunded</p>
                                </div>
                                <div className="card-body py-4 p-3">
                                    <p className="mb-4">{order?.rejectedBy} reject the deal, Admin refunded the payment to You.</p>
                                </div>
                            </div>   
                        }
                        {   
                            order?.escrowStatus == 'Deal Closed' &&
                            <div className="card mb-4 mb-md-5">
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                    <h5 className="mb-2">Status</h5>
                                    <p className="mb-4 text-info fw-bold"><span className="badge badge-sm bg-gradient-info me-3">{order?.escrowStatus}</span></p>
                                </div>
                                <div className="card-body py-4 p-3">
                                    <p className="mb-4">Deal Closed by admin, payment released to supplier.</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentSummaryBuyer;