import React from "react";
import { useState } from "react";
import {  Form, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
const { REACT_APP_SITE_KEY } = process.env;

const LoginForm = (props) => {
    const [captchaCheck, setCaptchaCheck] = useState(false);
    
    const handleCaptaChange = (val) => {
        setCaptchaCheck(val !== null);
    }

    const { handleSubmit, handleChange, values, errors } = props;

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control className="form-control-lg" type="email" name="email" value={values.email} onChange={handleChange} placeholder="Enter Email" />
                    {errors.email && <small className="text-danger">{errors.email}</small>}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Control className="form-control-lg" type="password" name="password" value={values.password} onChange={handleChange} placeholder="Enter Password" />
                    {errors.password && <small className="text-danger">{errors.password}</small>}
                </Form.Group>
                <div className="mb-2">
                    <ReCAPTCHA
                        sitekey={REACT_APP_SITE_KEY}
                        onChange={handleCaptaChange}
                    />
               </div>
               <p className="m-0">{errors.role && <small className="text-danger">{errors.role}</small>}</p>
                <Button disabled={!captchaCheck} variant="warning" type="submit" size="lg" className="btn-lg w-100 mt-2 mb-0">
                    Sign in
                </Button>
            </Form>
        </>
    );
};

export default LoginForm;
