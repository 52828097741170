import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap"
import NotifyStatus from '../../../../Components/NotifyStatus';

const StatusPaymentRecived = (props) => {
    const { order, isConnected, cancelDeal } = props;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onCancelDeal = async () => {
        await cancelDeal(Number(order.escrowNo));
        handleClose();
    }

    return (
        <>
            <div className="card mb-4 mb-md-5">
                <div className="card-header bg-light rounded-0 pb-0 px-3">
                    <h5 className="mb-2">Status</h5>
                    <p className="mb-4 text-success fw-bold"><span className="badge badge-sm bg-gradient-success me-3">{order?.escrowStatus}</span>Payment Received </p>
                </div>
                <div className="card-body py-4 p-3">
                    <p className="mb-4">Payment transferred by the Buyer waiting for {order?.paymentFor} delivery status.</p>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <button disabled={!isConnected} type="button" className="btn bg-gradient-danger w-100 mx-1" onClick={handleShow}>Cancel Deal</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title className="mx-auto">Your attention is required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NotifyStatus Status="Are you sure you want to cancel the deal?" />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    {/* Deal Cancel msg btn */}
                    <Button variant="danger" onClick={onCancelDeal}>
                        Cancel Deal
                    </Button>
                    <Button disabled={!isConnected} variant="primary" onClick={handleClose}>
                        Back To Summury
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StatusPaymentRecived