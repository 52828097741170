import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthUserApi } from "../../../utils/api";
import { getEncryptId } from "../../../utils/secure";

const BankDetails = (props) => {
    const navigate = useNavigate();
    const { order} = props;

    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        amount: {
                            currency_code: "USD",
                            value: order.totalPrice,
                        },
                    },
                ],
                // not needed if a shipping address is actually needed
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID) => {
                console.log('orderID',orderID);
                return orderID;
            });
    };

    // check Approval
    const onApprove = async (data, actions) => {
        return actions.order.capture().then (async function (details) {
            const { payer } = details;
            console.log('details',details);
            const updateData = {
                transactionId: payer.payer_id,
                tranStatus: details.status
            }
            let updateResponse = await AuthUserApi.put(`/user/order-update/${order.id}`, updateData);
            const { status, data } = updateResponse;
            if (status === 201 && data.order !== undefined) {
                navigate(`/view-order/${getEncryptId(data.order.id)}`);
            }
            toast("Thank you for your payment!");
            navigate("/buy-fiat-money");
        });
    };
    //capture likely error
    const onError = async (payData, actions) => {
        const updateData = {
            tranStatus: 'Cancel' 
        }
        let updateResponse = await AuthUserApi.put(`/user/order-update/${order.id}`, updateData);
        const { status, data } = updateResponse;
        if (status === 201 && data.order !== undefined) {
            navigate(`/view-order/${getEncryptId(data.order.id)}`);
        }
        toast("An Error occured with your payment ");
    };

    return (
        <>
            <div className="row d-flex justify-content-start align-items-center">
                <div className="col-12 col-md-8">
                    <label class="text-sm ms-0">Payment Details</label>
                    <PayPalScriptProvider options={{ "client-id": "Acc711QhqFhJBCBM8FytXpHSlyCXaYri66lYKNaUAtYvFXkzsRCMtDog8zzfdgzzLvNsGfMA_mHGOrvf" }}>
                        <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={onError}
                        />                       
                    </PayPalScriptProvider>
                </div>
            </div>
        </>
    );
};

export default BankDetails;
