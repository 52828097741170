import React, { useState } from 'react'
import moment from 'moment';
import { Button, Modal } from "react-bootstrap"
import NotifyStatus from '../../../Components/NotifyStatus';
import { REACT_APP_IMAGE_URL } from '../../../utils/api';

const PaymentTransferStatus = ({transStatus, isConnected, cancelDeal, confirmDelivery, role, order}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onCancel = async () => {
        cancelDeal();
        handleClose();
    };

    const onApprove = async () => {
        confirmDelivery();
        handleClose();
    }

  return (
    <>
        {
            role == 'Buyer' &&
            <div className="card">
                <div className="card-header bg-light rounded-0 pb-0 px-3">
                    <h5 className="mb-2">Status</h5>
                    <p className="mb-4 text-success fw-bold text-capitalize"><span className="badge badge-sm bg-gradient-success me-3">{order?.escrowStatus}</span>Payment Credited successfully</p>
                </div>
                <div className="card-body py-4 p-3">
                    <p className="mb-4">Payment Credited Successfully to the Escrow System waiting for delivery.</p>
                    <p className="mb-4"></p>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <button onClick={() => onApprove()} disabled={!isConnected} type="button" className="btn bg-gradient-success w-100 mx-1 text-capitalize">Confirm Delivery</button>
                        </div>
                        <div className="col-12 col-md-6">
                            <button disabled={!isConnected} onClick={handleShow} type="button" className="btn bg-gradient-danger w-100 mx-1 text-capitalize">Cancel Deal</button>
                        </div>
                        <div className="col-12 text-success text-center">
                            {transStatus}
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            role == 'Supplier' &&
            <div className="card mb-4 mb-md-5">
                <div className="card-header bg-light rounded-0 pb-0 px-3">
                    <h5 className="mb-2">Status</h5>
                    <p className="mb-4 text-success fw-bold"><span className="badge badge-sm bg-gradient-success me-3">{order?.escrowStatus}</span>Awaiting Payment Release</p>
                </div>
                <div className="card-body py-4 p-3">
                    <p className="mb-4">{!order?.deliveryDate ? 'Awaiting Payment Release from HVAM admin, after delivery confirmation by buyer' : 'Delivery confirmed by buyer'}.</p>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <button disabled={!isConnected} type="button" className="btn bg-gradient-danger w-100 mx-1" onClick={handleShow}>Cancel Deal</button>
                        </div>
                    </div>
                </div>
            </div>
        }
        {   
            (order?.escrowStatus == 'Dispatched' || order?.location !== null) &&
            <div className="card mb-4 mb-md-5">
                <div className="card-body py-4 p-3">
                    <p className="mb-4">Dispatch Details</p>
                    <div className="bg-gray-100 border-radius-lg shadow-none h-100 p-3">
                        <div className="d-flex justify-content-start align-items-center">
                            <label className="text-sm ms-0 mt-2">Date & Time : </label>
                            <p className="mt-2 mb-2 text-dark text-sm ms-3">{ order?.dispatchDate ? moment(order?.dispatchDate).format('DD MMM YYYY') : '' }</p>
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                            <label className="text-sm ms-0 mt-2">Location : </label>
                            <p className="mt-2 mb-2 text-dark text-sm ms-3"> {order?.location}</p>
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                            <label className="text-sm ms-0 mt-2">Docet No. : </label>
                            <p className="mt-2 mb-2 text-dark text-sm ms-3"> {order?.docket_no}</p>
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                            <label className="text-sm ms-0 mt-2">Document : </label>
                            <p className="mt-2 mb-2 text-dark text-sm ms-3">
                                <a href={order.fileUrl ? REACT_APP_IMAGE_URL + '/' + order.fileUrl : '' } target="_blank" className="text-capitalize text-primary h6">{order?.fileName}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        }

        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title className="mx-auto">Your attention is required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NotifyStatus Status="Are you sure you want to cancel the Deal?"/>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="danger" onClick={() => onCancel()}>
                        Cancel Deal
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Back To Summury
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    </>
  )
}

export default PaymentTransferStatus