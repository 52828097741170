import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsCreditCard2BackFill } from "react-icons/bs";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { checkDataIsValid } from '../../utils/secure';

const PaymentInformation = (props) => {
    const { totalFee } = props;
    const { invoiceAmt, additionalAmt } = props.values || {};

    return (
        <>
            <div className="card mb-4">
                <div className="card-header pb-0 px-3">
                    <h3 className="mb-0 text-center">Payment Information</h3>
                </div>
                <div className="card-body pt-4 p-3">
                    <div className="row">
                        <div className="col-12">
                            <h6>Amount to transfer to Escrows</h6>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div>
                                        <span
                                            className="btn bg-gradient-dark"
                                            id="navbarDropdownMenuLink2"
                                        >
                                            <img src="https://demos.creative-tim.com/argon-dashboard-pro-bs4/assets/img/icons/flags/US.png" />{" "}
                                            USD
                                        </span>
                                    </div>
                                </div>
                                <h3 className="text-end">{totalFee}</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h6>Invoice Amount</h6>
                                <h5 className="text-end">{invoiceAmt}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h6>Transaction fees</h6>
                                <div className="text-end">
                                    <h5>{additionalAmt}</h5>
                                    <p>Paid by you</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h6>Total</h6>
                                <h5 className="text-end">{totalFee}</h5>
                            </div>
                        </div>
                        <div className="col-12">
                            <ul className="ps-3">
                                <li>
                                The amount will be transferred to Escrows System (excluding  fees) and will be safeguarded in a secure Blockchain  until  your vendor has provided fulfillment proof..
                                </li>
                                <li>
                                    If your vendor does not fulfil the order, amount in
                                    escrow will be fully refunded to you
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentInformation;
