import React from 'react'
import ValueUpdateCard from '../../../Components/ValueUpdateCard'
import AdminAuthLayout from '../../../Layout/AdminAuthLayout'
import AmericaFlag from '../../../assets/img/flag/AU.png'
import { GiCancel } from "react-icons/gi";
import { AuthApi } from '../../../utils/api';
import { Form } from "react-bootstrap";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getEncryptId } from '../../../utils/secure';
import CountryForm from './CountryForm';
import CountryTr from './CountryTr';

const AdminSetting = () => {

    const formInputObj = {
        oldCharge: "",
        charge: ""
    };

    const [values, setValues] = useState(formInputObj);
    const [errors, setErrors] = useState([]);
    const [charge, setCharge] = useState([]);
    const [countries, setCountries] = useState([]);
    const [editcountry, setEditCountry] = useState({});

    const handleChange = event => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: ((name === 'oldCharge' || name === 'charge')&& value.length) ? value.replace(/^0+/, '') : value
        });
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        var validNumber = new RegExp(/^\d*\.?\d*$/);

        if (!values.oldCharge) {
            formIsValid = false;
            errors["oldCharge"] = "Old Charge is required.";
        }else if(values.oldCharge && !validNumber.test(values.oldCharge)){
            formIsValid = false;
            errors["oldCharge"] = "Enter number or decimal value only.";
        }
        if (!values.charge) {
            formIsValid = false;
            errors["charge"] = "Charge is required.";
        }else if(values.charge && !validNumber.test(values.charge)){
            formIsValid = false;
            errors["charge"] = "Enter number or decimal value only.";
        }
        setErrors({ ...errors, errors });
        return formIsValid;
    }

    const getData = async () => {
        try {
            const listResponse = await AuthApi.get(`/admin/charge`);
            const { status, data } = listResponse;
            if (status === 200 && data.charge !== undefined) {
                setCharge(data.charge);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const getCountries = async () => {
        try {
            const listResponse = await AuthApi.get(`/admin/countries-ds`);
            const { status, data } = listResponse;
            if (status === 200 && data.countries !== undefined) {
                setCountries(data.countries);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const submit = async (e) => {
        try {
            e.preventDefault();
            if (handleValidation()) {
                let saveResponse;
                let msg;
                if (charge?.id) {
                    let encId = getEncryptId(charge.id);
                    saveResponse = await AuthApi.put(`/admin/update-charge/${encId}`, values);
                    msg = "Charges Updated Successfully.";
                } else {
                    saveResponse = await AuthApi.post(`/admin/add-charge`, values);
                    msg = "Charges Added Successfully.";
                }
                const { status, data } = saveResponse;

                if (status === 201 && data.charge !== undefined) {
                    toast.success(msg);
                    setCharge(data.charge);
                }
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    const editCountry = (adata) => {
        console.log('adata',adata);
        setEditCountry(adata);
    }

    const deleteData = async (id) => {
        try {
            const deleteResponse = await AuthApi.delete(`/admin/delete-country/${id}`);
            const { status, data } = deleteResponse;
            if (status === 200 && data.country !== undefined) {
                getCountries();
                toast.success(`Country Removed successfully`);
            }
        } catch (e) {
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.message !== undefined) {
                toast.error(response.data.message);
            } else {
                toast.error(`😱 Axios request failed: ${e}`);
            }
        }
    }

    useEffect(() => {
        if (charge && Object.keys(charge).length !== 0) {
            for (let [key] of Object.entries(formInputObj)) {
                formInputObj[key] = charge[key];
            }
            setValues({ ...formInputObj });
        }
    }, [charge]);

    useEffect(() => {
        getData();
        getCountries();
    }, []);

    return (
        <>
            <AdminAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                            <ValueUpdateCard
                                Count="00"
                                Title="total payment release to supplier"
                                Icon="fas fa-arrow-up"
                                Bgcolor="bg-gradient-warning"
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <ValueUpdateCard
                                Count="00"
                                Title="total payment hvam received "
                                Icon="fas fa-arrow-down"
                                Bgcolor="bg-gradient-info"
                            />
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="text-uppercase mb-3">admin charges</h5>
                                        </div>
                                        <div className="col-12">
                                            <Form>
                                                <div className="row">
                                                    <div className="col-6 col-md-4 mb-3">
                                                        <label className="text-capitalize" for="file">previous charges</label>
                                                        <input type="text" onChange={handleChange} value={values.oldCharge} name="oldCharge" className="form-control" placeholder="0" aria-label="0" />
                                                        {errors.oldCharge && <small className="text-danger">{errors.oldCharge}</small>}
                                                    </div>
                                                    <div className="col-6 col-md-4 mb-3">
                                                        <label className="text-capitalize" for="file">new charges</label>
                                                        <input type="text" onChange={handleChange} value={values.charge} name="charge" className="form-control" placeholder="0" aria-label="0" />
                                                        {errors.charge && <small className="text-danger">{errors.charge}</small>}
                                                    </div>
                                                    <div className="col-12 col-md-12 mb-3 pt-1 text-end">
                                                        <button onClick={e => submit(e)} className="btn bg-gradient-warning text-capitalize mb-0">update</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <h5 className="text-uppercase mb-3">country</h5>
                                        </div>
                                        <CountryForm
                                            editcountry={editcountry}
                                            getCountries={getCountries}
                                        />
                                        <div className="col-12 col-md-4 mb-3">
                                            <div className="card">
                                                <div className="card-body p-2">
                                                    <ul className="list-group">
                                                        {countries && countries.map((country, indx) =>
                                                            <CountryTr
                                                                key={indx}
                                                                indx={indx}
                                                                country={country}
                                                                editCountry={editCountry}
                                                                deleteData={deleteData}
                                                            />
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminAuthLayout>
        </>
    )
}

export default AdminSetting