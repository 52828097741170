import { useNavigate, useParams } from 'react-router-dom';
import { GuestApi } from "../../../utils/api";
import { toast } from "react-toastify";
import { useEffect } from 'react';

const VerifyEmail = (props) => {
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        submit();
    }, []);

    const submit = async () => {
        try {
            var saveResponse = await GuestApi.put(`/user/verify-email/${id}`, { is_verified: '1' });
            const { status, data } = saveResponse;
            
            if (status === 201 && data.user !== undefined) {
                toast.success('User Verified Successfully..');
                navigate("/");
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
            }
        }
    }
}

export default VerifyEmail;
