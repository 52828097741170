import React from 'react';
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { MdCreate, MdDashboard, MdSwapVerticalCircle } from "react-icons/md";
import { BiTransferAlt } from "react-icons/bi";
import { HiEyeOff } from "react-icons/hi";
import { FaUserCog } from "react-icons/fa";
import { RiSecurePaymentFill } from "react-icons/ri";

const SidebarUser = (props) => {

    const { logoutHandle } = props;

    return (
        <>
            <aside
                className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 "
                id="UserSidebar"
            >
                <div className="sidenav-header">
                    <i
                        className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                        aria-hidden="true"
                        id="iconSidenav"
                    ></i>
                    <Link
                        to="/dashboard"
                        className="navbar-brand m-0"
                    >
                        <img
                            src={Logo}
                            className="navbar-brand-img h-100"
                            alt=""
                        />
                        <span className="ms-1 font-weight-bolder"></span>
                    </Link>
                </div>
                <hr className="horizontal dark mt-0" />
                <div
                    className="collapse navbar-collapse  w-auto "
                    id="sidenav-collapse-main"
                >
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink to="/dashboard" className="nav-link" activeClassName="is-active">
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <MdDashboard size={25} className="text-secondary text-sm opacity-10"/>
                                </div>
                                <span className="nav-link-text ms-1 text-capitalize">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/agreement-listing" className="nav-link" activeClassName="is-active">
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <BiTransferAlt size={25} className="text-secondary text-sm opacity-10"/>
                                </div>
                                <span className="nav-link-text ms-1 text-capitalize">Transactions</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/create-eccrow/0" className="nav-link" activeClassName="is-active">
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <MdCreate size={25} className="text-secondary text-sm opacity-10"/>
                                </div>
                                <span className="nav-link-text ms-1 text-capitalize">Create Escrow</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/swapp" className="nav-link" activeClassName="is-active">
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <MdSwapVerticalCircle size={25} className="text-secondary text-sm opacity-10"/>
                                </div>
                                <span className="nav-link-text ms-1 text-capitalize">Swapping</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/buy-fiat-money" className="nav-link" activeClassName="is-active">
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <img
                                        src={Logo}
                                        className="navbar-brand-img h-100"
                                        alt=""
                                    />
                                </div>
                                <span className="nav-link-text ms-1 text-capitalize">HVAM By Money</span>
                            </NavLink>
                        </li>
                        <li className="nav-item mt-3 mb-2">
                            <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
                            General Setting
                            </h6>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/user-profile"className="nav-link" activeClassName="is-active">
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <FaUserCog size={25} className="text-secondary text-sm opacity-10"/>
                                </div>
                                <span className="nav-link-text ms-1 text-capitalize">My Profile</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/change-password" className="nav-link" activeClassName="is-active">
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <HiEyeOff size={25} className="text-secondary text-sm opacity-10"/>
                                </div>
                                <span className="nav-link-text ms-1 text-capitalize">Change Password</span>
                            </NavLink>
                        </li>
                        <li className="d-block d-md-none px-2 mt-2">
                            <button type="button" onClick={() => logoutHandle()} className="btn btn-outline-warning w-100 ms-0">
                                <span className="nav-link-text mx-auto text-capitalize text-bold">Sign Out</span>   
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="sidenav-footer mx-3 "></div>
            </aside>
        </>
    )
}

export default SidebarUser