import React from "react";
import validateDispatch from "../../../validate/validateDispatch";
import useForm from "../../../useForm";
import { AuthUserApi } from "../../../utils/api";
import { toast } from "react-toastify";
import BuyerStatus from "../../AllStatus/Buyer/BuyerStatus";

const PaymentSummarySupplier = (props) => {

    const { order, id, role } = props;

    const submit = async () => {
        try {
            var saveResponse = await AuthUserApi.put(`/user/transaction-update/${id}`, values);
            const { status, data } = saveResponse;
            
            if (status === 201 && data.escrow !== undefined) {
                setValues({ ...formInputObj });
                toast.success('Dispatch Details Update Successfully..');
                window.location.reload();
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    const formInputObj = {
        escrowStatus: "Dispatched",
        dispatchDate: "",
        location: "",
        docket_no: "",
    };

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } = useForm(submit, validateDispatch, formInputObj);

    return (
        <>
            {   order?.escrowStatus == 'In Progress' &&
               <div className="card mb-4 mb-md-5">
                <div className="card-header bg-light rounded-0 pb-0 px-3">
                    <h5 className="mb-2">Status</h5>
                    <p className="mb-4 text-warning fw-bold"><span className="badge badge-sm bg-gradient-warning me-3">{order?.escrowStatus}</span>Waiting For Payment</p>
                </div>
                <div className="card-body py-4 p-3">
                    <p className="mb-4">Escrow Request is accepted by {order?.userRole == role ? 'You' : order?.userRole} waiting for payment.</p>
                </div>
            </div>
            }
            {
                (order?.escrowStatus == 'Credited' || order?.escrowStatus == 'Dispatched') &&
                <BuyerStatus
                    order={order}
                    role={'Supplier'}
                />
            } 
            {
                (order?.escrowStatus == 'Delivered') &&
                <div className="card mb-4 mb-md-5">
                    <div className="card-header bg-light rounded-0 pb-0 px-3">
                        <h5 className="mb-2">Status</h5>
                        <p className="mb-4 text-warning fw-bold text-capitalize"><span className="badge badge-sm bg-gradient-warning me-3">{order?.escrowStatus}</span>awaiting for payment release</p>
                    </div>
                    <div className="card-body py-4 p-3">
                        <p className="mb-4">Buyer Confirm the delivery, awaiting for release.</p>
                    </div>
                </div>   
            }
            {
                (order?.escrowStatus == 'Rejected') &&
                <div className="card mb-4 mb-md-5">
                    <div className="card-header bg-light rounded-0 pb-0 px-3">
                        <h5 className="mb-2">Status</h5>
                        <p className="mb-4 text-warning fw-bold text-capitalize"><span className="badge badge-sm bg-gradient-warning me-3">{order?.escrowStatus}</span>Payment refunded</p>
                    </div>
                    <div className="card-body py-4 p-3">
                        <p className="mb-4">{order?.rejectedBy} reject the deal, Admin refunded the payment to buyer.</p>
                    </div>
                </div>   
            }
            {
                    (order?.escrowStatus == 'Refunded') &&
                    <div className="card mb-4 mb-md-5">
                        <div className="card-header bg-light rounded-0 pb-0 px-3">
                            <h5 className="mb-2">Status</h5>
                            <p className="mb-4 text-warning fw-bold text-capitalize"><span className="badge badge-sm bg-gradient-warning me-3">{order?.escrowStatus}</span>Payment refunded</p>
                        </div>
                        <div className="card-body py-4 p-3">
                            <p className="mb-4">{order?.rejectedBy} reject the deal, Admin refunded the payment to You.</p>
                        </div>
                    </div>   
                }
            {
                (order?.escrowStatus == 'Deal Cancel') &&
                <div className="card mb-4 mb-md-5">
                    <div className="card-header bg-light rounded-0 pb-0 px-3">
                        <h5 className="mb-2">Status</h5>
                        <p className="mb-4 text-warning fw-bold text-capitalize"><span className="badge badge-sm bg-gradient-warning me-3">{order?.escrowStatus}</span>Payment refunded</p>
                    </div>
                    <div className="card-body py-4 p-3">
                        <p className="mb-4">{order?.rejectedBy} reject the deal, Admin refunded the payment to buyer.</p>
                    </div>
                </div>   
            }
            {   
                order?.escrowStatus == 'Deal Closed' &&
                <div className="card mb-4 mb-md-5">
                    <div className="card-header bg-light rounded-0 pb-0 px-3">
                        <h5 className="mb-2">Status</h5>
                        <p className="mb-4 text-info fw-bold"><span className="badge badge-sm bg-gradient-info me-3">{order?.escrowStatus}</span></p>
                    </div>
                    <div className="card-body py-4 p-3">
                        <p className="mb-4">Deal Closed by admin, payment released to supplier.</p>
                    </div>
                </div>
            }
        </>
    );
};

export default PaymentSummarySupplier;
