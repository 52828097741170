import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { HiUserGroup, HiEyeOff } from "react-icons/hi";
import { FaUserPlus, FaUserCog, FaUserEdit } from "react-icons/fa";
import {
  MdSwapVerticalCircle,
  MdBorderColor,
  MdDashboard,
} from "react-icons/md";
import { SiHiveBlockchain } from "react-icons/si";

const SidebarAdmin = (props) => {
  const { logoutHandle } = props;

  return (
    <>
      <aside
        className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 "
        id="sidenav-main"
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <Link to="/admin-dashboard" className="navbar-brand m-0">
            <img src={Logo} className="navbar-brand-img h-100" alt="" />
            <span className="ms-1 font-weight-bolder"></span>
          </Link>
        </div>
        <hr className="horizontal dark mt-0" />
        <div
          className="collapse navbar-collapse  w-auto "
          id="sidenav-collapse-main"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                to="/admin-dashboard"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <MdDashboard
                    size={25}
                    className="text-secondary text-sm opacity-10"
                  />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  Dashboard
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/admin-transactions"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <MdBorderColor
                    size={25}
                    className="text-secondary text-sm opacity-10"
                  />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  Orders
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/customers"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <HiUserGroup
                    size={25}
                    className="text-secondary text-sm opacity-10"
                  />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  Customer
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/transfer-hvam-fiat"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <img src={Logo} className="navbar-brand-img h-100" alt="" />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  HVAM By Money
                </span>
              </NavLink>
            </li>
            {/* <li className="nav-item">
                            <NavLink to="/hvam-orderlist" className="nav-link" activeClassName="is-active">
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <RiSecurePaymentFill size={25} className="text-secondary text-sm opacity-10"/>
                                </div>
                                <span className="nav-link-text ms-1 text-capitalize">Payment Gateway</span>
                            </NavLink>
                        </li> */}
            <li className="nav-item mt-3 mb-2">
              <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
                Blockchain Setting
              </h6>
            </li>
            <li className="nav-item">
              <NavLink
                to="/blockchain-setting"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <SiHiveBlockchain
                    size={25}
                    className="text-secondary text-sm opacity-10"
                  />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  blockchain setting
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/admin-setting"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <FaUserEdit
                    size={25}
                    className="text-secondary text-sm opacity-10"
                  />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  Admin setting
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/admin-swapp"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <MdSwapVerticalCircle
                    size={25}
                    className="text-secondary text-sm opacity-10"
                  />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  Swapping
                </span>
              </NavLink>
            </li>
            <li className="nav-item mt-3 mb-2">
              <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
                System Setting
              </h6>
            </li>
            <li className="nav-item">
              <NavLink
                to="/users"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <FaUserPlus
                    size={25}
                    className="text-secondary text-sm opacity-10"
                  />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  Sub admin
                </span>
              </NavLink>
            </li>
            <li className="nav-item mt-3 mb-2">
              <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
                General Setting
              </h6>
            </li>
            <li className="nav-item">
              <NavLink
                to="/admin-profile"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <FaUserCog
                    size={25}
                    className="text-secondary text-sm opacity-10"
                  />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  My Profile
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/admin-change-password"
                className="nav-link"
                activeClassName="is-active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <HiEyeOff
                    size={25}
                    className="text-secondary text-sm opacity-10"
                  />
                </div>
                <span className="nav-link-text ms-1 text-capitalize">
                  Change Password
                </span>
              </NavLink>
            </li>
            <li className="nav-item d-block d-md-none px-2">
              <button
                onClick={() => logoutHandle()}
                className="nav-link btn btn-outline-warning w-100 ms-0"
              >
                <span className="nav-link-text mx-auto text-capitalize text-bold">
                  Sign Out
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div className="sidenav-footer mx-3 "></div>
      </aside>
    </>
  );
};

export default SidebarAdmin;
