import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import UserAuthLayout from "../../../Layout/UserAuthLayout"
import { AuthUserApi } from '../../../utils/api';
import PaymentGatewayForm from './PaymentGatewayForm';
import BankDetails from './BankDetails';

const EditPaymentGateway = () => {

    const [order, setOrder] = useState({});
    const { id } = useParams();

    const getData = async () => {
        try {
            const listResponse = await AuthUserApi.get(`/user/order-get/${id}`);
            const { status, data } = listResponse;
            if (status === 200 && data.order !== undefined) {
                setOrder(data.order);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    useEffect(() => {
         getData();
    }, []);

    return (
        <>
            <UserAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="card">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="text-uppercase mb-3">purchase hvam by using Fiat money</h6>
                                        </div>
                                        <PaymentGatewayForm
                                            order={order}
                                            type="view"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {order.tranStatus == 'Pending' && 
                            <div className="col-12 col-md-6">
                                <div className="card bg-white mt-3">
                                    <div className="card-body">
                                        <BankDetails
                                            order={order}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </UserAuthLayout>
        </>
    )
}

export default EditPaymentGateway