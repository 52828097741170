import React from 'react'

const StatusInDraft = () => {
  return (
    <>
        <div className="card mb-4 mb-md-5">
            <div className="card-header bg-light rounded-0 pb-0 px-3">
                <h5 className="mb-2">Status</h5>
                <p className="mb-4 text-secondary fw-bold"><span className="badge badge-sm bg-gradient-secondary me-3">In Draft</span>Escrow In-draft</p>
            </div>
            <div className="card-body py-4 p-3">
                <p className="mb-4">Order is uncompleted by the user.</p>
            </div>
        </div>  
    </>
  )
}

export default StatusInDraft