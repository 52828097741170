import React, { useEffect, useState } from 'react'
import ValueUpdateCard from '../../../Components/ValueUpdateCard';
import AdminAuthLayout from '../../../Layout/AdminAuthLayout'
import { AuthApi } from '../../../utils/api';
import LatestOrder from '../../Escrow/LatestEscrow'

const AdminDashboard = () => {

     const [dashboardCount, setDashboardCount] = useState({});
     const [escrows, setEscrows] = useState([]);

     const getData = async () => {
          try {
               const listResponse = await AuthApi.get(`/admin/dashboard-data`);
               const { status, data } = listResponse;
               if (status === 200 && data.dashboardCount !== undefined) {
                    setDashboardCount(data.dashboardCount);
                    setEscrows(data.escrows);
               }
          } catch (e) {
               console.log(`😱 Axios request failed: ${e}`);
          }
     }

     useEffect(() => {
          getData();
     }, []);

     return (
            <>
                <AdminAuthLayout >
                    <div className="container-fluid py-4">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-8">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                                <ValueUpdateCard
                                                    Count={dashboardCount?.allOrder}
                                                    Title="All Order"
                                                    Bgcolor="bg-gradient-secondary"
                                                    Icon="fa fa-list"
                                                />
                                        </div>
                                        <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                                <ValueUpdateCard
                                                    Count={ dashboardCount?.totalAmt?.invoiceAmt ? parseFloat(dashboardCount?.totalAmt?.invoiceAmt) + parseFloat(dashboardCount?.totalAmt?.additionalAmt) : 0}
                                                    Title="Payment Received for sales"
                                                    Bgcolor="bg-gradient-success"
                                                    Icon="fa fa-list"
                                                />
                                        </div> 
                                        <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                                <ValueUpdateCard
                                                    Count={dashboardCount?.completedOrder}
                                                    Title="Completed Order"
                                                    Bgcolor="bg-gradient-success"
                                                    Icon="fa fa-check-double"
                                                />
                                        </div> 
                                        <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                                <ValueUpdateCard
                                                    Count={dashboardCount?.inProgressOrder}
                                                    Title="Processing Order"
                                                    Bgcolor="bg-gradient-warning"
                                                    Icon="fa fa-spinner"
                                                />
                                        </div> 
                                        <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                                <ValueUpdateCard
                                                    Count={dashboardCount?.pendingOrder}
                                                    Title="Pending Order"
                                                    Bgcolor="bg-gradient-info"
                                                    Icon="fa fa-clock-o"
                                                />
                                        </div>
                                        <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                                <ValueUpdateCard
                                                    Count={dashboardCount?.rejectedOrder}
                                                    Title="Rejected Order"
                                                    Bgcolor="bg-gradient-danger"
                                                    Icon="fa fa-times"
                                                />
                                        </div> 
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4">
                                    <LatestOrder   
                                        escrows={escrows}
                                    />
                                </div>
                            </div>
                    </div>
                </AdminAuthLayout>
            </>
     )
}

export default AdminDashboard