import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import './assets/css/custom.css';
import { fireabseInit, onMessageListener } from './utils/firebase';
import React, { useEffect } from 'react';
import { Suspense, useMemo, useState  } from 'react';
import { Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Flip } from 'react-toastify';
import { AdminContext } from './Context/AdminContext';
import { UserContext } from './Context/UserContext';

import AdminLogin from "./Views/Admin/Login/AdminLogin";
import AdminForgotPassword from "./Views/Admin/ForgotPassword/ForgotPassword";
import AdminResetPassword from "./Views/Admin/ForgotPassword/ResetPassword";
import AdminDashboard from "./Views/Admin/Dashboard/AdminDashboard";
import AdminTransaction from "./Views/Admin/Transaction/TransactionTable";

import Register from "./Views/User/Register/Register";
import Login from "./Views/User/Login/Login";
import ForgotPassword from "./Views/User/ForgotPassword/ForgotPassword";
import ResetPassword from "./Views/User/ForgotPassword/ResetPassword";
import VerifyEmail from "./Views/User/VerifyEmail/VerifyEmail";
import Dashboard from "./Views/User/Dashboard/Dashboard";
import EscrowTable from "./Views/Escrow/EscrowTable";
import TransactionForm from "./Views/Transactions/Transaction";
import TransactionSuccess from "./Views/Transactions/TransactionSuccessFull";
import TransactionRequest from "./Views/Transactions/TransactionRequest/TransactionRequest";
import PaymentSummary from "./Views/Transactions/TransactionSummary/PaymentSummary";
import Profile from "./Views/User/Profile/Profile";
import MyProfile from "./Views/Admin/GeneralSetting/MyProfile";
import ChangePassword from "./Views/Admin/GeneralSetting/ChangePassword";
import Customer from "./Views/Admin/Customer/List";
import EditCustomer from "./Views/Admin/Customer/Edit";
import UserList from "./Views/Admin/SystemSetting/Admin/List";
import AddUser from "./Views/Admin/SystemSetting/Admin/AddUser";
import EditUser from "./Views/Admin/SystemSetting/Admin/EditUser";
import TransactionCancel from "./Views/Transactions/TransactionCancel";
import Swapp from "./Views/Swapp/Swapp";
import SwappAdmin from "./Views/Swapp/SwappAdmin";
import ComplteTranscationDetails from "./Views/Admin/Transaction/ComplteTranscationDetails";
import Swapping from "./Views/Swapping/Swapping";
import BlockchainSetting from "./Views/Admin/BlockchainSetting/BlockchainSetting";
import AdminSetting from "./Views/Admin/AdminSetting/AdminSetting";
import ChangeUserPassword from "./Views/User/Profile/ChangeUserPassword";
import HvamOrderList from "./Views/Admin/AdminSetting/HvamOrderList";
import Withdraw from './Views/Admin/Withdraw/Withdraw';
import AddPaymentGateway from "./Views/PaymentGateway/UserGateway/AddPaymentGateway";
import ViewDetail from "./Views/PaymentGateway/UserGateway/ViewDetail";
import TransferredOrder from "./Views/PaymentGateway/AdminGateway/EditOrderDetail";
import BuyHvamFiat from './Views/PaymentGateway/UserGateway/BuyHvamFiat';
import TransferHvamFiat from './Views/PaymentGateway/AdminGateway/TransferHvamFiat';
import PaymentSuccessFull from './Views/PaymentGateway/UserGateway/PaymentSuccessFull';
import PaymentCancel from './Views/PaymentGateway/UserGateway/PaymentCancel';

function App() {

    let adminLocalStorage = null;
    let userLocalStorage = null;

    if (localStorage.getItem('admin_data') != null) {
        adminLocalStorage = JSON.parse(localStorage.getItem('admin_data'));
    }
    const [admin, setAdmin] = useState(adminLocalStorage);

    const adminValue = useMemo(() => ({ admin, setAdmin }), [admin, setAdmin]);

    
    if (localStorage.getItem('user_data') != null) {
        userLocalStorage = JSON.parse(localStorage.getItem('user_data'));
    }
    const [user, setUser] = useState(userLocalStorage);

    const value = useMemo(() => ({ user, setUser }), [user, setUser]);

    function onNotification(payload) {
        const data = payload.notification;
        const notificationTitle = data.title;
        const notificationOptions = {
            body: data.body
        };

        if (!('Notification' in window)) {
            console.log("Browser does not support system notification");

        } else if (Notification.permission === "granted") {
            new Notification(notificationTitle, notificationOptions);
        }
    }

    useEffect(() => {
        fireabseInit()
        onMessageListener(onNotification);
    }, []);

    
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/admin-login" element={<AdminLogin />} />
                    <Route path="/admin-forgot-password" element={<AdminForgotPassword />} />
                    <Route path="/admin-reset-password/:email" element={<AdminResetPassword />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:email" element={<ResetPassword />} />
                    <Route path="/swapping" element={<Swapping/>} />
                    <Route path="/verify-email/:id" element={<VerifyEmail/>} />
                </Routes>
                <UserContext.Provider value={value}>
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/create-eccrow/:id" element={<TransactionForm />} />
                        <Route path="/agreement-listing" element={<EscrowTable />} />
                        <Route path="/transaction-success/:id" element={<TransactionSuccess />} />
                        <Route path="/transaction-request/:id" element={<TransactionRequest/>} />
                        <Route path="/payment-summary/:id" element={<PaymentSummary/>} />
                        <Route path="/user-profile" element={<Profile/>} />
                        <Route path="/swapp" element={<Swapp/>} />
                        <Route path="/payment-gateway" element={<AddPaymentGateway/>} />
                        <Route path="/payment-success" element={<PaymentSuccessFull/>} />
                        <Route path="/payment-cancel" element={<PaymentCancel/>} />
                        <Route path="/view-order/:id" element={<ViewDetail/>} />
                        <Route path="/buy-fiat-money" element={<BuyHvamFiat/>} />
                        <Route path="/transaction-cancel/:id" element={<TransactionCancel/>} />
                        <Route path="/change-password" element={<ChangeUserPassword />} />
                </Routes>
                </UserContext.Provider>
                <AdminContext.Provider value={adminValue}>
                    <Routes>
                        <Route path="/admin-dashboard" element={<AdminDashboard />} />
                        <Route path="/admin-transactions" element={<AdminTransaction />} />
                        <Route path="/admin-profile" element={<MyProfile />} />
                        <Route path="/admin-change-password" element={<ChangePassword />} />
                        <Route path="/customers" element={<Customer />} />
                        <Route path="/edit-customer/:id" element={<EditCustomer />} />
                        <Route path="/users" element={<UserList />} />
                        <Route path="/add-user" element={<AddUser />} />
                        <Route path="/edit-user/:id" element={<EditUser />} />
                        <Route path="/admin-swapp" element={<SwappAdmin/>} />
                        <Route path="/order-detail/:id" element={<ComplteTranscationDetails/>} />
                        <Route path="/blockchain-setting" element={<BlockchainSetting/>} />
                        <Route path="/admin-setting" element={<AdminSetting/>} />
                        <Route path="/hvam-orderlist" element={<HvamOrderList/>} />
                        <Route path="/withdraw" element={<Withdraw/>} />
                        <Route path="/transfer-hvam-fiat" element={<TransferHvamFiat/>} />
                        <Route path="/transferred-order/:id" element={<TransferredOrder/>} />
                    </Routes>
                </AdminContext.Provider>
            </Suspense>
            <ToastContainer
                draggable={false}
                position="top-center"
                transition={Flip}
                autoClose={3000}
                pauseOnHover={true}
            />
        </>
    );
}

export default App;
