import React, { useContext, useState } from 'react'
import { Link } from "react-router-dom";
import { UserContext } from '../../../Context/UserContext';
import { AuthUserApi } from '../../../utils/api';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const TransactionStatus = (props) => {
    const { order, id } = props;
    const { user } = useContext(UserContext);
    const { role, wallet_address, name, company_name } = user?.user || {};
    const [checked, setChecked] = useState(true);
    const [copied, setCopied] = useState(false);

    const navigate = useNavigate();

    const handleChecked = (event) => {
        setChecked(event.target.checked);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const acceptOrRejectRequest = async (reqStatus) => {
        try {
            let sendData = { 'escrowStatus': reqStatus }
            if(role === 'Supplier'){
                sendData = { ...sendData, userWAddress : wallet_address, userContactName: name, userCompanyName: company_name};
            }else{
                sendData = { ...sendData, myWAddress : wallet_address, myName: name };
            }
            if (reqStatus == 'Rejected') sendData = { ...sendData, 'rejectedBy': role }
            let saveResponse = await AuthUserApi.put(`/user/transaction-update/${id}`, sendData);
            const { status, data } = saveResponse;

            if (status === 201 && data.escrow !== undefined) {
                if (reqStatus == 'In Progress') {
                    navigate("/agreement-listing");
                    toast.success('Request Accepted Successfully..');
                } else if (reqStatus == 'Rejected') {
                    navigate(`/transaction-cancel/${id}`);
                    toast.success('Request Rejected Successfully..');
                }
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
        }
    }

    return (
        <>
            {
                //  if statatus is rejected 
                order?.escrowStatus == 'Rejected' ?
                    <div className="card mb-4 mb-md-5">
                        <div className="card-header bg-light rounded-0 pb-0 px-3">
                            <h4 className="mb-2">Status</h4>
                            <p className="mb-4 text-danger"><span className="badge badge-sm bg-gradient-danger me-3">Rejected</span> Rejected by {order?.rejectedBy}</p>
                        </div>
                        <div className="card-body py-5 p-3">
                            <div className="row">
                                <div className="col-12">
                                    <h6 className="text-danger">Escrow Request Rejected</h6>
                                    <p className="text-danger">{order?.rejectedBy} rejected the escrow request.</p>
                                    {/* <button type="button" className="btn btn-primary btn-lg w-100 my-3">Edit And Recreate</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {/* for opposite user */}
                        {order?.userRole == role ?
                            <div className="card mb-4 mb-md-5">
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                    <h4 className="mb-4"> Accept Escrow Request</h4>
                                </div>
                                <div className="card-body py-5 p-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body pt-4 p-3">
                                                    <p>Please check that the information provided by your buyer is correct and you are agreeable to the payment release conditions for escrow</p>
                                                    <hr />
                                                    <button type="button" onClick={() => acceptOrRejectRequest('In Progress')} className={`btn btn-primary w-100 btn-lg mb-4 ${checked ? '' : 'disabled'}`}>Accept Request</button>
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={checked} onChange={handleChecked} />
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        I agree the <Link to="" className={`text-warning font-weight-bolder ${checked ? '' : 'disabled'}`}>Terms and Conditions</Link>
                                                    </label>
                                                    <Button className={`btn btn-warning btn-outline-danger w-100 btn-lg mt-4 ${checked ? '' : 'disabled'}`} variant="primary" onClick={handleShow}>Cancel Request</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            // person who created order
                            <div className="card mb-4 mb-md-5">
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                    <h4 className="mb-2">Status</h4>
                                    <p className="mb-4 text-info fw-bold"><span className="badge badge-sm bg-gradient-info me-3">{order?.escrowStatus}</span> Awaiting Acceptance</p>
                                </div>
                                <div className="card-body py-5 p-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <p>Your {order?.userRole} has to accept the request for the escrow transaction to proceed</p>
                                            <div className="card">
                                                <div className="card-body pt-4 p-3">
                                                    <h6 className="mb-4">Share Escrow Request again</h6>
                                                    <div className="input-group">
                                                        <input type="text" value={window.location.href} className="form-control" />
                                                        <CopyToClipboard className="btn text-sm px-2 px-md-3 btn-warning mb-0" text={window.location.href} onCopy={() => setCopied(true)}>
                                                            <span>Copy Link</span>
                                                        </CopyToClipboard>
                                                    </div>
                                                    {copied ? <span className="text-end d-block pe-3 pt-2" style={{ color: 'red' }}>Copied.</span> : null}
                                                    <p className="mt-3 text-small">Copy and paste the request link into email, chats or apps with your {order?.userRole}.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="mx-auto">Your attention is required</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="py-3 text-center">
                                    <i className="ni ni-bell-55 ni-3x"></i>
                                    <h4 className="text-gradient text-danger mt-4">Are you sure you want to cancel the agreement?</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="mx-auto d-flex justify-content-center">
                                <Button variant="danger" onClick={() => acceptOrRejectRequest('Rejected')}>
                                    Confirm Cancellation
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                    Back to Agreement
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
            }
        </>
    )
}

export default TransactionStatus