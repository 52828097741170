import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../../Context/UserContext";
import UserAuthLayout from "../../../Layout/UserAuthLayout";
import useForm from "../../../useForm";
import { AuthUserApi } from "../../../utils/api";
import { getEncryptId } from "../../../utils/secure";
import { validateUserEdit } from "../../../validate/validateUserRegister";
import { Form } from "react-bootstrap";
import { getShortAddress } from "../../../utils";
import WallateModal from "./WallateModal";
import { BiEdit } from "react-icons/bi";

const Profile = () => {
  const { user } = useContext(UserContext);

  const { id } = user?.user || {};
  let encId = getEncryptId(id);

  const [countries, setCountries] = useState([]);
  const [userData, setUser] = useState({});
  const [show, setShow] = useState(false);

  const getData = async () => {
    try {
      const listResponse = await AuthUserApi.get(`/user/get-user-profile`);
      const { status, data } = listResponse;
      if (status === 200 && data.countries !== undefined) {
        setCountries(data.countries);
        setUser(data.user);
      }
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
  };

  const submit = async () => {
    try {
      var saveResponse = await AuthUserApi.put(
        `/user/user-update/${encId}`,
        values
      );
      const { status, data } = saveResponse;

      if (status === 201 && data.user !== undefined) {
        toast.success("Profile Updated Successfully..");
      }
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
      const { response } = e;
      if (
        response !== undefined &&
        Object.keys(response.data).length &&
        response.data.error !== undefined
      ) {
        setErrors(response.data.error); // if error from server side
      }
    }
  };

  const formInputObj = {
    name: "",
    email: "",
    mobile_no: "",
    country_id: "",
    username: "",
    company_name: "",
  };

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      for (let [key] of Object.entries(formInputObj)) {
        formInputObj[key] = userData[key];
      }
      setValues({ ...formInputObj });
    }
  }, [userData]);

  const { handleChange, handleSubmit, values, errors, setErrors, setValues } =
    useForm(submit, validateUserEdit, formInputObj);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <UserAuthLayout>
        <div className="container-fluid py-4">
          <div className="row">
            <Form role="form" onSubmit={handleSubmit}>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row d-flex align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="form-group">
                          <label
                            for="example-text-input"
                            className="form-control-label text-lg mb-1"
                          >
                            <i className="fa fa-user me-sm-1"></i>{" "}
                            {userData?.name} {`( ${userData?.role} )`}
                          </label>
                          {/* <label className="d-block ms-4 ps-1">User Id : 12689</label> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 text-end d-flex justify-content-end">
                        <div>
                          <div className="d-flex align-items-center justify-content-end">
                            <label className="form-control-label text-sm mb-1 d-block">
                              Wallet Address
                            </label>
                            <button
                              onClick={() => setShow(true)}
                              type="button"
                              class="btn bg-success-info mb-0 shadow-none ps-1 pe-0 ms-2"
                            >
                              <BiEdit size={20} />
                            </button>
                          </div>
                          <button
                            type="button"
                            class="btn bg-gradient-info mb-0"
                          >
                            {getShortAddress(userData?.wallet_address)}
                          </button>
                        </div>
                        <div>
                          <label className="form-control-label text-sm mb-1 d-block p-2"></label>
                        </div>
                      </div>
                    </div>
                    <hr className="horizontal dark" />
                    <p className="text-uppercase text-sm">User Information</p>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            for="example-text-input"
                            className="form-control-label"
                          >
                            Username
                          </label>
                          <input
                            readOnly
                            className="form-control"
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            placeholder="Username"
                          />
                          {errors.username && (
                            <small className="text-danger">
                              {errors.username}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            for="example-text-input"
                            className="form-control-label"
                          >
                            Full Name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            placeholder="Full Name"
                          />
                          {errors.name && (
                            <small className="text-danger">{errors.name}</small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            for="example-text-input"
                            className="form-control-label"
                          >
                            Email address
                          </label>
                          <input
                            readOnly
                            className="form-control"
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Email"
                          />
                          {errors.email && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            for="example-text-input"
                            className="form-control-label"
                          >
                            Contact Number
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="mobile_no"
                            value={values.mobile_no}
                            onChange={handleChange}
                            placeholder="Mobile Number"
                          />
                          {errors.mobile_no && (
                            <small className="text-danger">
                              {errors.mobile_no}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            for="example-text-input"
                            className="form-control-label"
                          >
                            Company Name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="company_name"
                            value={values.company_name}
                            onChange={handleChange}
                            placeholder="Company Name"
                          />
                          {errors.company_name && (
                            <small className="text-danger">
                              {errors.company_name}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label
                          for="example-text-input"
                          className="form-control-label"
                        >
                          Country
                        </label>
                        <Form.Group className="mb-3">
                          <Form.Select
                            className="mb-3"
                            aria-label="Default select example"
                            name="country_id"
                            value={values.country_id}
                            onChange={handleChange}
                          >
                            <option>Select Country</option>
                            {countries &&
                              countries.length &&
                              countries.map((country) => (
                                <option value={country.id}>
                                  {country.country_name}
                                </option>
                              ))}
                          </Form.Select>
                          {errors.country_id && (
                            <small className="text-danger">
                              {errors.country_id}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-end">
                        {/* <button className="btn btn-primary btn-sm mb-0 mt-3" onClick={handleShow}> 
                                                    Update Walate Address
                                                </button> */}
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm mb-0 mt-3"
                        >
                          Update Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <WallateModal
          show={show}
          setShow={setShow}
          walletAddr={userData?.wallet_address}
        />
      </UserAuthLayout>
    </>
  );
};

export default Profile;
