import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import UserAuthLayout from '../../../Layout/UserAuthLayout';

const PaymentSuccessFull = () => {
    const [copied, setCopied] = useState(false);
    const { id } = useParams();

    return (
        <>
            <UserAuthLayout>
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center mt-5">
                        <div className="col-12 col-md-9">
                            <div className="card mb-4 text-center py-4">
                                <div className="card-header pb-0 px-3">
                                    <h4 className="mb-3">Your transaction is completed!</h4>
                                </div>
                                <div className="card-footer pb-0 px-3">
                                    <Link to="/payment-gateway" className="btn btn-outline-default me-4">Create Another Order</Link>
                                    <Link to="/buy-fiat-money" className="btn bg-gradient-warning">Back to Orders</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UserAuthLayout>
        </>
    )
}

export default PaymentSuccessFull