import React, { useEffect, useState } from 'react'
import AdminAuthLayout from '../../../../Layout/AdminAuthLayout'
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { AuthApi } from '../../../../utils/api';
import ListTr from './ListTr'

const UserList = () => {

    const [adminUsers, setAdminUsers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(1);

    const getData = async (pgNO) => {
        try {
        const listResponse = await AuthApi.get(`/admin/admins?pageNo=${pgNO}`);
        const { status, data } = listResponse;
        if (status === 200 && data.admins !== undefined) {
            setAdminUsers(data.admins);
            const { pageData: { per_page, total_record } } = data || {};
            setPerPage(per_page);
            setTotalRecords(total_record);
        }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const getClickedPageNo = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    useEffect(() => {
        getData(currentPage)
    }, []);

    return (
        <>
            <AdminAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header pb-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0">User</h5>
                                        <Link to="/add-user" className="btn btn-primary btn-sm ms-auto">+ Add User</Link>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="text-uppercase text-sm">User List</p>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table align-items-center mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Sr. No.
                                                            </th>
                                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Name
                                                            </th>
                                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Mobile Number
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Email Address
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Role
                                                            </th>
                                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                Status
                                                            </th>
                                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                ACTION
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            adminUsers && adminUsers.length > 0 && adminUsers.map((adminUser, indx) => {
                                                            return(
                                                                <ListTr
                                                                    adminUser={adminUser}
                                                                    key={indx}
                                                                    indx={indx}
                                                                />
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center justify-content-md-end pt-3">
                                        {
                                            // totalRecords > 10 &&
                                            <Pagination
                                                activePage={currentPage}
                                                prevPageText='prev'
                                                nextPageText='next'
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                pageRangeDisplayed={5}
                                                itemsCountPerPage={perPage}
                                                totalItemsCount={totalRecords}
                                                onChange={getClickedPageNo}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminAuthLayout>
        </>
    )
}

export default UserList