import React from "react";
import { Link } from "react-router-dom";
import { getEncryptId } from "../../../utils/secure";

const UserListTr = (props) => {

    const { customer, indx } = props;

    return (
        <>
            <tr>
                <td>
                    <div className="d-flex px-2 py-1">
                        <div className="d-flex flex-column justify-content-center">
                            <p className="text-xs text-secondary mb-0">{indx+1}</p>
                        </div>
                    </div>
                </td>
                <td>
                    <Link to={`/edit-customer/${getEncryptId(customer?.id)}`} className="text-xs font-weight-bold mb-0 text-primary">{customer?.name}</Link>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{customer?.mobile_no}</p>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0"> {customer?.email} </p>
                </td>
                <td className="align-middle text-start text-sm">
                    <p className="text-xs text-secondary mb-0">{customer?.role}</p>
                </td>
                <td className="align-middle text-start">
                    <p className="text-xs text-secondary mb-0"> {customer?.country?.country_name} </p>
                </td>
                <td className="align-middle text-center">
                    <Link to={`/edit-customer/${getEncryptId(customer?.id)}`} className="text-xs font-weight-bold mb-0 text-primary">Edit</Link>
                </td>
            </tr>
        </>
    );
};

export default UserListTr;
