import React, { useState } from "react";
import {  Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { FcGoogle } from "react-icons/fc";
const { REACT_APP_SITE_KEY } = process.env;
// import GoogleLogin from 'react-google-login';

const LoginForm = (props) => {
    const [captchaCheck, setCaptchaCheck] = useState(false);
    
    const handleCaptaChange = (val) => {
        setCaptchaCheck(val !== null);
    }

    const { handleSubmit, handleChange, values, errors } = props;

    return (
        <>
            {/* <div>
                <Link to="" className="btn btn-outline-warning btn-lg text-lg px-3 w-100"> <FcGoogle size="28" className="me-2"/> Sign in with Google</Link>
            </div> */}
            {/* <GoogleLogin
                clientId="1044953672736-t5fct632of6h2fsa01lcpris5tinhs98.apps.googleusercontent.com"
                render={renderProps => (
                    <Button color="googleRegiBtn" onClick={renderProps.onClick} disabled={renderProps.disabled}><span className="icon-search"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>  Sign in with Google</Button>
                    )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
            /> */}
            {/* <div className="text-center">
                <p className="text-center border d-inline-block mx-auto py-2 px-3 rounded-circle fw-bold bg-light">Or</p>
            </div> */}
            <Form role="form" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <input className="form-control form-control-lg" placeholder="Enter email or username" aria-label="Email" type="text" name="email" value={values.email} onChange={handleChange}/>
                    {errors.email && <small className="text-danger">{errors.email}</small>}
                </div>
                <div className="mb-3">
                    <input className="form-control form-control-lg" placeholder="Password" aria-label="Password" type="password" name="password" value={values.password} onChange={handleChange}/>
                    {errors.password && <small className="text-danger">{errors.password}</small>}
                </div>
               <div className="d-flex mb-2">
                    <Form.Check className="mb-1 me-3" type="radio" label="Buyer" value="Buyer" name="role" onChange={handleChange} checked={values.role == "Buyer"}/>
                    <Form.Check className="mb-1" type="radio" label="Supplier" value="Supplier" name="role" onChange={handleChange} checked={values.role == "Supplier"}/>
               </div>
               <div className="mb-2 recptchaset">
                    <ReCAPTCHA
                        sitekey={REACT_APP_SITE_KEY}
                        onChange={handleCaptaChange}
                    />
               </div>
               <p className="m-0">{errors.role && <small className="text-danger">{errors.role}</small>}</p>
                <Button disabled={!captchaCheck} variant="warning" type="submit" size="lg" className="btn-lg w-100 mt-2 mb-0">
                    Sign in
                </Button>
            </Form>
            <div className="card-footer text-center pt-0 px-lg-2 px-1">
                <p className="mt-4 text-sm mx-auto">
                    Don't have an account?
                    <Link to="/register" className="text-warning text-gradient font-weight-bold"> Sign up</Link>
                </p>
                <p className="my-0 text-sm mx-auto">
                    <Link to="/forgot-password" className="text-warning text-gradient font-weight-bold">Forgot Password ?</Link>
                </p>
            </div>
           
        </>
    );
};

export default LoginForm;
