import React from 'react'
import { useEffect, useState } from 'react'
import Pagination from "react-js-pagination";
import SearchBr from '../../../Components/SearchBr'
import AdminAuthLayout from '../../../Layout/AdminAuthLayout'
import { AuthApi } from '../../../utils/api'
import TransactionTableTr from './TransactionTableTr'

const TransactionTable = () => {

    const [orders, setOrders] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(1);
    const [parameter, setParameter] = useState();

    const getData = async (pgNO) => {
        try {
            const listResponse = await AuthApi.get(`/admin/transactions?pageNo=${pgNO}`);
            const { status, data } = listResponse;
            if (status === 200 && data.escrows !== undefined) {
                setOrders(data.escrows);
                const { pageData: { per_page, total_record } } = data || {};
                setPerPage(per_page);
                setTotalRecords(total_record);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const getFilterData = async (pgNO) => {
        try {
            const listResponse = await AuthApi.get(`/admin/transactions?pageNo=${pgNO}`, {
                params: { parameter: parameter }
            });
            const { status, data } = listResponse;
            if (status === 200 && data.escrows !== undefined) {
                setOrders(data.escrows);
                const { pageData: { per_page, total_record } } = data || {};
                setPerPage(per_page);
                setTotalRecords(total_record);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            // setFilterErr();
        }
    }

    const handleSearch = event => {
        const { value } = event.target;
        setParameter(value);
    };

    const getClickedPageNo = (pageNumber) => {
        setCurrentPage(pageNumber);
        parameter !== '' ? getFilterData(pageNumber) : getData(pageNumber);
    }

    useEffect(() => {
        getData(currentPage)
    }, []);


    return (
        <>
            <AdminAuthLayout>
                <SearchBr
                    Pageheading="Order List"
                    getFilterData={getFilterData}
                    handleSearch={handleSearch}
                    parameter={parameter}
                />
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-lg-12 mb-lg-0 mb-4">
                            <div className="card ">
                                <div className="card-header pb-0 p-3">
                                    <div className="d-flex justify-content-between">
                                    </div>
                                </div>
                                <div className="card-body">
                                    {/* <Filters/> */}
                                </div>
                                <div className="table-responsive">
                                    <div className="card-body pt-2 p-3">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                                                        TRANSACTION NO.
                                                    </th>
                                                    <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        DATE
                                                    </th>
                                                    <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        BUYER
                                                    </th>
                                                    <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        SUPPLIER
                                                    </th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        STATUS
                                                    </th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        INVOICE AMT
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orders && orders.length > 0 && orders.map((order, indx) => {
                                                        return (
                                                            <TransactionTableTr
                                                                order={order}
                                                                key={indx}
                                                            />
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center justify-content-md-end pt-3">
                                    {
                                        // totalRecords > 10 &&
                                        <Pagination
                                            activePage={currentPage}
                                            prevPageText='prev'
                                            nextPageText='next'
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            pageRangeDisplayed={5}
                                            itemsCountPerPage={perPage}
                                            totalItemsCount={totalRecords}
                                            onChange={getClickedPageNo}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminAuthLayout>
        </>
    )
}

export default TransactionTable