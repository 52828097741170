export default function validateCountry(values) {
    let errors = {};
	if (!values.country_name) {
		errors.country_name = "Country name is required";
	}
	if (!values.country_code) {
		errors.country_code = "Country code is required";
	}
    
    return errors;
}