export default function validateEscrow(values) {
    let errors = {};
    if (!values.userEmail) {
        errors.userEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.userEmail)) {
    	errors.userEmail = "Email address is invalid";
    }else if(values.userEmail == values.myEmail){
    	errors.userEmail = "Email address must be different";
    }
    if (!values.userCountry) {
    	errors.userCountry = "Country is required";
    } 
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (!values.invoiceAmt) {
    	errors.invoiceAmt = "Invoice amount is required";
    }else if (values.invoiceAmt == 0 || !validNumber.test(values.invoiceAmt)) {
    	errors.invoiceAmt = "Amount should be number and greater than 0";
    }
    if (!values.description) {
    	errors.description = "Description is required";
    }
    if (!values.startDate) {
    	errors.startDate = "Start date is required";
    }
    if (!values.endDate) {
    	errors.endDate = "End date is required";
    }else if(values.startDate && values.endDate && values.startDate > values.endDate){
        errors.endDate = "End date should be greater than start date";
    }
    
    return errors;
}