import React, { useState, useEffect, useRef, useContext } from "react";
import { ethers } from "ethers";
import { ABI, ADDRESS, TOKENS } from "./../../Contract";
import ImgUsdt from "../../assets/img/usdt.svg";
import ImgBnb from "../../assets/img/bnb.svg";
import ImgEth from "../../assets/img/eth.svg";
import ImgBtc from "../../assets/img/btc.svg";
import ImgBusd from "../../assets/img/busd.png";
import ImgHvam from "../../assets/img/logo.png";
import WalletConnect from "../../Components/WalletConnect";
import SwappingCardRight from "./SwappingCardRight";
import { Dropdown } from "react-bootstrap";
import { useSigner, useProvider, useAccount, useContract } from "wagmi";

const SwappingCard = (props) => {
    const library = useProvider({
        chainId: 56,
    });
    const { data: signer } = useSigner();
    const { isConnected: active, address: account } = useAccount();

    const adminRef = useRef(null);
    const [contract, setContract] = useState(null);
    const [fromContract, setFromContract] = useState(null);
    const [toContract, setToContract] = useState(null);
    const [hvamUSD, setHvamUSD] = useState(0.53);
    const [fromBalance, setFromBalance] = useState(0);
    const [toBalance, setToBalance] = useState(0);
    const [txStatus, setTxStatus] = useState("");
    const [adminAddress, setAdminAddress] = useState("");
    const [isAdmin, setIsAdmin] = useState("");
    const [totalSupply, setTotalSupply] = useState(0);
    const [adminStatus, setAdminStatus] = useState("");

    const { show } = props;

    const [fromAmount, setFromAmount] = useState(0);
    const [toAmount, setToAmount] = useState(0);

    const fromTokens = [{ id: "HVAM", tag: "HVAM", img: ImgHvam, DECIMALS: 18 }];
    const [selectedFromToken, setSelectedFromToken] = useState(fromTokens[0]);

    const toTokens = [
        { id: 1, tag: "USDT", img: ImgUsdt, DECIMALS: 18 },
        { id: 2, tag: "BNB", img: ImgBnb, DECIMALS: 8 },
        { id: 3, tag: "BTCB", img: ImgBtc, DECIMALS: 18 },
        { id: 4, tag: "ETH", img: ImgEth, DECIMALS: 18 },
        { id: 5, tag: "BUSD", img: ImgBusd, DECIMALS: 18 },
    ];
    const [selectedToToken, setSelectedToToken] = useState(toTokens[0]);

    const resetState = () => {
        setFromAmount(0);
        setToAmount(0);
        getBalance(fromContract, setFromBalance);
        getBalance(toContract, setToBalance);
    };

    const getContract = (address, ABI) => {
        const contract = new ethers.Contract(address, ABI, library);

        return contract.connect(signer);
    };

    const buyTokens = async () => {
        try {
        if (selectedToToken.id !== 2) {
            const allowance = await toContract.allowance(account, ADDRESS);

            setTxStatus("checking allowance");
            if (Number(allowance.toString()) < toAmount) {
            setTxStatus("Setting Allowance...");
            const atx = await toContract.approve(
                ADDRESS,
                ethers.utils
                .parseUnits("1000000", TOKENS[selectedToToken.tag].DECIMALS)
                .toString()
            );
            setTxStatus("Processig Allowance...");
            await atx.wait();
            setTxStatus("Allowance Processed...");
            }
        }
        const _value = selectedToToken.id !== 2 ? 0 : (Number(toAmount) + ((Number(toAmount) * 5)/100));
        setTxStatus("Confirm Transaction on Wallet");
        const _fromAmount = ethers.utils
            .parseUnits(fromAmount, TOKENS[selectedFromToken.tag].DECIMALS)
            .toString();

        const tx = await contract.buyTokens(_fromAmount, selectedToToken.id, {
            value: ethers.utils.parseEther(_value.toString()),
            gasLimit: 200000
        });

        setTxStatus("Waiting For Confirmation");
        await tx.wait();
        setTxStatus("Transaction Completed");
        resetState();
        } catch (error) {
        console.log(error);
        setTxStatus(
            error?.error?.data?.message ||
            error?.error?.message ||
            "Transaction Reverted"
        );
        }
    };

    const getBalance = (contract, setter) => {
        if (contract && account) {
        contract.balanceOf(account).then((res) => {
            setter(Number(ethers.utils.formatEther(res.toString())).toFixed(8));
        });
        } else {
        if (selectedToToken.id === 2) {
            library?.getBalance(account).then((res) => {
            setter(Number(ethers.utils.formatEther(res.toString())).toFixed(12));
            });
        }
        }
    };

    useEffect(() => {
        if (library) {
        setContract(getContract(ADDRESS, ABI));
        } else {
        setContract(null);
        }
    }, [library, account, active, signer]);

    useEffect(() => {
        if (contract) {
        Promise.all([
            contract
            .price()
            .then((res) => ethers.utils.formatUnits(res.toString(), 8)),
            contract.admin().then((res) => res.toString()),
            contract
            .totalTokenSold()
            .then((res) => ethers.utils.formatUnits(res.toString())),
        ])
            .then((vals) => {
            setHvamUSD(vals[0] || 0);
            setAdminAddress(vals[1] || "");
            setIsAdmin(vals[1] === account ? true : false);
            setTotalSupply(vals[2] || 0);
            })
            .catch((err) => console.log(err));
        }
    }, [contract]);

    useEffect(() => {
        if (library && signer) {
        const fromTokenAddress = TOKENS[selectedFromToken.tag].ADDRESS;
        const fromTokenABI = TOKENS[selectedFromToken.tag].ABI;
        const fContract = getContract(fromTokenAddress, fromTokenABI);
        setFromContract(fContract);
        } else {
        setFromContract(null);
        }
    }, [account, selectedFromToken, active, signer, library]);

    useEffect(() => {
        if (active && selectedToToken.id !== 2 && contract) {
        const toTokenABI = TOKENS[selectedToToken.tag].ABI;

        contract.getTokensByType(selectedToToken.id).then((res) => {
            const tContract = getContract(res, toTokenABI);

            setToContract(tContract);
        });
        } else {
        setToContract(null);
        }
    }, [account, selectedToToken, active, contract]);

    useEffect(() => {
        if (fromContract) {
        getBalance(fromContract, setFromBalance);
        }
    }, [fromContract?.balanceOf, account, active, contract]);

    useEffect(() => {
        getBalance(toContract, setToBalance);
    }, [toContract?.balanceOf, account, active, contract]);

    const resetChildState = () => {
        typeof adminRef.current.resetState === "function" &&
        adminRef.current.resetState();
    };

    const callPrice = async (amount) => {
        try {
        setAdminStatus("Please confirm transaction on wallet");

        const _amount = ethers.utils.parseUnits(amount, 8).toString();

        const tx = await contract.updatePrice(_amount);
        setAdminStatus("Waiting For Confirmation");

        await tx.wait();
        setAdminStatus("Pricing updated.");

        resetChildState();
        } catch (err) {
        setAdminStatus(err.message);
        }
    };

    const callToken = async (address) => {
        try {
        setAdminStatus("Please confirm transaction on wallet");

        const tx = await contract.setToken(address);
        setAdminStatus("Waiting For Confirmation");

        await tx.wait();
        setAdminStatus("Token updated.");

        resetChildState();
        } catch (error) {
        setAdminStatus(error.message);
        }
    };

    const callUpdateToken = async (address, receiveAddress) => {
        try {
        setAdminStatus("Please confirm transaction on wallet");

        const tx = await contract.inCaseTokensGetStuck(address, receiveAddress);
        setAdminStatus("Waiting For Confirmation");

        await tx.wait();
        setAdminStatus("Stucked token updated.");

        resetChildState();
        } catch (error) {
        setAdminStatus(error.message);
        }
    };

    function calcToAmount(amount) {
        amount = !amount ? "0" : amount;

        const _amount = ethers.utils
        .parseUnits(amount, TOKENS[selectedToToken.tag].DECIMALS)
        .toString();

        contract.amountNeedsToBePaid(_amount, selectedToToken.id).then((res) => {
        setToAmount(
            Number(
            ethers.utils.formatUnits(res.toString(), selectedToToken.DECIMALS)
            ).toFixed(8)
        );
        });
    }

    function calcFromAmount(amount) {
        amount = !amount ? "0" : amount;

        const _amount = ethers.utils
        .parseUnits(amount, TOKENS[selectedToToken.tag].DECIMALS)
        .toString();

        contract.getCorrespondingTokens(_amount, selectedToToken.id).then((res) => {
        setFromAmount(
            Number(
            Number(
                ethers.utils.formatUnits(
                res.toString(),
                selectedToToken.id === 2
                    ? selectedToToken.DECIMALS
                    : selectedFromToken.DECIMALS
                )
            ).toFixed(8)
            ).toFixed(8)
        );
        });
    }

    useEffect(() => {
        if (contract && fromAmount > 0) {
        calcToAmount(fromAmount);
        } else {
        setToAmount(0);
        }
    }, [selectedFromToken, selectedToToken, contract]);
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                <div className="card swapcarad">
                    <div className="card-header pb-0 px-3">
                    <h6 className="mb-0">Buy HVAM</h6>
                    <div className="row">
                        <div className="col-md-6">Buy HVAM Token In An Instant</div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <i className="far fa-calendar-alt me-2"></i>
                        <small>
                            1 {selectedFromToken.tag || ""} = {hvamUSD} USD
                        </small>
                        </div>
                    </div>
                    </div>
                    <div className="card-body pt-4 p-3">
                    <div className="card mb-3">
                        <div className="card-body  p-3">
                        <form role="form">
                            <div className="row">
                            <div className="col-12">
                                <label
                                className="text-end text-lg d-block"
                                for="EndDate"
                                >
                                Balance : {fromBalance}
                                </label>
                                <div className="input-group">
                                {/* <Dropdown>
                                    <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="btn bg-gradient-dark btn-lg px-0 px-md-3 dropdown-toggle mb-0"
                                    >
                                    <img
                                        src={selectedFromToken.img}
                                        className="img-fluid me-1 swapcoinimg"
                                        alt=""
                                    />{" "}
                                    {selectedFromToken.tag}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                    {(fromTokens || []).map((token) => (
                                        <Dropdown.Item
                                        onClick={() =>
                                            setSelectedFromToken({ ...token })
                                        }
                                        href="#"
                                        >
                                        <img
                                            src={token.img}
                                            className="img-fluid me-1 swapcoinimg"
                                            alt=""
                                        />{" "}
                                        {token.id}
                                        </Dropdown.Item>
                                    ))}
                                    </Dropdown.Menu>
                                </Dropdown> */}
                                <div
                                    variant="success"
                                    id="dropdown-basic"
                                    className="btn bg-gradient-dark btn-lg px-0 px-md-3 mb-0"
                                >
                                    <img
                                    src={selectedFromToken.img}
                                    className="img-fluid me-1 swapcoinimg"
                                    alt=""
                                    />{" "}
                                    {selectedFromToken.tag}
                                </div>
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    className="form-control form-control-lg text-end"
                                    placeholder="0.00"
                                    value={fromAmount}
                                    onChange={(e) => {
                                    setFromAmount(e.target.value);
                                    calcToAmount(e.target.value);
                                    }}
                                />
                                </div>
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body p-3">
                        <form role="form">
                            <div className="row">
                            <div className="col-12">
                                <label
                                className="text-end text-lg d-block"
                                for="EndDate"
                                >
                                Balance : {toBalance}
                                </label>
                                <div className="input-group">
                                <Dropdown>
                                    <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="btn bg-gradient-dark btn-lg px-0 px-md-3 dropdown-toggle mb-0"
                                    >
                                    <img
                                        src={selectedToToken.img}
                                        className="img-fluid me-1 swapcoinimg"
                                        alt=""
                                    />{" "}
                                    {selectedToToken.tag}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                    {(toTokens || []).map((token) => (
                                        <Dropdown.Item
                                        onClick={() => {
                                            setSelectedToToken({ ...token });
                                        }}
                                        href="#"
                                        >
                                        <img
                                            src={token.img}
                                            className="img-fluid me-1 swapcoinimg"
                                            alt=""
                                        />{" "}
                                        {token.tag}
                                        </Dropdown.Item>
                                    ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <input
                                    type="text"
                                    className="form-control form-control-lg text-end"
                                    value={toAmount}
                                    onChange={(e) => {
                                    setToAmount(e.target.value);
                                    calcFromAmount(e.target.value);
                                    }}
                                />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="text-center">
                                {active ? (
                                    <button
                                    type="button"
                                    className="btn btn-lg btn-warning btn-lg w-100 mt-2 mb-0"
                                    // disabled={(fromAmount <= 0 || (selectedToToken.id != 2 && toAmount <= 0) || toBalance <= 0 || (selectedToToken.id != 2 && toAmount <= toBalance))}
                                    onClick={() => buyTokens()}
                                    >
                                    Swap
                                    </button>
                                ) : (
                                    <WalletConnect className="btn btn-lg btn-warning btn-lg w-100 mt-2 mb-0" />
                                )}
                                <p className="mt-1 text-success text-capitalize">
                                    {txStatus}
                                </p>
                                </div>
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-12 col-md-6">
                {show && (
                    <SwappingCardRight
                    isAdmin={isAdmin}
                    adminAddress={adminAddress}
                    hvamUSD={hvamUSD}
                    fromToken={selectedFromToken.tag || ""}
                    totalSupply={totalSupply}
                    callPrice={callPrice}
                    callToken={callToken}
                    callUpdateToken={callUpdateToken}
                    adminStatus={adminStatus}
                    ref={adminRef}
                    />
                )}
                </div>
            </div>
        </>
    );
};

export default SwappingCard;
