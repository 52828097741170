import React from 'react'

const StatusDealRejected = (props) => {
    const { order, isConnected, role_id, updateDealStatus } = props;

	return (
		<>
			<div className="card mb-4 mb-md-5">
				<div className="card-header bg-light rounded-0 pb-0 px-3">
					<h4 className="mb-2">Status</h4>
					<p className="mb-4 text-danger"><span className="badge badge-sm bg-gradient-danger me-3">Rejected</span> Rejected by { order?.rejectedBy }</p>
				</div>
				<div className="card-body py-5 p-3">
					<div className="row">
						<div className="col-12">
							<h6 className="text-danger">Escrow Request Rejected</h6>
							<p className="text-danger">{ order?.rejectedBy } rejected the escrow request. Acknowledge superadmin for payment refund.</p>
						</div>
						{
                            order?.paymentStatus == 'Credited' &&
							<>
								{
									role_id == 1 ?
									<div className="col-12 col-md-6">
										<button disabled={true} type="button" className="btn bg-gradient-danger w-100 mx-1">Refund Payment</button>
									</div>
									:
									<div className="col-12 col-md-6">
										<button type="button" className="btn bg-gradient-danger w-100 mx-1" onClick={() => updateDealStatus('Deal Cancel')}>Deal Cancel Ack</button>
									</div>
								}
							</>
                        }
					</div>
				</div>
			</div>
		</>
	)
}

export default StatusDealRejected