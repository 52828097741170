import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import LoginForm from "./LoginForm";
import { AuthApi, GuestApi } from "../../../utils/api";
import useForm from "../../../useForm";
import validate from "../../../validate/validateAdminLogin";
import { toast } from "react-toastify";
import GuestLayout from "../../../Layout/GuestLayout";
import { getBrowserToken } from '../../../utils/firebase';

const AdminLogin = (props) => {
    const navigate = useNavigate();

    const formInputObj = {
        email: "",
        password: "",
    };

    const sendTokenToServer = async (browserToken, accessToken) => {
        try {
            const requestBody = {
                fcm_token: browserToken,
            }
            const config = {
                headers: {
                    Authorization: `bearer ${accessToken}`
                }
            }
            const saveDeviceTokenResponse = await AuthApi.post("/admin/register-device", requestBody, config);
            const { status } = saveDeviceTokenResponse;
            if (status === 200) {
                return;
            } else {
                console.log(saveDeviceTokenResponse);
            }
        } catch (error) {
            console.log({ error });
        }
    }
    
    const submit = async () => {
        try {
            var saveResponse = await GuestApi.post('/admin/login', values);
            const { status, data } = saveResponse;
            
            if (status === 200 && data.token !== undefined && Object.keys(data.token).length) {
                localStorage.setItem('admin_data', `${JSON.stringify(data)}`);
                setValues({ ...formInputObj });
               
                const permisssionResult = Notification.permission;
                if (permisssionResult === "granted") {
                    const browserToken = await getBrowserToken();
                    await sendTokenToServer(browserToken, data.token.access_token);

                } else { // Request for permission 
                    const notificationPermissionResult = await Notification.requestPermission();
                    if (notificationPermissionResult === "granted") {
                        const browserToken = await getBrowserToken();
                        await sendTokenToServer(browserToken, data.token.access_token);
                    }
                }

                toast.success('Login Successfully..');
                navigate("/admin-dashboard");
                
                window.location.reload();
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } =  useForm(submit, validate, formInputObj);

    return (
        <>
            <GuestLayout
                Title="Attention is the new currency"
                Subtitle="The more effortless the writing looks, the more effort the
                        writer actually put into the process."
                FormTitle="Sign In"
                FormSubTitle="Enter your email and password to sign in"
            >
                <LoginForm
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    setValues={setValues}
                    values={values}
                    errors={errors}
                />
                <p className="my-4 text-sm mx-auto text-center">
                    <Link to="/admin-forgot-password">Forgot Password ?</Link>
                </p>
            </GuestLayout>
        </>
    );
}
export default AdminLogin;
