export default function validateChangePassword(values) {
    let errors = {};
    if (!values.current_password) {
        errors.current_password = "Password is required";
    } else if (values.current_password.length < 6) {
        errors.current_password = "Password needs to be more than 5 characters";
    }
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,15})");
    if (!values.password) {
        errors.password = "New password is required";
    }  else if (!strongRegex.test(values.password)) {
        errors.password = "New password should be between 6 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
    }  else if (values.password.length < 6) {
        errors.password = "New password must be min 6 characters & max 15 characters";
    }
    if (!values.confirm_password) {
        errors.confirm_password = "confirm password is required";
    } else if (values.password !== values.confirm_password) {
        errors.password = "New Password and Confirm password does not match";
    }
    return errors;
}