import React from "react";
import { useState } from "react";

const SearchBr = (props) => {
  const { getFilterData, handleSearch, parameter } = props;

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body p-3">
                <div className="row d-flex align-items-center">
                  <div className="col-12 col-md-6">
                    <h4 className="mb-3 mb-md-0 text-uppercase">
                      {props.Pageheading}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 text-end">
                    <div className="row d-flex justify-content-end">
                      <div className="col-8 col-md-6">
                        <input
                          type="text"
                          name="parameter"
                          onChange={handleSearch}
                          value={parameter}
                          className="form-control form-control-lg"
                          placeholder="Email or Name"
                          aria-label="Email or Name"
                        />
                      </div>
                      <div className="col-4 col-md-3 ps-0">
                        <button
                          type="button"
                          onClick={() => getFilterData(1)}
                          className="btn btn-lg btn-info w-100 px-2 mb-0"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBr;
