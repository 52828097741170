import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import UserAuthLayout from '../../Layout/UserAuthLayout';
import CancelImage from "../../assets/img/rejectAgreement.svg";
import { AuthApi } from '../../utils/api';

const TransactionCancel = () => {

    const [order, setOrder] = useState({});

    const { id } = useParams();

    const getData = async () => {
        try {
            const listResponse = await AuthApi.get(`/admin/transaction-get/${id}`);
            const { status, data } = listResponse;
            if (status === 200 && data.escrow !== undefined) {
                setOrder(data.escrow);               
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <UserAuthLayout>
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center mt-5">
                        <div className="col-12 col-md-9">
                            <div className="card mb-4 text-center py-4">
                                <div className="card-body pt-3 p-3">
                                    <img src={CancelImage} alt="" className="mb-3" />
                                    <h4 className="mb-3">We’re notifying your {order?.myRole} of the cancellation.</h4>
                                    <p className="mt-4">Sit back while we update your {order?.myRole} of your request. Meanwhile, explore the rest of Escrows to optimize your trades.</p>
                                </div>
                                <div className="card-footer pb-0 px-3">
                                    <Link to="/create-eccrow/0" className="btn btn-outline-default me-4">Create Another Escrow</Link>
                                    <Link to="/agreement-listing" className="btn bg-gradient-warning">Back to Transaction</Link>
                                </div>
                            </div>
                        </div>
                    </div>SS
                </div>
            </UserAuthLayout>
        </>
    )
}

export default TransactionCancel