import React from 'react'
import WalletConnect from '../../Components/WalletConnect'
import SwappingCard from './SwappingCard'

const Swapping = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-5">
                            <WalletConnect />
                        </div>
                    </div>
                    <SwappingCard 
                        show={true}
                    />
                </div>
            </section>
        </>
    )
}

export default Swapping