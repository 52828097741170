import { checkDataIsValid } from "./utils/secure";

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const getShortAddressStr = (address = "") => {
  address = typeof address === "string" ? address : "";
  
  return address === "" ? "" : address.slice(0, 5) + '...' + address.slice(-5);
};

export const getShortAddress = (address = "") => {
  var _address = getShortAddressStr(address);

  if(_address === "") {
    return "";
  }

  return (<div class="d-flex">
    <span style={{marginRight: "3px"}}>{_address}</span>
    <i class="fa fa-copy cursor-pointer" onClick={() => {
      navigator.clipboard.writeText(address);
    }}></i>
  </div>);
}