import React from 'react'
import { GiCancel } from "react-icons/gi";
import { GiPencil } from "react-icons/gi";
import useConfirm from '../../../utils/useConfirm';

const CountryTr = (props) => {

    const { country, deleteData, editCountry } = props;

    const deleteSubmit = (closeDialog) => {
        deleteData(country.id);
        closeDialog();
    }

    const { showDialog } = useConfirm(deleteSubmit, 'Country');

    return (
        <>
            <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-0 border-radius-lg">
                <div className="d-flex align-items-center">
                    <div className="d-flex px-2 py-1 align-items-center">                                                                  
                        <div className="ms-4">
                            <p className="text-xs font-weight-bold mb-0">Country:{country?.country_name}</p>
                            <p className="text-sm mb-0">Country Code:{country?.country_code}</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <button onClick={() => editCountry(country)} className="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove Country" data-container="body" data-animation="true"><GiPencil size={20} /></button>
                    <button onClick={showDialog} className="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove Country" data-container="body" data-animation="true"><GiCancel size={20} /></button>
                </div>
            </li>
        </>
    )
}

export default CountryTr