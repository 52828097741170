import React from "react";
import AdminAuthLayout from "../../../Layout/AdminAuthLayout";
import LatestOrderList from "../../Escrow/LatestEscrowList"

const HvamOrderList = (props) => {
    const { escrows } = props; 

  return (
    <>
        <AdminAuthLayout>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12">
                        <h3>HVAM Transaction Details</h3>
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                        <div className="card">
                            <div className="card-body p-3">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h6 class="text-sm ms-0">Transation No</h6>
                                    <p class="badge bg-gradient-warning py-2">622301</p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="text-sm ms-0 mt-2">No. Of HVAM</label>
                                    <p class="mb-1 text-dark text-sm">10</p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="text-sm ms-0 mt-2">Price Of HVAM</label>
                                    <p class="mb-1 text-dark text-sm">120</p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="text-sm ms-0 mt-2">Total Price</label>
                                    <p class="mb-1 text-dark text-sm text-uppercase">12546 DOLLOR</p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="text-sm ms-0 mt-2">Transfer HVAM To Addres</label>
                                    <span class="badge bg-gradient-info">gifb9otodfoifo8ro</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminAuthLayout>
    </>
  );
};

export default HvamOrderList;
