import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getEncryptId } from '../../../utils/secure';

const FiatListTableTr = (props) => {

    const { order } = props;

    return (
        <>
            <tr>
                <td>
                    <div className="d-flex px-2 py-1">
                        <div className="d-flex flex-column justify-content-center">
                            <Link to={`/view-order/${getEncryptId(order?.id)}`} className="text-xs text-primary font-weight-bold mb-0">{order?.orderNo}</Link>
                        </div>
                    </div>
                </td>
                <td>
                    <span className="text-secondary text-xs font-weight-bold">{order?.noOfHvam}</span>
                </td>
                <td>
                    <span className="text-secondary text-xs font-weight-bold">{order?.priceOfHvam}</span>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{order?.totalPrice}</p>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{order?.currency}</p>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{order?.transactionId}</p>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{order?.hashId}</p>
                </td>
                <td className="text-center text-secondary mb-0">
                    <p className="text-xs text-secondary mb-0">{order?.hvamAddress}</p>
                </td>
                <td className="text-center text-secondary mb-0">
                    <p className="text-xs text-secondary mb-0">{moment(order?.tranDate).format('DD MMM YYYY')}</p>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{order?.tranStatus}</p>
                </td>
            </tr>
        </>
    )
}

export default FiatListTableTr