import React from 'react'

const StatusDealDone = (props) => {
    const { order } = props;

	return (
		<>
			<div className="card mb-4 mb-md-5">
				<div className="card-header bg-light rounded-0 pb-0 px-3">
					<h5 className="mb-2">Status</h5>
					<p className="mb-4 text-dark fw-bold"><span className="badge badge-sm bg-gradient-dark me-3">Deal Closed</span>Deal Completed Succsesfully</p>
				</div>
				<div className="card-body py-4 p-3">
					<p className="mb-3">{order?.paymentFor} Deliverd to the the Buyer and paymet transferd to supplier.</p>
					<div className="row">
						<div className="col-12 col-md-6">
							<button type="button" className="btn bg-gradient-dark w-100 mx-1">Deal Closed</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default StatusDealDone