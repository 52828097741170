import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserAuthLayout from "../../../Layout/UserAuthLayout";
import { AuthUserApi, REACT_APP_IMAGE_URL } from "../../../utils/api";
import Hedding from ".././../../Components/Hedding";
import PaymentSummaryBuyer from "./PaymentSummaryBuyer";
import PaymentSummarySupplier from "./PaymentSummarySupplier";
import { UserContext } from "../../../Context/UserContext";
import { escrowAddress, escrowABI, TOKENS, expoURL } from "./../../../Contract";
import ImgUsdt from "../../../assets/img/usdt.png";
import ImgBnb from "../../../assets/img/bnb.png";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import useForm from "../../../useForm";
import { Form } from "react-bootstrap";
import validateDispatch from "../../../validate/validateDispatch";
import { getShortAddress } from "../../../utils";
import { useSigner, useProvider, useAccount } from "wagmi";

const PaymentSummary = (props) => {
    const library = useProvider({
        chainId: 56,
    });
    const { data: signer } = useSigner();
    const { isConnected: active, address: account } = useAccount();

    const toTokens = [
        { id: 1, tag: "USDT", img: ImgUsdt },
        // { id: 2, tag: "BNB", img: ImgBnb },
        // { id: 3, tag: "BTCB", img: ImgBnb },
        // { id: 4, tag: "ETH", img: ImgBnb },
        { id: 5, tag: "BUSD", img: ImgBnb },
    ];

    const fromTokens = [{ id: "HVAM", tag: "HVAM", img: ImgBnb }];
    const [order, setOrder] = useState({});
    const { id } = useParams();
    const [StableToken, SetStableToken] = useState(toTokens[0].tag || "BUSD");
    const [Days, SetDays] = useState(0);
    const { user } = useContext(UserContext);
    const { role } = user?.user || {};
    const [Payed, SetPayed] = useState(false);
    const [transStatus, setTransStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [supplierAddress, setSupplierAddress] = useState("");
    const [buyerAddress, setBuyerAddress] = useState("");
    const [contract, setContract] = useState(null);
    const [toContract, setToContract] = useState(null);
    const [selectedFromToken, setSelectedFromToken] = useState(fromTokens[0]);
    const [fromContract, setFromContract] = useState(null);
    const navigate = useNavigate();

    const getContract = (address, ABI) => {
        const contract = new ethers.Contract(address, ABI, library);

        return contract.connect(signer);
    };

    useEffect(() => {
        if (library && signer) {
        setContract(getContract(escrowAddress, escrowABI));
        setFromContract(
            getContract(
            TOKENS[selectedFromToken.tag || "HVAM"].ADDRESS,
            TOKENS[selectedFromToken.tag || "HVAM"].ABI
            )
        );
        if (StableToken !== "BNB") {
            setToContract(
            getContract(TOKENS[StableToken].ADDRESS, TOKENS[StableToken].ABI)
            );
        }
        } else {
        setContract(null);
        }
    }, [library, account, signer]);

    useEffect(() => {
        if (library && signer) {
        setToContract(
            getContract(TOKENS[StableToken].ADDRESS, TOKENS[StableToken].ABI)
        );
        }
    }, [StableToken, library, signer]);

    const getData = async () => {
        try {
        const listResponse = await AuthUserApi.get(`/user/transaction-get/${id}`);
        const { status, data } = listResponse;
        if (status === 200 && data.escrow !== undefined) {
            const { escrow } = data;
            setOrder(escrow);
            setBuyerAddress(escrow.myWAddress);
            setSupplierAddress(escrow.userWAddress);
            const days = Math.ceil(
            (new Date(data.escrow.endDate.split("T")[0]).getTime() -
                new Date(data.escrow.startDate.split("T")[0]).getTime()) /
                (1000 * 3600 * 24)
            );
            if (days === 0) {
            SetDays(1);
            } else {
            SetDays(days);
            }
            //SetPayed(Boolean(data.escrow.checkescrow));
        }
        } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const dataxd = async () => {
        if (active === true && Payed === false) {
        try {
            setLoading(true);
            if (StableToken !== "BNB") {
            const allowance = await toContract.allowance(account, escrowAddress);
            const fromAllowance = await fromContract.allowance(
                account,
                escrowAddress
            );

            setTransStatus(`checking ${StableToken} allowance`);
            if (Number(allowance.toString()) < order.invoiceAmt) {
                setTransStatus(`Setting ${StableToken} Allowance...`);
                const atx = await toContract.approve(
                escrowAddress,
                ethers.utils
                    .parseUnits("1000000", TOKENS[StableToken].DECIMALS)
                    .toString()
                );
                setTransStatus(`Processig ${StableToken} Allowance...`);
                await atx.wait();
                setTransStatus(`Allowance ${StableToken} Processed...`);
            }

            if (Number(fromAllowance.toString()) < order.invoiceAmt) {
                setTransStatus(`Setting ${selectedFromToken.tag} Allowance...`);
                const aftx = await fromContract.approve(
                escrowAddress,
                ethers.utils
                    .parseUnits("1000000", TOKENS[selectedFromToken.tag].DECIMALS)
                    .toString()
                );
                setTransStatus(`Processig ${selectedFromToken.tag} Allowance...`);
                await aftx.wait();
                setTransStatus(`Allowance ${selectedFromToken.tag} Processed...`);
            }
            }

            setTransStatus("Confirm Transaction on Wallet");

            const tx = await contract.placeOrder(
            Number(order.escrowNo),
            TOKENS[StableToken].ADDRESS,
            buyerAddress,
            supplierAddress,
            ethers.utils
                .parseUnits(
                order.invoiceAmt,
                TOKENS[selectedFromToken.tag].DECIMALS
                )
                .toString(),
            Number(Days),
            ethers.utils
                .parseUnits(
                order.additionalAmt,
                TOKENS[selectedFromToken.tag].DECIMALS
                )
                .toString()
            );

            setTransStatus("Waiting For Confirmation");
            await tx.wait();

            let saveResponse = await AuthUserApi.put(
            `/user/transaction-update/${id}`,
            {
                hashId: tx.hash,
                escrowStatus: "Credited",
                paymentStatus: "Credited",
            }
            );
            const { status, data } = saveResponse;

            if (status === 201 && data.escrow !== undefined) {
            toast.success("Awaiting delivery Confirmation..");
            window.location.reload();
            }
            setTransStatus(
            `Transaction Completed. <a target="_blank" href="${expoURL}/tx/${
                tx.hash || ""
            }">view</a>`
            );
            setLoading(false);
        } catch (err) {
            setTransStatus(
            err?.error?.data?.message || err?.error?.message || "Transaction Reverted"
            );
            setLoading(false);
        }
        }
    };

    const submit = async () => {
        try {
        const formData = new FormData();
        for (let [key] of Object.entries(values)) {
            formData.append(key, values[key]);
        }
        var saveResponse = await AuthUserApi.put(
            `/user/transaction-update/${id}`,
            formData
        );
        const { status, data } = saveResponse;

        if (status === 201 && data.escrow !== undefined) {
            setValues({ ...formInputObj });
            toast.success("Dispatch Details Updated Successfully..");
            window.location.reload();
        }
        } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
        const { response } = e;
        if (
            response !== undefined &&
            Object.keys(response.data).length &&
            response.data.error !== undefined
        ) {
            setErrors(response.data.error); // if error from server side
        }
        }
    };

    const handleFileChange = (event) => {
        const { files, name } = event.target;
        let newSelectedFile = "";
        if (files && files.length) {
        const selectedFile = files[0];
        newSelectedFile = selectedFile;
        }
        setValues({
        ...values,
        [name]: newSelectedFile,
        });
    };

    const formInputObj = {
        escrowStatus: "Dispatched",
        dispatchDate: "",
        location: "",
        docket_no: "",
        fileUrl: "",
    };

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } =
        useForm(submit, validateDispatch, formInputObj);

    return (
        <>
            <UserAuthLayout>
                <Hedding Hedding="Transaction Summary" />
                <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                    <div className="card mb-4 mb-md-5">
                        <div className="card-header bg-light rounded-0 pb-0 px-3">
                        <h5 className="mb-4">Escrow Details</h5>
                        </div>
                        <div className="card-body pt-3 p-3">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-sm ms-0">Escrow Id</h6>
                                <p className="badge bg-gradient-warning py-2">
                                {order?.escrowNo}
                                </p>
                            </div>
                            </div>
                            <hr className="horizontal dark"></hr>
                            <div className="col-md-6 mb-3 mb-md-0">
                            <div className="card bg-gray-100 border-radius-lg shadow-none h-100 p-3">
                                <label className="text-sm ms-0">Wallet Address</label>
                                <span className="badge bg-gradient-info">
                                {order?.myRole == "Buyer"
                                    ? getShortAddress(order?.myWAddress)
                                    : getShortAddress(order?.userWAddress)}
                                </span>
                                <label className="text-sm ms-0 mt-2 d-block">
                                FROM - {order?.myRole}
                                </label>
                                <p className="mb-2 text-dark text-sm">
                                {order?.myName}
                                </p>
                                <p className="mb-2 text-dark text-sm">
                                {order?.myEmail}
                                </p>
                                <p className="mb-2 text-dark text-sm">
                                {order?.mycountry?.country_name}
                                </p>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="card bg-gray-100 border-radius-lg shadow-none h-100 p-3">
                                <label className="text-sm ms-0">Wallet Address</label>
                                <span className="badge bg-gradient-info">
                                {order?.userRole == "Supplier"
                                    ? getShortAddress(order?.userWAddress)
                                    : getShortAddress(order?.myWAddress)}
                                </span>
                                <label className="text-sm ms-0 mt-2 d-block">
                                To - {order?.userRole}
                                </label>
                                <p className="mb-2 text-dark text-sm">
                                {order?.userContactName}
                                </p>
                                <p className="mb-2 text-dark text-sm">
                                {order?.userEmail}
                                </p>
                                <p className="mb-2 text-dark text-sm">
                                {order?.country?.country_name}
                                </p>
                            </div>
                            </div>
                            <hr className="horizontal dark mt-3"></hr>
                            <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <label className="text-sm ms-0 mt-2">Payment For</label>
                                <p className="mb-1 text-dark text-sm">
                                {order?.paymentFor}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <label className="text-sm ms-0">
                                Deal Amount in USD
                                </label>
                                <h5 className="mb-2">{order?.invoiceAmt}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                <label className="text-sm ms-0 mt-2 mb-0">
                                    Escrow Charges In USD
                                </label>
                                <p className="mb-2 text-dark text-sm">
                                    (Pay In HVAM)
                                </p>
                                </div>
                                <div className="text-end">
                                <h6 className="mb-2 text-dark text-lg">
                                    {order?.additionalAmt}
                                </h6>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <label className="text-sm ms-0 mt-2">
                                Total Amount
                                </label>
                                <h6 className="mb-1 text-dark text-sm">
                                {parseFloat(order?.invoiceAmt) +
                                    parseFloat(order?.additionalAmt)}
                                </h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <label className="text-sm ms-0 mt-2">Deal Day's</label>
                                {/* <h6 className="mb-1 text-dark text-sm">{ days } days</h6> */}
                            </div>
                            <div>
                                <label className="text-sm ms-0 mt-2">
                                Transaction Description
                                </label>
                                <p className="mb-1 text-dark text-sm">
                                {order?.description}
                                </p>
                            </div>
                            <div>
                                <label className="text-sm ms-0 mt-2">
                                Additional Documents
                                </label>
                                <div>
                                {order?.escrow_documents &&
                                order?.escrow_documents.length > 0
                                    ? order?.escrow_documents.map((document, k) =>
                                        document.documentUrl ? (
                                        <a
                                            href={
                                            document.documentUrl
                                                ? REACT_APP_IMAGE_URL +
                                                "/" +
                                                document.documentUrl
                                                : ""
                                            }
                                            target="blank"
                                            className="my-4"
                                        >
                                            <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 16 16"
                                            className="me-1"
                                            height="20"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"></path>
                                            </svg>{" "}
                                            {document?.documentName}
                                        </a>
                                        ) : (
                                        <span>{document?.documentName}</span>
                                        )
                                    )
                                    : "Document not found"}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <Form role="form" onSubmit={handleSubmit}>
                        {order?.escrowStatus == "Credited" && (
                        <div className="card mb-4 mb-md-5">
                            {role == "Supplier" && (
                            <>
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                <h5 className="mb-2">Add Dispatch Details.</h5>
                                </div>
                                <div className="card-body py-4 p-3">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                    <input
                                        type="date"
                                        name="dispatchDate"
                                        onChange={handleChange}
                                        value={values.dispatchDate}
                                        className="form-control form-control-lg"
                                        placeholder="Date"
                                        aria-label="Date"
                                    />
                                    {errors.dispatchDate && (
                                        <small className="text-danger">
                                        {errors.dispatchDate}
                                        </small>
                                    )}
                                    </div>
                                    <div className="col-12 mb-3">
                                    <input
                                        type="text"
                                        name="location"
                                        onChange={handleChange}
                                        value={values.location}
                                        className="form-control form-control-lg"
                                        placeholder="Location"
                                        aria-label="Location"
                                    />
                                    {errors.location && (
                                        <small className="text-danger">
                                        {errors.location}
                                        </small>
                                    )}
                                    </div>
                                    <div className="col-12 mb-3">
                                    <input
                                        type="text"
                                        name="docket_no"
                                        onChange={handleChange}
                                        value={values.docket_no}
                                        className="form-control form-control-lg"
                                        placeholder="Docet No."
                                        aria-label="Docet No."
                                    />
                                    {errors.docket_no && (
                                        <small className="text-danger">
                                        {errors.docket_no}
                                        </small>
                                    )}
                                    </div>
                                    <div className="col-12 mb-3">
                                    <input
                                        type="file"
                                        name="fileUrl"
                                        onChange={handleFileChange}
                                        className="form-control form-control-lg"
                                    />
                                    </div>
                                    <div className="col-12">
                                    <button
                                        type="submit"
                                        className="btn bg-gradient-success w-100 mx-1"
                                    >
                                        Dispatch
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </>
                            )}
                        </div>
                        )}
                    </Form>
                    </div>
                    <div className="col-12 col-md-6">
                    {role == "Buyer" ? (
                        <PaymentSummaryBuyer
                        order={order}
                        id={id}
                        SetStableToken={SetStableToken}
                        dataxd={dataxd}
                        days={Days}
                        transStatus={transStatus}
                        toTokens={toTokens}
                        loading={loading}
                        />
                    ) : (
                        <PaymentSummarySupplier order={order} role={role} id={id} />
                    )}
                    </div>
                </div>
                </div>
            </UserAuthLayout>
        </>
    );
};

export default PaymentSummary;
