import React from 'react';
import { Link } from "react-router-dom";
import UserAuthLayout from '../../../Layout/UserAuthLayout';
import CancelImage from "../../../assets/img/rejectAgreement.svg";

const PaymentCancel = () => {

    return (
        <>
            <UserAuthLayout>
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center mt-5">
                        <div className="col-12 col-md-9">
                            <div className="card mb-4 text-center py-4">
                                <div className="card-body pt-3 p-3">
                                    <img src={CancelImage} alt="" className="mb-3" />
                                    <h4 className="mb-3">Your transaction is cancelled.</h4>
                                </div>
                                <div className="card-footer pb-0 px-3">
                                    <Link to="/payment-gateway" className="btn btn-outline-default me-4">Create Another Order</Link>
                                    <Link to="/buy-fiat-money" className="btn bg-gradient-warning">Back to Orders</Link>
                                </div>
                            </div>
                        </div>
                    </div>SS
                </div>
            </UserAuthLayout>
        </>
    )
}

export default PaymentCancel