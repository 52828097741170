import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap"
import NotifyStatus from '../../../../Components/NotifyStatus';

const StatusInProgress = (props) => {
    const { order, cancelRequest, role_id } = props;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onCancelRequest = async () => {
        cancelRequest();
        handleClose();
    }

  return (
    <>
        <div className="card mb-4 mb-md-5">
            <div className="card-header bg-light rounded-0 pb-0 px-3">
                <h5 className="mb-2">Status</h5>
                <p className="mb-4 text-info fw-bold"><span className="badge badge-sm bg-gradient-info me-3">{ order?.escrowStatus }</span>Waiting For Acceptance</p>
            </div>
            <div className="card-body py-4 p-3">
                <p className="mb-4">New Escrow Request created by { order?.myRole } and waiting for { order?.userRole } acceptance.</p>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <button type="button" className="btn bg-gradient-danger w-100 mx-1" onClick={handleShow}>Cancel Request</button>
                    </div>
                </div>
            </div>
        </div>    
        
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title className="mx-auto">Your attention is required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NotifyStatus Status="Are you sure you want to cancel the request?" />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    {/* Deal Cancel msg btn */}
                    <Button variant="danger" onClick={onCancelRequest}>
                        Confirm Cancellation
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Back To Summury
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    
    </>
  )
}

export default StatusInProgress