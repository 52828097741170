import React from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import { getEncryptId } from "../../../utils/secure";

const TransactionTableTr = (props) => {

    const { order } = props;

    return (
        <>
            <tr>
                <td>
                    <div className="d-flex px-2 py-1">
                        <div className="d-flex flex-column justify-content-center">
                            <Link to={`/order-detail/${getEncryptId(order?.id)}`} className="text-xs text-primary font-weight-bold mb-0">{order?.escrowNo}</Link>
                        </div>
                    </div>
                </td>
                <td>
                    <span className="text-secondary text-xs font-weight-bold">{ moment(order?.createdAt).format('DD MMM YYYY') }</span>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{order?.myEmail}</p>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{order?.userEmail}</p>
                </td>
                <td className="align-middle text-center text-sm">
                    <span className={`text-xs mb-0 ${order?.escrowStatus} ${order?.escrowStatus.replace(' ', '-')}`}>{order?.escrowStatus}</span>
                </td>
                <td className="align-middle text-center">
                    <span className="text-secondary text-xs font-weight-bold">{ parseFloat(order?.invoiceAmt) +  parseFloat(order?.additionalAmt)}</span>
                </td>
            </tr>
        </>
    );
};

export default TransactionTableTr;
