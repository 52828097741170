import React from 'react';
import FiatListTableTr from "./FiatListTableTr";

const FiatListTable = (props) => {

    const { orders } = props;

    return (
        <>
            <div className="table-responsive">
                <div className="card-body pt-2 p-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>   
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                    Order No
                                </th>                                
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                    No. Of HVAM
                                </th>
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                    Price Of HVAM
                                </th>
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                    Total Price
                                </th>
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                    Currency
                                </th>
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                                    Transaction ID 
                                </th>
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                                    Hash ID 
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                    Wallet address
                                </th>
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                    DATE
                                </th>
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders && orders.length > 0 && orders.map((order, indx) => {
                                    return(
                                        <FiatListTableTr
                                            order={order}
                                            key={indx}
                                        />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default FiatListTable