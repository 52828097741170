import React from "react";
import {  Form } from "react-bootstrap";

const UserForm = (props) => {

    const { handleSubmit, handleChange, values, errors, roles, handleChecked } = props;

    console.log('values',values);

    return (
        <>
            <Form onSubmit={handleSubmit} noValidate>
                <div className="card-body">
                    <p className="text-uppercase text-sm">Add User Details</p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label
                                    for="example-text-input"
                                    className="form-control-label"
                                >
                                    Name
                                </label>
                                <input onChange={handleChange} name="name" className="form-control" type="text" value={values.name} />
                                {errors.name && <small className="text-danger">{errors.name}</small>}
                        </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label
                                    for="example-text-input"
                                    className="form-control-label"
                                >
                                    Mobile Number
                                </label>
                                <input onChange={handleChange} name="mobile_no" className="form-control" type="text" value={values.mobile_no} />
                                {errors.mobile_no && <small className="text-danger">{errors.mobile_no}</small>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label
                                    for="example-text-input"
                                    className="form-control-label"
                                >
                                    Email Address
                                </label>
                                <input onChange={handleChange} name="email" className="form-control" type="email" value={values.email} />
                                {errors.email && <small className="text-danger">{errors.email}</small>}                        
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label for="role_id">Role</label>
                                <select className="form-control" id="role_id" name="role_id" value={values.role_id} onChange={handleChange}>
                                    <option value="">Select Role</option>
                                    {  
                                        roles && roles.map((role, i) =>
                                            <option key={i} value={role.id}>{role.role}</option>
                                        )
                                    }
                                </select>
                                {errors.role_id && <small className="text-danger">{errors.role_id}</small>} 
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label
                                    for="example-text-input"
                                    className="form-control-label"
                                >
                                    Password
                                </label>
                                <input onChange={handleChange} name="password" className="form-control" type="password" value={values.password} />
                                {errors.password && <small className="text-danger">{errors.password}</small>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label
                                    for="example-text-input"
                                    className="form-control-label"
                                >
                                    Confirm Password
                                </label>
                                <input onChange={handleChange} name="confirm_password" className="form-control" type="password" value={values.confirm_password} />
                                {errors.confirm_password && <small className="text-danger">{errors.confirm_password}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-check form-check-info text-start">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="is_active"
                                    value={values?.is_active}
                                    id="flexCheckDefault"
                                    checked={values?.is_active}
                                    onChange={handleChecked}
                                />
                                <label className="form-check-label" for="flexCheckDefault">
                                    Is active?{" "}
                                </label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 text-end">
                            <button
                                type="submit"
                                className="btn bg-gradient-primary me-3 mb-0"
                            >
                                Save
                            </button>
                            <button type="button" className="btn bg-gradient-danger mb-0">
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default UserForm;
