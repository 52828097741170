import axios from 'axios';
import { toast } from 'react-toastify';

const { REACT_APP_API_ENDPOINT, REACT_APP_IMAGE_URL } = process.env;

const GuestApi = axios.create({
    baseURL: REACT_APP_API_ENDPOINT
});

let accessToken = null;
if(localStorage.getItem('admin_data') != null){
    const adminLocalStorage = JSON.parse(localStorage.getItem('admin_data'));
    const { access_token } = adminLocalStorage && adminLocalStorage?.token;
    accessToken = access_token;
}

const AuthApi = axios.create({
    baseURL: REACT_APP_API_ENDPOINT,
    headers: {
        Authorization: `bearer ${accessToken}`
    }
});

let accessUserToken = null;
if (localStorage.getItem('user_data') != null) {
    const userLocalStorage = JSON.parse(localStorage.getItem('user_data'));
    const { access_token } = userLocalStorage?.token;
    accessUserToken = access_token;
}

const AuthUserApi = axios.create({
    baseURL: REACT_APP_API_ENDPOINT,
    headers: {
        Authorization: `bearer ${accessUserToken}`
    }
});

let count = 0;

AuthApi.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null
    if (status === 401) {
        count = 1;
        if( count = 0) toast.warning("Session expired.. Login to continue.");
    }

    return Promise.reject(error);
});

let intialCount = 0;

AuthUserApi.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null
    if (status === 401) {
        intialCount = 1;
        if(intialCount = 0) toast.warning("Session expired.. Login to continue.");
    }
    return Promise.reject(error);
});

export { GuestApi, AuthApi, REACT_APP_API_ENDPOINT, REACT_APP_IMAGE_URL, AuthUserApi };
