import React from 'react'

const ValueUpdateCard = (props) => {
  return (
    <>
        <div className="card h-100">
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-8">
                        <div className="numbers">
                            <h5 className="font-weight-bolder">{props.Count}</h5>
                        </div>
                    </div>
                    <div className="col-4 text-end">
                        <div className={`icon icon-shape shadow-warning text-center rounded-circle ms-auto ${ props.Bgcolor }`}>
                            <i className={`text-lg opacity-10 ${props.Icon}`} aria-hidden="true"></i>
                        </div>
                    </div>
                    <div className="col-12">
                        <p className="text-sm mb-0 text-uppercase font-weight-bold">
                            {props.Title}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ValueUpdateCard