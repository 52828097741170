export default function validateOrder(values) {
    let errors = {};
    if (!values.noOfHvam) {
    	errors.noOfHvam = "No of HVAM is required";
    } 
    if (!values.priceOfHvam) {
    	errors.priceOfHvam = "Price of HVAM is required";
    }
    if (!values.currency) {
    	errors.currency = "Currency is required";
    }

    if (!values.totalPrice) {
    	errors.totalPrice = "Total price is required";
    } 
    if (!values.hvamAddress) {
    	errors.hvamAddress = "Transfer HVAM To Addres is required";
    }
    if (!values.tranDate) {
    	errors.tranDate = "Transaction date is required";
    }
    
    return errors;
}