import React from 'react'
import UserAuthLayout from '../../Layout/UserAuthLayout'
import SwappingCard from '../Swapping/SwappingCard'

const Swapp = () => {
  return (
    <>
        <UserAuthLayout>
            <section>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            <SwappingCard
                                show={false}
                            />
                        </div>
                    </div>
                </div>
            </section> 
        </UserAuthLayout>
    </>
  )
}

export default Swapp