import React, { useEffect, useState } from "react";
import { Modal, Button, Image } from "react-bootstrap";
import {
  useConnect,
  useAccount,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { getShortAddressStr } from "./../utils";

const WalletConnect = ({ className = "", variant = "" }, props) => {
  const [show, setShow] = useState(false);
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { connect, connectors } = useConnect();

  const handleWalletConnect = (event) => {
    setShow(true);
  };

  useEffect(() => {
    if (
      typeof switchNetwork === "function" &&
      typeof chain === "object" &&
      chain.id !== 56
    ) {
      switchNetwork?.(chains[0]?.id);
    }
  }, [chain]);

  useEffect(() => {
    if(isConnected) {
      const reloadCount = Number(localStorage.getItem("reloadCount"));

      if (reloadCount < 1) {
        localStorage.setItem("reloadCount", String(reloadCount + 1));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
  }, [isConnected]);

  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };

  return (
    <>
      {isConnected ? (
        <button
          onClick={() => {
            disconnect();
            localStorage.removeItem("reloadCount");
            setProvider("");
          }}
          type="button"
          className={`${
            className ? className : "btn mb-0 me-0 me-md-3"
          } btn-info`}
        >
          {`Disconnect ${getShortAddressStr(address)}`}
        </button>
      ) : (
        <button
        onClick={handleWalletConnect}
        type="button"
        className={` ${variant ? variant : "bg-gradient-primary"} ${
            className
            ? className
            : "btn bg-gradient-primary mb-0 me-0 me-md-3 px-3 px-md-4"
        }`}
        >
        Wallet Connect
        </button>
      )}

      <Modal size="sm" show={show}>
            <Modal.Header>Select Wallet</Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column align-items-left">
                    {connectors.map((connector) => (
                        <Button
                            variant="outline"
                            className="border-0"
                            disabled={!connector.ready}
                            onClick={() => {
                            connect({ connector });
                            setShow(false);
                            }}
                            w="100%"
                        >
                            <Image
                            fluid
                            src={`/${connector.name}.png`}
                            height={26}
                            width={26}
                            />
                            <br />
                            <span>{connector.name}</span>
                        </Button>
                    ))}
                </div>
            </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletConnect;
