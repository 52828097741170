var CryptoJS = require("crypto-js");

export const getEncryptId = (id = 0) => {
    // let newId = id.toString();
    // var ciphertext = CryptoJS.AES.encrypt(newId, 'escrowkey');
    return id;
}

export const getDecryptId = (id = 0) => {
    // var bytes  = CryptoJS.AES.decrypt(id, 'escrowkey');
    // var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    // console.log("decrypted text", plaintext);
    return id;
}

export const checkDataIsValid = (data) => {
    if(data !== null && data !== undefined && data !== '' && data !== 0){
        return true;
    }
    return false;
}
