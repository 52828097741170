import React from 'react'
import { useEffect } from 'react';
import { Form } from "react-bootstrap";
import useForm from '../../../useForm';
import { AuthUserApi } from '../../../utils/api';
import validate from '../../../validate/validateOrder';
import moment from 'moment';
import { useState } from 'react';
import { currencies } from '../../../utils/constant';
import { getEncryptId } from '../../../utils/secure';
import { useNavigate } from 'react-router-dom';

const PaymentGatewayForm = (props) => {

    const { order, type } = props;
    const [checked, setChecked] = useState(true);
    const [readOnly, setReadOnly] = useState(type === 'view' ? true : false);
    const [loadnig, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleChecked = (event) => {
        setChecked(event.target.checked);
    };

    const formInputObj = {
        noOfHvam: 0,
        priceOfHvam: 0,
        currency: "USD",
        totalPrice: 0,
        hvamAddress: "",
        transactionId: "",
        hashId:"",
        tranStatus:"Pending",
        tranDate: moment().format('YYYY-MM-DD'),
    };

    const submit = async () => {
        try {
            setLoading(true);
            let saveResponse = await AuthUserApi.post('/user/order-create', values);
            const { status, data } = saveResponse;
            if (status === 201 && data.order !== undefined) {
                setLoading(false);
                navigate(`/view-order/${getEncryptId(data.order.id)}`);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } = useForm(submit, validate, formInputObj);

    useEffect(() => {
        if (order && Object.keys(order).length !== 0) {
            for (let [key] of Object.entries(formInputObj)) {
                formInputObj[key] = order[key];
            }
            setValues({ ...formInputObj });
        }
    }, [order]);

    useEffect(() => {
        var validNumber = new RegExp(/^\d*\.?\d*$/);
        if (validNumber.test(values.noOfHvam) && values.currency) {
            let totPrice = parseFloat(values.noOfHvam) * parseFloat(values.priceOfHvam);
            setValues({ ...values, totalPrice: totPrice.toFixed(2) });
        }
    }, [values.noOfHvam, values.priceOfHvam]);

    useEffect(() => {
        if (values.currency) {
            let currency = currencies.find((currency) => currency.label === values.currency)
            if(currency !== undefined){
                setValues({ ...values, priceOfHvam: currency.val });
            }
        }
    }, [values.currency]);

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="col-12 d-flex justify-content-between">
                    <h6 className="text-uppercase mb-3">HVAM</h6>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md-3 mb-3">
                            <label className="text-capitalize">no. of HVAM</label>
                            <input readOnly={readOnly} type="text" onChange={handleChange} name="noOfHvam" value={values.noOfHvam} className="form-control" placeholder="0" aria-label="0" />
                            {errors.noOfHvam && <small className="text-danger">{errors.noOfHvam}</small>}
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label className="text-capitalize">price of HVAM</label>
                            <input readOnly={readOnly} type="text" onChange={handleChange} name="priceOfHvam" value={values.priceOfHvam} className="form-control" placeholder="0" aria-label="0" disabled />
                            {errors.priceOfHvam && <small className="text-danger">{errors.priceOfHvam}</small>}
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label for="userCountry">Select Currency</label>
                            <div className="form-group selectdropdon">
                                <select readOnly={readOnly} className="form-control" onChange={handleChange} name="currency" value={values.currency}>
                                    {  
                                        currencies && currencies.map((currency, i) =>
                                            <option key={i} value={currency.label}>{currency.label}</option>
                                        )
                                    }
                                </select>
                                {errors.currency && <small className="text-danger">{errors.currency}</small>}
                            </div>
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label className="text-capitalize">Total price</label>
                            <input readOnly={readOnly} type="text" name="totalPrice" value={values.totalPrice} onChange={handleChange} className="form-control text-bold text-dark" aria-label="0" disabled />
                            {errors.totalPrice && <small className="text-danger">{errors.totalPrice}</small>}
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <label className="text-capitalize">transfer HVAM to addres</label>
                            <input readOnly={readOnly} type="text" name="hvamAddress" value={values.hvamAddress} onChange={handleChange} className="form-control" placeholder="Enter Wallet Address" aria-label="0" />
                            {errors.hvamAddress && <small className="text-danger">{errors.hvamAddress}</small>}
                        </div>
                        { order?.id &&
                            <div className="col-12 col-md-6 mb-3">
                                <label className="text-capitalize">Transaction ID</label>
                                <input readOnly={readOnly} type="text" name="transactionId" value={values.transactionId} onChange={handleChange} className="form-control" placeholder="Enter Transaction ID" aria-label="0" />
                                {errors.transactionId && <small className="text-danger">{errors.transactionId}</small>}
                            </div>
                        }
                        <div className="col-12 col-md-3 mb-3">
                            <label className="text-capitalize" for="date">Current Date</label>
                            <input readOnly={readOnly} type="date" name="tranDate" value={moment(values.tranDate).format('YYYY-MM-DD')} onChange={handleChange} className="form-control" placeholder="0" aria-label="0" disabled/>
                            {errors.tranDate && <small className="text-danger">{errors.tranDate}</small>}
                        </div>
                        <div className="col-12 col-md-3 mt-4">
                            {
                                values.hashId && 
                                <a target="_blank" className="text-xs text-primary font-weight-bold mb-0" href={`https://bscscan.com/tx/${values.hashId}`}>Hash Id</a>
                            }
                        </div>
                        {/* show while adding data */}
                        {
                            type === 'add' &&
                            <div className="col-12 d-flex justify-content-between">
                                <div className="form-check mt-3">
                                    <input readOnly={readOnly} className="form-check-input" type="checkbox" checked={checked} onChange={handleChecked} id="fcustomCheck1"/>
                                    <label className="custom-control-label" for="customCheck1">Agree terms and conditions</label>
                                </div>
                                {
                                    !loadnig ? 
                                    <div className="text-end">
                                        <button type="submit" className={`btn bg-gradient-primary text-capitalize mb-0 me-3 ${checked ? '' : 'disabled'}`}>Buy HVAM</button>
                                    </div> :
                                    <div className="text-end">
                                        <button type="button" disabled className={`btn bg-gradient-primary text-capitalize mb-0 me-3 ${checked ? '' : 'disabled'}`}>Submit ......</button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Form>
        </>
    )
}

export default PaymentGatewayForm