import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminAuthLayout from "../../../../Layout/AdminAuthLayout";
import useForm from "../../../../useForm";
import { AuthApi } from "../../../../utils/api";
import validate from "../../../../validate/validateUser";
import UserForm from "./AdminForm";

const AddUser = () => {

    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();

    const handleChecked = (event) => {
        const { name, checked} = event.target;
        setValues({
            ...values,
            [name]: checked
        });
    };

    const getRole = async () => {
        try {
            const listResponse = await AuthApi.get("/admin/roles-ds");
            const { status, data } = listResponse;
            if (status === 200 && data.roles !== undefined) {
                setRoles(data.roles);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const submit = async () => {
        try {
            const saveResponse = await AuthApi.post("/admin/add-admin", values);
            const { status, data } = saveResponse;
            if (status === 201 && data.admin !== undefined) {
                setValues({ ...formInputObj });
                navigate("/users");
                toast.success(`User added successfully`);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            // setErrors
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    const formInputObj = {
        name: "",
        email: "",
        mobile_no: "",
        password: "",
        confirm_password: "",
        role_id: "",
        is_active: ""
    };

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } = useForm(submit, validate, formInputObj);

    useEffect(() => {
        getRole();
    }, [])

    return (
        <>
            <AdminAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header pb-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0">Add User</h5>
                                    </div>
                                </div>
                                <UserForm
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    handleChecked={handleChecked}
                                    values={values}
                                    errors={errors}
                                    roles={roles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </AdminAuthLayout>
        </>
    );
};

export default AddUser;
