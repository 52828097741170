import React from "react";
import { Link } from "react-router-dom";
import TextLogo from "../../src/assets/img/logo.png"

const GuestLayout = (props) => {
    const { children } = props;

    return (
        <>
            <div className="container position-sticky z-index-sticky top-0">
                <div className="row">
                    <div className="col-12"></div>
                </div>
            </div>
            <main className="main-content  mt-0">
                <section>
                    <div className="page-header min-vh-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                    <div className="card card-plain bg-white my-3">
                                        <div className="card-header pb-0 text-start">
                                            <br />
                                            <Link to="/">
                                            <img
                                                src={TextLogo}
                                                width="60%"
                                                alt="logo"
                                                />
                                            </Link>
                                            <br />
                                            <br />
                                            <h4 className="font-weight-bolder">{props.FormTitle}</h4>
                                            <p className="mb-0">{props.FormSubTitle}</p>
                                        </div>
                                        <div className="card-body pb-0">
                                            {children}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                                    <div className="position-relative bg-gradient-warning h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden logpages">
                                        <span className="mask bg-gradient-warning opacity-6"></span>
                                        <h4 className="mt-5 text-white font-weight-bolder position-relative">
                                            {props.Title}
                                        </h4>
                                        <p className="text-white position-relative">
                                            {props.Subtitle}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default GuestLayout;
