import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import UserAuthLayout from "../../../Layout/UserAuthLayout";
import { AuthUserApi } from '../../../utils/api';
import FiatListTable from "./FiatListTable";

const BuyHvamFiat = () => {

    const [orders, setOrders] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(1);

    const getData = async (pgNO) => {
        try {
        const listResponse = await AuthUserApi.get(`/user/orders?pageNo=${pgNO}`);
        const { status, data } = listResponse;
        if (status === 200 && data.orders !== undefined) {
            setOrders(data.orders);
            const { pageData: { per_page, total_record } } = data || {};
            setPerPage(per_page);
            setTotalRecords(total_record);
        }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const getClickedPageNo = (pageNumber) => {
        setCurrentPage(pageNumber);
        getData(pageNumber);
    }

    useEffect(() => {
        getData(currentPage)
    }, []);

    return (
        <UserAuthLayout>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div class="d-flex align-items-center">
                                    <h5 class="mb-0"></h5>
                                    <Link class="btn btn-primary btn-sm ms-auto" to="/payment-gateway">Buy HVAM</Link>
                                </div>
                            </div>
                            <FiatListTable 
                                orders={orders}
                            />
                        </div>
                        <div className="col-12 d-flex justify-content-center justify-content-md-end pt-3">
                            {
                                // totalRecords > 10 &&
                                <Pagination
                                    activePage={currentPage}
                                    prevPageText='prev'
                                    nextPageText='next'
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    pageRangeDisplayed={5}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={totalRecords}
                                    onChange={getClickedPageNo}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </UserAuthLayout>
    )
}

export default BuyHvamFiat