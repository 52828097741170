import React from "react";
import {  Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const RegisterForm = (props) => {

    const { handleSubmit, handleChange, countries, values, errors } = props;

    return (
        <>
            <Form role="form" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <input className="form-control" type="name" name="name" value={values.name} onChange={handleChange} placeholder="Full Name"/>
                    {errors.name && <small className="text-danger">{errors.name}</small>}
                </div>
                <div className="mb-3">
                    <input className="form-control" type="name" name="username" value={values.username} onChange={handleChange} placeholder="Username"/>
                    {errors.username && <small className="text-danger">{errors.username}</small>}
                </div>
                <div className="mb-3">
                    <input className="form-control" type="email" name="email" value={values.email} onChange={handleChange} placeholder="Email" />
                    {errors.email && <small className="text-danger">{errors.email}</small>}
                </div>
                <div className="mb-3">
                    <input className="form-control" type="text" name="mobile_no" value={values.mobile_no} onChange={handleChange} placeholder="Mobile Number" />
                    {errors.mobile_no && <small className="text-danger">{errors.mobile_no}</small>}
                </div>	
                <div className="mb-3">
                    <input className="form-control" type="password" name="password" value={values.password} onChange={handleChange} placeholder="Password" />
                    {errors.password && <small className="text-danger">{errors.password}</small>}
                </div>	
                <div className="mb-3">
                    <input className="form-control" type="password" name="confirm_password" value={values.confirm_password} onChange={handleChange} placeholder="Confirm Password" />
                    {errors.confirm_password && <small className="text-danger">{errors.confirm_password}</small>}
                </div>					
                <Form.Group className="mb-3">
                    <Form.Select className="mb-3" aria-label="Default select example" name="country_id" value={values.country_id} onChange={handleChange}>
                        <option>Select Country</option>
                        {
                            countries && countries.length && countries.map((country) => 
                                <option value={country.id}>{country.country_name}</option>
                            )
                        }
                    </Form.Select>
                    {errors.country_id && <small className="text-danger">{errors.country_id}</small>}
                </Form.Group>
                <Form.Group className="mb-2">
                    <div className="d-flex">
                        <Form.Check type="radio" label="Buyer" value="Buyer" name="role" onChange={handleChange} checked={values.role == "Buyer"} className="mb-0 me-3" />
                        <Form.Check type="radio" label="Supplier" value="Supplier" name="role" onChange={handleChange} checked={values.role == "Supplier"} className="mb-0" />
                    </div>
                    {errors.role && <small className="text-danger">{errors.role}</small>}
                </Form.Group>			
                <div className="form-check form-check-info text-start mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/>
                    <label className="form-check-label" for="flexCheckDefault">
                    I agree the <a href="javascript:;" className="text-warning font-weight-bolder">Terms and Conditions</a>
                    </label>
                </div>
                <div className="text-center">
                    <Button variant="warning" type="submit" size="lg" className="btn-lg w-100 mb-0">Sign up</Button>
                </div>
            </Form>
            <div className="card-footer text-center pt-0 px-lg-2 px-1">
                <p className="mt-4 text-sm mx-auto">
                    Allready have an account?
                    <Link to="/" className="text-warning text-gradient font-weight-bold"> Sign in</Link>
                </p>
            </div>
        </>
    );
};

export default RegisterForm;
