import React, { useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useParams } from "react-router-dom";
import AdminAuthLayout from "../../../Layout/AdminAuthLayout";
import { AuthApi, REACT_APP_IMAGE_URL } from "../../../utils/api";
import AdminTransctionStatus from "./TransactionStatus/AdminTransctionStatus";
import PdfDoc from '../../../../src/assets/escrow_test.pdf';
import { getShortAddress } from "../../../utils";

const ComplteTranscationDetails = () => {
    const [order, setOrder] = useState({});
    const [days, setDays] = useState(0);
    const [copied, setCopied] = useState(false);

    const { id } = useParams();

    const getData = async () => {
        try {
            const listResponse = await AuthApi.get(`/admin/transaction-get/${id}`);
            const { status, data } = listResponse;
            if (status === 200 && data.escrow !== undefined) {
                setOrder(data.escrow);
                const days = Math.ceil(((new Date((data.escrow.endDate).split("T")[0])).getTime() - (new Date((data.escrow.startDate).split("T")[0])).getTime()) / (1000 * 3600 * 24));
                setDays(days);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <AdminAuthLayout>
                <div className="container-fluid py-5">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <div className="card mb-4 mb-md-5">
                                <div className="card-body pt-4 p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="text-sm ms-0">Escrow Id</h6>
                                                <p className="badge bg-gradient-warning py-2">{order?.escrowNo}</p>
                                                {/* <p className="badge bg-gradient-info">{order?.escrowStatus}</p> */}
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label className="text-sm ms-0">Deal Amount in USD</label>
                                                <h5 className="mb-2">{ parseFloat(order?.invoiceAmt) + parseFloat(order?.additionalAmt) }</h5>
                                            </div>
                                        </div>
                                        <hr className="horizontal dark"></hr>
                                        <div className="col-md-6 mb-3 mb-md-0">
                                            <div className="card bg-gray-100 border-radius-lg shadow-none h-100 p-3">
                                                <label className="text-sm ms-0">Wallet Address</label>
                                                <span className="badge bg-gradient-info">{order?.myRole == 'Buyer' ?  getShortAddress(order?.myWAddress) : getShortAddress(order?.userWAddress)}</span>
                                                <label className="text-sm ms-0 mt-2 d-block">FROM - {order?.myRole}</label>
                                                <p className="mb-2 text-dark text-sm">{order?.myName}</p>
                                                <p className="mb-2 text-dark text-sm">{order?.myEmail}</p>
                                                <p className="mb-2 text-dark text-sm">{order?.mycountry?.country_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card bg-gray-100 border-radius-lg shadow-none h-100 p-3">
                                                <label className="text-sm ms-0">Wallet Address</label>
                                                <span className="badge bg-gradient-info">{order?.userRole == 'Supplier' ? getShortAddress(order?.userWAddress) : getShortAddress(order?.myWAddress)}</span>
                                                <label className="text-sm ms-0 mt-2 d-block">To - {order?.userRole}</label>
                                                <p className="mb-2 text-dark text-sm">{order?.userContactName}</p>
                                                <p className="mb-2 text-dark text-sm">{order?.userEmail}</p>
                                                <p className="mb-2 text-dark text-sm">{order?.country?.country_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                                                                                                                                                 
                            </div>
                            <div className="card mb-4 mb-md-4">
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                    <h4 className="mb-4">Escrow Details</h4>                                                                                                                                                                                            
                                </div>
                                <div className="card-body py-4 p-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0 mt-2">Payment For</label>
                                        <p className="mb-1 text-dark text-sm">{order?.paymentFor}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <label className="text-sm ms-0 mt-2 mb-0">Escrow Charges In USD</label>
                                            <p className="mb-2 text-dark text-sm">(Pay In HVAM)</p>
                                        </div>
                                        <div className="text-end">
                                            <h6 className="mb-2 text-dark text-lg">{ order?.additionalAmt }</h6>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0 mt-2">Total Amount</label>
                                        <h6 className="mb-1 text-dark text-sm">{ parseFloat(order?.invoiceAmt) + parseFloat(order?.additionalAmt) }</h6>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="text-sm ms-0 mt-2">Deal Day's</label>
                                        <h6 className="mb-1 text-dark text-sm">{ days } days</h6>
                                    </div>
                                    <div>
                                        <label className="text-sm ms-0 mt-2">Transaction Description</label>
                                        <p className="mb-1 text-dark text-sm">{order?.description}</p>
                                    </div>
                                    <div>
                                        <label className="text-sm ms-0 mt-2">Additional Documents</label>
                                        <div>
                                            {(order?.escrow_documents && order?.escrow_documents.length  > 0) ? order?.escrow_documents.map((document, k) =>
                                                <a href={document.documentUrl ? REACT_APP_IMAGE_URL + '/' + document.documentUrl : '' } target="blank" className="my-4">
                                                    <svg
                                                        stroke="currentColor"
                                                        fill="currentColor"
                                                        stroke-width="0"
                                                        viewBox="0 0 16 16"
                                                        className="me-1"
                                                        height="20"
                                                        width="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"></path>
                                                    </svg>{" "}
                                                    {document?.documentName}
                                                </a>
                                            ) : 'Document not found'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {   
                            order?.escrowStatus == 'Close' &&
                            <div className="card mb-4 mb-md-5">
                                <div className="card-header bg-light rounded-0 pb-0 px-3">
                                    <h5 className="mb-2">Status</h5>
                                    <p className="mb-4 text-info fw-bold"><span className="badge badge-sm bg-gradient-info me-3">{order?.escrowStatus}</span></p>
                                </div>
                                <div className="card-body py-4 p-3">
                                    <p className="mb-4">Deal Closed</p>
                                </div>
                            </div>
                        } */}
                        <div className="col-12 col-md-5">
                            <AdminTransctionStatus
                                order={order}
                            />                            
                        </div>
                    </div>
                </div>
            </AdminAuthLayout>
        </>
    );
};

export default ComplteTranscationDetails;
