import React, { useContext, useState } from 'react'

const StatusReleasePayment = (props) => {
    const { order, isConnected, tranfserPaymentToSeller, status, role_id } = props;

    const onTransferPaymentToSeller = async () => {
        await tranfserPaymentToSeller(Number(order.escrowNo));
    }

    return (
        <>
            <div className="card mb-4 mb-md-5">
                <div className="card-header bg-light rounded-0 pb-0 px-3">
                    <h5 className="mb-2">Status</h5>
                    <p className="mb-4 text-success fw-bold"><span className="badge badge-sm bg-gradient-success me-3">{order?.escrowStatus}</span>Received Product {status}</p>
                </div>
                <div className="card-body py-4 p-3">
                    <p className="mb-4">{order?.paymentFor} Deliverd to the the Buyer waiting for {order?.paymentFor} delivery status.</p>
                    <div className="row">
                        {
                            role_id == 1 &&
                            <div className="col-12 col-md-6">
                                <button disabled={!isConnected} onClick={() => onTransferPaymentToSeller()} type="button" className="btn bg-gradient-success w-100 mx-1">Payment Release</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default StatusReleasePayment