import React from "react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/HeaderAdmin";
import SidebarAdmin from "../Components/SidebarAdmin";
import { AdminContext } from "../Context/AdminContext";
import { GuestApi } from "../utils/api";

const AdminAuthLayout = (props) => { 
    const { children } = props;
    const { admin, setAdmin } = useContext(AdminContext);

    const logoutHandle = async () => {
        try {
            const { refreshToken } = admin;
            await GuestApi.post("/admin-logout", {refreshToken});
        } catch (e) {
            const { response } = e;
            console.log(`😱 Axios request failed: ${response}`);
        }
        // in any case user data must be removed from client side
        localStorage.removeItem('admin_data');
        setAdmin(null);
        window.localStorage.clear();
        window.location.reload();
    }
    
    if(admin == null) return <Navigate to='/admin-login' />;

    return (
        <>
            <div className="min-height-300 bg-warning position-absolute w-100"></div>
            <SidebarAdmin logoutHandle={logoutHandle}/>
            <main className="main-content position-relative border-radius-lg ">
                <Header logoutHandle={logoutHandle}/>
                {children}
                <Footer/>
            </main>
        </>
    );
};

export default AdminAuthLayout;
