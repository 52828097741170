import React from 'react'
import moment from 'moment';

const LatestOrderList = (props) => {
    const { escrows } = props; 
   
    return (
        <>
            {
                escrows && escrows.map((escrow) => (
                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                        <div className="d-flex align-items-center">
                        <button className="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 btn-sm d-flex align-items-center justify-content-center"><i className="fas fa-arrow-down" aria-hidden="true"></i></button>
                        <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark text-sm">{escrow.myName}</h6>
                            <span className="text-xs"> {moment(escrow?.createdAt).format('DD MMM YYYY, hh:mm A')}</span>
                        </div>
                        </div>
                        <div className="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
                            { escrow?.invoiceAmt ? parseFloat(escrow?.invoiceAmt) + parseFloat(escrow?.additionalAmt) : 0}
                        </div>
                    </li>
                ))
            }        
        </>
    )
}

export default LatestOrderList