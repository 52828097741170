import React from "react";
import { Button } from "react-bootstrap";
import { Card } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const useConfirm = (callback, message) => {

    const showDialog = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <Card>
                    <Card.Body>
                        <div className='custom-ui text-center p-4'>
                            <h3 className="mb-0">Are you sure?</h3>
                            <p className="fs-16">You want to delete this {message}?</p>
                            <Button onClick={onClose} className="red-btn-mui mr-3 px-4 text-white">No</Button>
                            <Button
                                onClick={() => {
                                    callback(onClose);
                                }}
                                className="green-btn-mui px-4 text-white"
                            >
                                Yes, Delete it!
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
              );
            }
        });
    }

    return {
        showDialog
    };
}

export default useConfirm;