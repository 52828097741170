import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { GuestApi } from "../../../utils/api";
import {  Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const ResetPasswordForm = (props) => {

    const { users } = props;

    const formInputObj = {
        email: "",
        role: "",
        password: "",
        confirm_password: "",
    };
    const navigate = useNavigate();
    const [values, setValues] = useState(formInputObj);
    const [errors, setErrors] = useState([]);

    const handleChange = event => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,15})");
        if (!values.password) {
            formIsValid = false;
            errors.password = "Password is required";
        }  else if (!strongRegex.test(values.password)) {
            formIsValid = false;
            errors.password = "Password should be between 6 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
        }  else if (values.password.length < 6) {
            formIsValid = false;
            errors.password = "Password must be min 6 characters & max 15 characters";
        }
        if (!values.role && users.length > 1) {
            formIsValid = false;
            errors.role = "Role is required";
        }
        
        if (!values.confirm_password) {
            formIsValid = false;
            errors.confirm_password = "Confirm Password is required";
        }
        if (values.confirm_password && values.password) {
            if (values.password !== values.confirm_password) {
                formIsValid = false;
                errors.confirm_password = "Password and confirm password must be same";
            }
        }

        setErrors({ ...errors, errors });
        return formIsValid;
    }

    const submit = async (e) => {

        console.log('values',values);
        try {
            e.preventDefault();
           
            if (handleValidation()) {
                var saveResponse = await GuestApi.post(`/user/reset-password`, values);
                const { status, data } = saveResponse;
                
                if (status === 200 && data.user !== undefined) {
                    toast.success('Password Reset Successfully..');
                    navigate("/");
                }
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    useEffect(() => {
        setValues({ ...values, role: users?.[0]?.['role'], email: users?.[0]?.['email'] });
    }, [users]);


    return (
        <>
            <Form>
                {
                    users.length > 1 && 
                    <>
                        <Form.Label>Select Role</Form.Label>
                        <div className="d-flex">
                            <Form.Check className="mb-1 pe-4" type="radio" label="Supplier" value="Supplier" name="role" onChange={handleChange} checked={values.role == "Supplier"}/>
                            <Form.Check className="mb-1" type="radio" label="Buyer" value="Buyer" name="role" onChange={handleChange} checked={values.role == "Buyer"}/>
                        </div>
                    </>
                }
                <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Control className="form-control-lg" type="password" name="password" value={values.password} onChange={handleChange} placeholder="New Password" />
                    {errors.password && <small className="text-danger">{errors.password}</small>}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Control className="form-control-lg" type="password" name="confirm_password" value={values.confirm_password} onChange={handleChange} placeholder="Confirm New Password" />
                    {errors.confirm_password && <small className="text-danger">{errors.confirm_password}</small>}
                </Form.Group>
                <Button variant="warning" onClick={e => submit(e)} size="lg" className="w-100 mb-5">
                    Submit
                </Button>
            </Form>
        </>
    );
}
export default ResetPasswordForm;
