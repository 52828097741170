import React from 'react'
import UserAuthLayout from "../../../Layout/UserAuthLayout"
import PaymentGatewayForm from './PaymentGatewayForm';

const PaymentGateway = () => {

    return (
        <>
            <UserAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="card">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="text-uppercase mb-3">purchase hvam by using Fiat money</h6>
                                        </div>
                                        <PaymentGatewayForm
                                            type="add"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UserAuthLayout>
        </>
    )
}

export default PaymentGateway