import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

import AdminAuthLayout from "../../../Layout/AdminAuthLayout";
import { escrowAddress, escrowABI, expoURL } from "./../../../Contract";
import { useSigner, useProvider, useAccount } from "wagmi";

const Withdraw = () => {
  const library = useProvider({
    chainId: 56,
  });
  const { data: signer } = useSigner();
  const { isConnected: active, address: account } = useAccount();

  const [dealId, setDealId] = useState();
  const [address, setAddress] = useState("");
  const [contract, setContract] = useState(null);
  const [transStatus, setTransStatus] = useState("");

  const getContract = (address, ABI) => {
    const contract = new ethers.Contract(escrowAddress, escrowABI, library);

    return contract.connect(signer);
  };

  useEffect(() => {
    if (library && signer) {
      setContract(getContract(escrowAddress, escrowABI));
    } else {
      setContract(null);
    }
  }, [library, account, signer]);

  const withdrawFunds = async () => {
    try {
      setTransStatus("Confirm Transaction on Wallet");

      const tx = await contract.withdrawFunds(Number(dealId), address);

      setTransStatus("Waiting For Confirmation");
      await tx.wait();

      setTransStatus(
        `Transaction Completed. <a target="_blank" href="${expoURL}/tx/${
          tx.hash || ""
        }">view</a>`
      );
    } catch (err) {
      setTransStatus(
        err?.error?.data?.message || err?.error?.message || "Transaction Reverted"
      );
    }
  };

  return (
    <>
        <AdminAuthLayout>
        <div className="container-fluid py-4">
            <div className="row">
            <div className="col-12 col-sm-12 col-md-4">
                <div className="card">
                <div className="card-body">
                    <div className="row">
                    <div className="col-12 mb-xl-3 mb-4">
                        <div className="mb-3">
                        <label for="file" className="ms-0">
                            Withdrawal for Dispute
                        </label>
                        <input
                            onChange={(e) => setDealId(e.target.value)}
                            type="text"
                            multiple
                            name="Deal ID"
                            className="form-control"
                            placeholder="Deal ID"
                            aria-label="Deal ID"
                        />
                        </div>
                        <div className="mb-3">
                        <label for="file" className="ms-0 text-capitalize">
                            receiver address
                        </label>
                        <input
                            onChange={(e) => setAddress(e.target.value)}
                            type="text"
                            multiple
                            name="Add Address"
                            className="form-control"
                            placeholder="Add Address"
                            aria-label="Add Address"
                        />
                        </div>
                        <div className="text-end">
                        <button
                            onClick={() => withdrawFunds()}
                            disabled={!dealId && address === ""}
                            type="button"
                            className="btn btn-warning fs-14 px-4 ml-4 mb-0"
                        >
                            Withdraw
                        </button>
                        </div>
                    </div>

                    <div className="col-12 mb-xl-3">{transStatus}</div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </AdminAuthLayout>
    </>
  );
};

export default Withdraw;
