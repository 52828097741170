import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Hedding from "../../../Components/Hedding";
import { UserContext } from "../../../Context/UserContext";
import UserAuthLayout from "../../../Layout/UserAuthLayout";
import { AuthUserApi } from "../../../utils/api";
import Login from "../../User/Login/Login";
import PaymentDetails from "./PaymentDetails";
import PaymentMaker from "./PaymentMaker";
import PaymentRelease from "./PaymentRelease";
import TransactionDetails from "./TransactionDetails";
import TransactionStatus from "./TransactionStatus";

const TransactionRequest = () => {
	const [order, setOrder] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();

	const { user } = useContext(UserContext);
    const { email, role } = user?.user || {};

    const getData = async () => {
        try {
            const listResponse = await AuthUserApi.get(`/user/transaction-get/${id}`);
            const { status, data } = listResponse;
            if (status === 200 && data.escrow !== undefined) {
				const { escrow } = data;
                setOrder(escrow);
				if( (escrow.myEmail == email && escrow.myRole == role) || (escrow.userEmail == email && escrow.userRole == role) ){
                    navigate(`/transaction-request/${id}`);
				}else{
					navigate(`/dashboard`);
				}
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    useEffect(() => {
        getData();
    }, []);

	return (
		<>
			{
				(user == null) ? 
					<Login/>
				:
				<UserAuthLayout id={id}>
					<Hedding Hedding="Transaction request" />
					<div className="container-fluid py-4">
						<div className="row">
							<div className="col-md-7 mt-4">
								<PaymentMaker 
									order={order}
								/>
							</div>
							{/* right side swection */}
							<div className="col-md-5 mt-4">
								<TransactionStatus 
									order={order}
									id={id}
								/>
							</div>
						</div>
					</div>
				</UserAuthLayout>
			}
		</>
	);
};

export default TransactionRequest;
