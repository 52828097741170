import React, { useEffect, useState } from "react";
import {  Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminAuthLayout from "../../../Layout/AdminAuthLayout";
import useForm from "../../../useForm";
import { AuthApi } from "../../../utils/api";
import { toast } from 'react-toastify';
import { validateUserEdit } from "../../../validate/validateUserRegister";

const EditCustomer = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);

    const handleChecked = (event) => {
        const { name, checked} = event.target;
        setValues({
            ...values,
            [name]: checked
        });
    };

    const getData = async () => {
        try {
            const listResponse = await AuthApi.get(`/admin/get-user/${id}`);
            const { status, data } = listResponse;
            if (status === 200 && data.user !== undefined) {
                if (Object.keys(data.user).length !== 0) {
                    for (let [key] of Object.entries(formInputObj)) {
                        formInputObj[key] = data.user[key];
                    }
                    if (data.countries !== undefined) setCountries(data.countries);
                    setValues({ ...formInputObj });
                }
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const formInputObj = {
        name: "",
        email: "",
        mobile_no: "",
        country_id: "",
        role: "",
        is_active: "",
        is_verified: "",
        username: "",
        company_name: "",
    };

    const submit = async () => {
        try {
            let saveResponse = await AuthApi.put(`/admin/user-update/${id}`, values);
            const { status, data } = saveResponse;
            if (status === 201 && data.user !== undefined) {
                setValues({ ...formInputObj });
                navigate("/customers");
                toast.success('Customer Edited Successfully..');
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } = useForm(submit, validateUserEdit, formInputObj);

    useEffect(() => {
        getData();
    }, []);


    return (
        <>
            <AdminAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header pb-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0">Edit Customer</h5>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="text-uppercase text-sm">Edit Customer Details</p>
                                    <Form role="form" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label
                                                        for="example-text-input"
                                                        className="form-control-label"
                                                    >
                                                        Name
                                                    </label>
                                                    <input className="form-control" onChange={handleChange} type="text" name="name" value={values?.name} />
                                                    {errors.name && <small className="text-danger">{errors.name}</small>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label
                                                        for="example-text-input"
                                                        className="form-control-label"
                                                    >
                                                        Username
                                                    </label>
                                                    <input disabled className="form-control" type="text" name="username" value={values.username} onChange={handleChange} placeholder="Username"/>
                                                    {errors.username && <small className="text-danger">{errors.username}</small>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label
                                                        for="example-text-input"
                                                        className="form-control-label"
                                                    >
                                                        Mobile Number
                                                    </label>
                                                    <input className="form-control" onChange={handleChange} type="text" name="mobile_no" value={values?.mobile_no} />
                                                    {errors.mobile_no && <small className="text-danger">{errors.mobile_no}</small>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label
                                                        for="example-text-input"
                                                        className="form-control-label"
                                                    >
                                                        Email Address
                                                    </label>
                                                    <input className="form-control" onChange={handleChange} type="email" name="email" value={values?.email}/>
                                                    {errors.email && <small className="text-danger">{errors.email}</small>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label
                                                        for="example-text-input"
                                                        className="form-control-label"
                                                    >
                                                        Role
                                                    </label>
                                                    <input className="form-control" onChange={handleChange} type="text" disabled name="role" value={values?.role} />
                                                    {errors.role && <small className="text-danger">{errors.role}</small>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label
                                                    for="example-text-input"
                                                    className="form-control-label"
                                                >
                                                    Country
                                                </label>
                                                <Form.Group className="mb-3">
                                                    <Form.Select className="mb-3" aria-label="Default select example" name="country_id" value={values.country_id} onChange={handleChange}>
                                                        <option>Select Country</option>
                                                        {
                                                            countries && countries.length && countries.map((country) => 
                                                                <option value={country.id}>{country.country_name}</option>
                                                            )
                                                        }
                                                    </Form.Select>
                                                    {errors.country_id && <small className="text-danger">{errors.country_id}</small>}
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label for="example-text-input" className="form-control-label" >
                                                        Company Name
                                                    </label>
                                                    <input className="form-control" type="text" name="company_name" value={values.company_name} onChange={handleChange} placeholder="Company Name" />
                                                    {errors.company_name && <small className="text-danger">{errors.company_name}</small>}
                                                </div>
                                            </div>
                                            <div className="col-4 mt-4">
                                                <div className="form-check form-check-info text-start">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="is_active"
                                                        value={values?.is_active}
                                                        id="flexCheckDefault"
                                                        checked={values?.is_active}
                                                        onChange={handleChecked}
                                                    />
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        Is Active?{" "}
                                                    </label>
                                                </div>
                                            </div>                                           
                                            <div className="col-4 mt-4">
                                                <div className="form-check form-check-info text-start">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="is_verified"
                                                        value={values?.is_verified}
                                                        id="flexCheckDefault"
                                                        checked={values?.is_verified}
                                                        onChange={handleChecked}
                                                    />
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        Is Verified?{" "}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-12 text-end">
                                                <button
                                                    type="submit"
                                                    className="btn bg-gradient-primary me-3 mb-0"
                                                >
                                                    Save
                                                </button>
                                                <Link to="/customers" className="btn bg-gradient-danger mb-0">Cancel</Link>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminAuthLayout> 
        </>
    );
};

export default EditCustomer;
