import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap"
import NotifyStatus from '../../../../Components/NotifyStatus';

const StatusDealCancel = (props) => {
    const { order, isConnected, role_id, refundPayment } = props;
	const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

	const onRefundPayment = async () => {
        await refundPayment(Number(order.escrowNo));
        handleClose();
    }

	return (
		<>
			<div className="card mb-4 mb-md-5">
				<div className="card-header bg-light rounded-0 pb-0 px-3">
					<h4 className="mb-2">Status</h4>
					<p className="mb-4 text-danger"><span className="badge badge-sm bg-gradient-danger me-3">{ order?.escrowStatus }</span></p>
				</div>
				<div className="card-body py-5 p-3">
					<div className="row">
						<div className="col-12">
							<h6 className="text-danger">Escrow { order?.escrowStatus }</h6>
							<p className="text-danger">{ order?.rejectedBy } rejected the escrow request.</p>
						</div>
						{
                            order?.paymentStatus == 'Credited' &&
							<>
								{
									role_id == 1 &&
									<div className="col-12 col-md-6">
										<button disabled={!isConnected} type="button" className="btn bg-gradient-danger w-100 mx-1" onClick={handleShow}>Refund Payment</button>
									</div>
								}
							</>
                        }
					</div>
				</div>
			</div>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Header>
					<Modal.Title className="mx-auto">Your attention is required</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<NotifyStatus Status="Are you sure you want to refund the payment?" />
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-between">
					<Button disabled={!isConnected} variant="danger" onClick={onRefundPayment}>
						Refund
					</Button>
					<Button variant="primary" onClick={handleClose}>
						Back To Summury
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default StatusDealCancel