export default function validateLogin(values) {
    
    let errors = {};

    if (!values.email.includes('@') && !values.email.includes('.')) { // if true then Username is submitted
        if (!values.email.length) {
            errors.email = "Username/Email is required";
        }
    } else {
        if (!values.email) {
            errors.email = "Username/Email is required";
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = "Email address is invalid";
        }
    }

    if (!values.password) {
    	errors.password = "Password is required";
    } else if (values.password.length < 6) {
        errors.password = "Password is invalid";
    }
    if (!values.role) {
    	errors.role = "Role is required";
    } 
    
    return errors;
}