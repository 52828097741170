import React, { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { UserContext } from "../../Context/UserContext";
import useForm from "../../useForm";
import { AuthUserApi, GuestApi } from "../../utils/api";
import validate from "../../validate/validateEscrow";
import { paymentFor } from "../../utils/constant";
import PaymentInformation from "./PaymentInformation";
import { checkDataIsValid, getEncryptId } from "../../utils/secure";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import EscrowDocForm from "./EscrowDocForm";

const TransacForm = (props) => {
  const { order } = props;
  const [countries, setCountries] = useState([]);
  const [checked, setChecked] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [totalFee, setTotalFee] = useState(0);
  const [oppositeUser, setOppUser] = useState({});
  const [charge, setCharge] = useState([]);
  const [readOnly, setReadOnly] = useState(true);

  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  const { email, role, name, country_id, wallet_address } = user?.user || {};

  const oppUser = role !== "Buyer" ? "Buyer" : "Supplier";

  const formInputObj = {
    myRole: role,
    myName: name,
    myEmail: email,
    myCountry: country_id,
    paymentFor: "Goods",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    userCountry: "",
    invoiceAmt: 0,
    additionalAmt: 0,
    userRole: oppUser,
    userEmail: "",
    userCompanyName: "",
    userContactName: "",
    description: "",
    address: "",
    myWAddress: "",
    userWAddress: "",
    fileUrl: "",
    escrowStatus: "Pending",
    escrow_documents: [],
  };

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  const getData = async () => {
    try {
      const listResponse = await GuestApi.get(`/user/countries`);
      const { status, data } = listResponse;
      if (status === 200 && data.countries !== undefined) {
        setCountries(data.countries);
      }
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
  };

  const getChargeData = async () => {
    try {
      const listResponse = await AuthUserApi.get(`/user/charge`);
      const { status, data } = listResponse;
      if (status === 200 && data.charge !== undefined) {
        setCharge(data.charge);
      }
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!values.userCountry) {
      formIsValid = false;
      errors.userCountry = "Country is required";
    }
    var validate = /^[0-9]*$/;
    if (!checkDataIsValid(values.invoiceAmt)) {
      formIsValid = false;
      errors.invoiceAmt = "Invoice amount is required";
    } else if (!validate.test(values.invoiceAmt)) {
      formIsValid = false;
      errors.invoiceAmt = "Amount should be number and greater than 0";
    }

    setErrors({ ...errors, errors });
    return formIsValid;
  };

  const handleEmailChange = async (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    try {
      if (/\S+@\S+\.\S+/.test(value)) {
        const listResponse = await AuthUserApi.post(`/user/get-opp-user`, {
          ...values,
          userEmail: value,
        });
        const { status, data } = listResponse;
        const { user, myUser } = data;
        if (status === 200 && user !== null) {
          setOppUser(user);
          if (user.country_id !== values.userCountry) {
            setErrors({
              ...errors,
              userCountry: `Selected country & ${oppUser} country doesn't match.`,
            });
          }
          setValues({
            ...values,
            userEmail: value,
            userContactName: user.name,
            userCompanyName: user.company_name,
            myWAddress:
              role == "Buyer" ? myUser?.wallet_address : user?.wallet_address,
            userWAddress:
              role == "Supplier"
                ? myUser?.wallet_address
                : user?.wallet_address,
          });
        } else {
          setValues({
            ...values,
            userEmail: value,
            userContactName: "",
            userCompanyName: "",
            myWAddress: "",
            userWAddress: "",
          });
        }
      }
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
  };

  const submit = async () => {
    try {
      const formData = new FormData();
      for (let [key] of Object.entries(values)) {
        if (key === "escrow_documents") {
          formData.append(key, JSON.stringify(values[key]));
        } else if (key === "escrowStatus") {
          formData.append("escrowStatus", "Pending");
        } else {
          formData.append(key, values[key]);
        }
      }
      if (values.escrow_documents && values.escrow_documents.length) {
        values.escrow_documents.forEach(function (escrowDoc, indx) {
          if (escrowDoc.documentName !== "" && escrowDoc.documentUrl !== "") {
            formData.append(`documentUrl${indx}`, escrowDoc.documentUrl);
          }
        });
      }
      let encId = getEncryptId(order.id);
      let saveResponse = null;
      if (Object.keys(order).length !== 0) {
        saveResponse = await AuthUserApi.put(
          `/user/transaction-update/${encId}`,
          formData
        );
      } else {
        saveResponse = await AuthUserApi.post(
          "/user/transaction-create",
          formData
        );
      }
      const { status, data } = saveResponse;
      if (status === 201 && data.escrow !== undefined) {
        setValues({ ...formInputObj });
        navigate(`/transaction-success/${data.escrow.id}`);
        toast.success("Escrow Created Successfully..");
      }
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
      const { response } = e;
      if (
        response !== undefined &&
        Object.keys(response.data).length &&
        response.data.error !== undefined
      ) {
        setErrors(response.data.error); // if error from server side
      }
    }
  };

  const sendLetter = async (e) => {
    try {
      const formData = new FormData();
      for (let [key] of Object.entries(values)) {
        if (key == "escrowStatus") {
          formData.append("escrowStatus", "In Draft");
        } else if (key === "escrow_documents") {
          formData.append(key, JSON.stringify(values[key]));
        } else {
          formData.append(key, values[key]);
        }
      }
      if (values.escrow_documents && values.escrow_documents.length) {
        values.escrow_documents.forEach(function (escrowDoc, indx) {
          if (escrowDoc.documentName !== "" && escrowDoc.documentUrl !== "") {
            formData.append(`documentUrl${indx}`, escrowDoc.documentUrl);
          }
        });
      }
      e.preventDefault();
      if (handleValidation()) {
        let saveResponse = null;
        let encId = getEncryptId(order.id);
        if (Object.keys(order).length !== 0) {
          saveResponse = await AuthUserApi.put(
            `/user/transaction-update/${encId}`,
            formData
          );
        } else {
          saveResponse = await AuthUserApi.post(
            "/user/transactiont-create",
            formData
          );
        }
        const { status, data } = saveResponse;

        if (status === 201 && data.escrow !== undefined) {
          setValues({ ...formInputObj });
          navigate("/agreement-listing");
          toast.success("Escrow Created Successfully..");
        }
      }
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
      const { response } = e;
      if (
        response !== undefined &&
        Object.keys(response.data).length &&
        response.data.error !== undefined
      ) {
        setErrors(response.data.error); // if error from server side
      }
    }
  };

  const { handleChange, handleSubmit, values, errors, setErrors, setValues } =
    useForm(submit, validate, formInputObj);

  useEffect(() => {
    getData();
    getChargeData();
  }, []);

  useEffect(() => {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (
      checkDataIsValid(values.invoiceAmt) &&
      validNumber.test(values.invoiceAmt) &&
      parseFloat(values.invoiceAmt) != 0
    ) {
      setShowInfo(true);
      let tax = charge?.charge;
      let invAmt = parseInt(values.invoiceAmt);
      let transFee = (invAmt * tax) / 100;
      setTotalFee(transFee + invAmt);
      setValues({ ...values, additionalAmt: transFee });
    } else {
      setShowInfo(false);
    }
  }, [values.invoiceAmt]);

  useEffect(() => {
    if (Object.keys(order).length !== 0) {
      for (let [key] of Object.entries(formInputObj)) {
        if (key === "escrow_documents") {
          formInputObj[key] = order.escrow_documents;
        } else {
          formInputObj[key] = order[key];
        }
      }
      setValues({ ...formInputObj });
    }
  }, [order]);

  return (
    <>
      <Form role="form">
        <div className="card mb-4">
          <div className="card-header pb-0 px-3">
            <h6 className="mb-0">1. Transaction Summary</h6>
          </div>
          <div className="card-body pt-4 p-3">
            <div className="row">
              <div className="col-12">
                <label for="exampleFormControlSelect1">My Role</label>
                <div className="d-flex">
                  <Form.Check
                    className="mb-1 pe-4"
                    type="radio"
                    label="Buyer"
                    value="Buyer"
                    name="myRole"
                    checked={values.myRole == "Buyer"}
                  />
                  <Form.Check
                    className="mb-1"
                    type="radio"
                    label="Supplier"
                    value="Supplier"
                    name="myRole"
                    checked={values.myRole == "Supplier"}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label for="paymentFor">Payment For</label>
                  <select
                    className="form-control"
                    id="paymentFor"
                    name="paymentFor"
                    value={values.paymentFor}
                    onChange={handleChange}
                  >
                    {paymentFor &&
                      paymentFor.map((payment, i) => (
                        <option key={i} value={payment.value}>
                          {payment.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label for="userCountry">{oppUser} Country</label>
                  <select
                    className="form-control"
                    id="userCountry"
                    name="userCountry"
                    value={values.userCountry}
                    onChange={handleChange}
                  >
                    <option value="">Select Country</option>
                    {countries &&
                      countries.length &&
                      countries.map((country) => (
                        <option value={country.id}>
                          {country.country_name}
                        </option>
                      ))}
                  </select>
                  {errors.userCountry && (
                    <small className="text-danger">{errors.userCountry}</small>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label for="StartDate">Start Date</label>
                  <input
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    name="startDate"
                    onChange={handleChange}
                    value={moment(values.startDate).format("YYYY-MM-DD")}
                    className="form-control form-control-lg"
                  />
                  {errors.startDate && (
                    <small className="text-danger">{errors.startDate}</small>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label for="EndDate">End Date</label>
                  <input
                    type="date"
                    min={
                      values.startDate
                        ? moment(values.startDate).format("YYYY-MM-DD")
                        : moment().format("YYYY-MM-DD")
                    }
                    name="endDate"
                    onChange={handleChange}
                    value={moment(values.endDate).format("YYYY-MM-DD")}
                    className="form-control form-control-lg"
                  />
                  {errors.endDate && (
                    <small className="text-danger">{errors.endDate}</small>
                  )}
                </div>
              </div>
              <div className="col-12">
                <label for="exampleFormControlSelect1">Invoice Amount</label>
                <div className="row">
                  <div className="col-4 col-sm-4 col-md-3 pe-0">
                    <p className="btn bg-gradient-dark btn-lg shadow-none w-100 mb-0 px-4 cursor-unset transform-none">
                      USD
                    </p>
                  </div>
                  <div className="col-8 col-sm-8 col-md-9">
                    <div className="mb-3">
                      <input
                        type="text"
                        maxLength={10}
                        name="invoiceAmt"
                        value={values.invoiceAmt}
                        onChange={handleChange}
                        className="form-control form-control-lg"
                        placeholder="Invoice Amount"
                        aria-label="Invoice Amount"
                      />
                      {errors.invoiceAmt && (
                        <small className="text-danger">
                          {errors.invoiceAmt}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showInfo && <PaymentInformation values={values} totalFee={totalFee} />}
        {/* Transaction Details */}
        <div className="card mb-4">
          <div className="card-header pb-0 px-3">
            <h6 className="mb-0">2. Transaction Details</h6>
          </div>
          <div className="card-body pt-4 p-3">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-4 col-sm-4 col-md-3">
                    <label for="exampleFormControlSelect1">My Email</label>
                  </div>
                  <div className="col-8 col-sm-8 col-md-9">
                    <div className="mb-3">
                      <p>{values.myEmail}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="mb-3">
                  <label for="ContactEmail">
                    {`${oppUser}'s`} Contact Email
                  </label>
                  <input
                    type="text"
                    name="userEmail"
                    onChange={handleEmailChange}
                    value={values.userEmail}
                    className="form-control form-control-lg"
                    placeholder="Contact Email"
                    aria-label="Contact Email"
                  />
                  {errors.userEmail && (
                    <small className="text-danger">{errors.userEmail}</small>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="mb-3">
                  <label for="CompanyName">{`${oppUser}'s`} Company Name</label>
                  <input
                    type="text"
                    readOnly={readOnly}
                    name="userCompanyName"
                    onChange={handleChange}
                    value={values.userCompanyName}
                    className="form-control form-control-lg"
                    placeholder="Company Name"
                    aria-label="Company Name"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="mb-3">
                  <label for="ContactName">
                    {`${oppUser}'s`} Contact Details
                  </label>
                  <input
                    type="text"
                    readOnly={readOnly}
                    name="userContactName"
                    onChange={handleChange}
                    value={values.userContactName}
                    className="form-control form-control-lg"
                    placeholder="Contact Details"
                    aria-label="Contact Name"
                  />
                  {errors.userContactName && (
                    <small className="text-danger">
                      {errors.userContactName}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="mb-3">
                  <label for="description">Wallet Address</label>
                  <input
                    type="text"
                    readOnly={readOnly}
                    name="userWAddress"
                    value={values.userWAddress}
                    className="form-control form-control-lg"
                    placeholder="Wallet Address"
                    aria-label="Wallet Address"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label for="description">
                    Description of Deal Transaction
                  </label>
                  <textarea
                    type="text"
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    className="form-control form-control-lg"
                    placeholder="Transaction Description"
                    aria-label="Transaction Description"
                    row="5"
                  />
                  {errors.description && (
                    <small className="text-danger">{errors.description}</small>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label for="file">Delivery Address</label>
                  <input
                    type="text"
                    multiple
                    name="address"
                    className="form-control"
                    placeholder="Address"
                    aria-label="Address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Payment Release */}
        <div className="card mb-4">
          <div className="card-header pb-0 px-3">
            <h6 className="mb-0">3. Payment Release</h6>
          </div>
          <div className="card-body pt-4 p-3">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <EscrowDocForm
                    handleSubmit={handleSubmit}
                    values={values}
                    setValues={setValues}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="col-12">
                <p>
                  We will notify you by email when we receive the documents when
                  we release the payments. You can also log in to your Escrows
                  account to check the status
                </p>
              </div>
              <div className="col-12">
                <div className="form-check form-check-info text-start">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={checked}
                    onChange={handleChecked}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    I agree the{" "}
                    <a
                      href="javascript:;"
                      className="text-warning font-weight-bolder"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <button
                  type="button"
                  onClick={(e) => sendLetter(e)}
                  className={`btn btn-outline-default btn-lg px-3 w-100 mt-4 mb-0 ${
                    checked ? "" : "disabled"
                  }`}
                >
                  Send Later
                </button>
              </div>
              <div className="col-12 col-md-6">
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={`btn btn-lg btn-warning btn-lg px-3 w-100 mt-4 mb-0 ${
                      checked ? "" : "disabled"
                    }`}
                  >
                    Place Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default TransacForm;
