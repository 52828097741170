export function validateRegister(values) {
    let errors = {};
    if (!values.email) {
        errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    	errors.email = "Email address is invalid";
    }
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,15})");
    if (!values.password) {
        errors.password = "Password is required";
    }  else if (!strongRegex.test(values.password)) {
        errors.password = "Password should be between 6 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
    }  else if (values.password.length < 6) {
        errors.password = "Password must be min 6 characters & max 15 characters";
    }
    if (!values.confirm_password) {
        errors.confirm_password = "Confirm password is required";
    }  else if (values.confirm_password != values.password) {
        errors.confirm_password = "Password and Confirm Password not match";
    }  
    if (!values.username) {
    	errors.username = "Username is required";
    } 
    if (!values.name) {
    	errors.name = "Name is required";
    } 
    if (!values.role) {
    	errors.role = "Role is required";
    } 
    if (!values.mobile_no) {
    	errors.mobile_no = "Mobile number is required";
    }
    if (!values.country_id) {
    	errors.country_id = "Country is required";
    }
    
    return errors;
}

export function validateUserEdit(values) {
    let errors = {};
    if (!values.email) {
        errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    	errors.email = "Email address is invalid";
    }
    if (!values.name) {
    	errors.name = "Name is required";
    } 
    if (!values.username) {
    	errors.username = "Username is required";
    } 
    // if (!values.role) {
    // 	errors.role = "Role is required";
    // } 
    if (!values.mobile_no) {
    	errors.mobile_no = "Mobile number is required";
    }
    if (!values.country_id) {
    	errors.country_id = "Country is required";
    }

    if (!values.username) {
    	errors.username = "Username is required";
    }
    
    return errors;
}