import React from "react";
import { Link } from "react-router-dom";
import { getEncryptId } from "../../../../utils/secure";

const UserListTr = (props) => {

    const { adminUser, indx } = props;

    return (
        <>
            <tr>
                <td>
                    <div className="d-flex px-2 py-1">
                        <div className="d-flex flex-column justify-content-center">
                            <p className="text-xs text-secondary mb-0">{indx+1}</p>
                        </div>
                    </div>
                </td>
                <td>
                    <p className="text-xs font-weight-bold mb-0">{adminUser?.name}</p>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{adminUser?.mobile_no}</p>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0"> {adminUser?.email} </p>
                </td>
                <td className="align-middle text-start text-sm">{adminUser?.role?.role} </td>
                <td className="align-middle text-center text-sm">{adminUser?.is_active === true ? 'Yes' : 'No'} </td>
                <td className="align-middle text-center">
                    <Link to={`/edit-user/${getEncryptId(adminUser?.id)}`} className="text-xs font-weight-bold mb-0 text-primary">Edit</Link>
                </td>
            </tr>
        </>
    );
};

export default UserListTr;
