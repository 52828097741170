import React, { useState, useEffect } from "react";
import StatusDealDone from "./StatusDealDone";
import StatusDelivery from "./StatusDelivery";
import StatusPaymentRecived from "./StatusPaymentRecived";
import StatusPending from "./StatusPending";
import StatusInProgress from "./StatusInProgress";
import { escrowAddress, escrowABI } from "./../../../../Contract";
import { ethers } from "ethers";
import { AuthApi } from "../../../../utils/api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import StatusInDraft from "./StatusInDraft";
import StatusDealRejected from "./StatusDealRejected";
import { useContext } from "react";
import { AdminContext } from "../../../../Context/AdminContext";
import StatusReleasePayment from "./StatusReleasePayment";
import StatusDealCancel from "./StatusDealCancel";
import StatusDealRefunded from "./StatusDealRefunded";
import { useSigner, useProvider, useAccount } from "wagmi";

const AdminTransctionStatus = ({ order }) => {
    const library = useProvider({
        chainId: 56,
    });
    const { data: signer } = useSigner();
    const { isConnected: active, address: account } = useAccount();

    const [contract, setContract] = useState(null);
    const [transStatus, setTransStatus] = useState("");

    const { admin } = useContext(AdminContext);
    const { role, role_id } = admin?.admin || {};

    const navigate = useNavigate();
    const { id } = useParams();

    const getContract = (address, ABI) => {
        const contract = new ethers.Contract(address, ABI, library);

        return contract.connect(signer);
    };

    const cancelRequest = async () => {
        try {
        let saveResponse = await AuthApi.put(`/admin/transaction-update/${id}`, {
            escrowStatus: "Rejected",
            rejectedBy: role?.role,
        });
        const { status, data } = saveResponse;

        if (status === 201 && data.escrow !== undefined) {
            navigate("/admin-transactions");
            toast.success("Request Rejected Successfully..");
        }
        } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
        const { response } = e;
        }
    };

    const updateDealStatus = async (escrowStatus) => {
        try {
        let saveResponse = await AuthApi.put(`/admin/transaction-update/${id}`, {
            escrowStatus: escrowStatus,
        });
        const { status, data } = saveResponse;

        if (status === 201 && data.escrow !== undefined) {
            navigate("/admin-transactions");
            if (escrowStatus === "Release Payment")
            toast.success(
                "Payment confirmation forwarded to Admin for Confirmation"
            );
            else {
            toast.success("Deal canceled, refund request forwarded to Admin");
            }
        }
        } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
        const { response } = e;
        }
    };

    const cancelDeal = async (dealID) => {
        try {
        setTransStatus("Confirm transaction on wallet");

        const tx = await contract.cancelDeal(Number(dealID || 0));

        setTransStatus("Waiting For Confirmation");

        await tx.wait();

        let saveResponse = await AuthApi.put(`/admin/transaction-update/${id}`, {
            escrowStatus: "Deal Cancel",
            rejectedBy: role?.role,
        });
        const { status, data } = saveResponse;

        if (status === 201 && data.escrow !== undefined) {
            navigate("/admin-transactions");
            toast.success("Deal Rejected Successfully..");
        }

        setTransStatus("Deal Cancelled");
        } catch (err) {
        setTransStatus(err?.error?.data?.message || err?.error?.message || "Transaction Reverted");
        }
    };

    const refundPayment = async (dealID) => {
        try {
        setTransStatus("Confirm transaction on wallet");

        const tx = await contract.RefundPayment(Number(dealID || 0));

        setTransStatus("Waiting For Confirmation");

        await tx.wait();

        let saveResponse = await AuthApi.put(`/admin/transaction-update/${id}`, {
            escrowStatus: "Refunded",
            paymentStatus: "Refunded",
        });
        const { status, data } = saveResponse;

        if (status === 201 && data.escrow !== undefined) {
            navigate("/admin-transactions");
            toast.success("Payment Refund Successfully..");
        }

        setTransStatus("Payment Refunded");
        } catch (err) {
        setTransStatus(err?.error?.data?.message || err?.error?.message || "Transaction Reverted");
        }
    };

    const confirmDeal = async (dealID) => {
        try {
        setTransStatus("Confirm transaction on wallet");

        const tx = await contract.confirmPayment(Number(dealID || 0));

        setTransStatus("Waiting For Confirmation");

        await tx.wait();

        setTransStatus("Order confirmed");
        } catch (err) {
        setTransStatus(err?.error?.data?.message || err?.error?.message || "Transaction Reverted");
        }
    };

    const tranfserPaymentToSeller = async (dealID) => {
        try {
        setTransStatus("Confirm transaction on wallet");

        const tx = await contract.transferPaymentToSeller(Number(dealID));

        setTransStatus("Waiting For Confirmation");

        await tx.wait();

        let saveResponse = await AuthApi.put(`/admin/transaction-update/${id}`, {
            escrowStatus: "Deal Closed",
        });
        const { status, data } = saveResponse;

        if (status === 201 && data.escrow !== undefined) {
            navigate("/admin-transactions");
            toast.success("Payment Release Successfully..");
        }

        setTransStatus("Payment transfered to Seller.");
        } catch (err) {
        setTransStatus(err?.error?.data?.message || err?.error?.message || "Transaction Reverted");
        }
    };

    useEffect(() => {
        if (library && signer) {
        setContract(getContract(escrowAddress, escrowABI));
        } else {
        setContract(null);
        }
    }, [library, account, active, signer]);

    let component = "";

    switch (order.escrowStatus) {
        case "In Draft":
        component = <StatusInDraft />;
        break;

        case "Pending":
        component = (
            <StatusPending
            order={order}
            role_id={role_id}
            cancelRequest={cancelRequest}
            />
        );
        break;

        case "In Progress":
        component = (
            <StatusInProgress
            order={order}
            role_id={role_id}
            cancelRequest={cancelRequest}
            />
        );
        break;

        case "Credited":
        component = (
            <StatusPaymentRecived
            isConnected={active}
            order={order}
            role_id={role_id}
            cancelDeal={cancelDeal}
            />
        );
        break;

        case "Delivered":
        component = (
            <StatusDelivery
            status="Delivery"
            isConnected={active}
            order={order}
            role_id={role_id}
            cancelDeal={cancelDeal}
            updateDealStatus={updateDealStatus}
            />
        );
        break;

        case "Dispatched":
        component = (
            <StatusDelivery
            status="Dispatch"
            isConnected={active}
            order={order}
            role_id={role_id}
            cancelDeal={cancelDeal}
            updateDealStatus={updateDealStatus}
            />
        );
        break;

        case "Release Payment":
        component = (
            <StatusReleasePayment
            status={order?.escrowStatus ? "Dispatch" : "Delivery"}
            isConnected={active}
            order={order}
            role_id={role_id}
            tranfserPaymentToSeller={tranfserPaymentToSeller}
            />
        );
        break;

        case "Rejected":
        component = (
            <StatusDealRejected
            order={order}
            updateDealStatus={updateDealStatus}
            role_id={role_id}
            isConnected={active}
            />
        );
        break;

        case "Deal Cancel":
        component = (
            <StatusDealCancel
            order={order}
            refundPayment={refundPayment}
            role_id={role_id}
            isConnected={active}
            />
        );
        break;

        case "Deal Closed":
        component = <StatusDealDone isConnected={active} order={order} />;
        break;

        case "Refunded":
        component = <StatusDealRefunded order={order} />;
        break;

        default:
        break;
    }

    return <>{component}</>;
};

export default AdminTransctionStatus;
