import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import SearchBr from '../../../Components/SearchBr';
import AdminAuthLayout from '../../../Layout/AdminAuthLayout'
import { AuthApi } from '../../../utils/api';
import FiatListTableTr from './FiatListTableTr';

const TransferHvamFiat = () => {

    const [orders, setOrders] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(1);
    const [parameter, setParameter] = useState();

    const getData = async (pgNO) => {
        try {
        const listResponse = await AuthApi.get(`/admin/orders?pageNo=${pgNO}`);
        const { status, data } = listResponse;
        if (status === 200 && data.orders !== undefined) {
            setOrders(data.orders);
            const { pageData: { per_page, total_record } } = data || {};
            setPerPage(per_page);
            setTotalRecords(total_record);
        }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const getFilterData = async (pgNO) => {
        try {
            const listResponse = await AuthApi.get(`/admin/orders?pageNo=${pgNO}`, {
                params: { parameter: parameter }
            });
            const { status, data } = listResponse;
            if (status === 200 && data.orders !== undefined) {
                setOrders(data.orders);
                const { pageData: { per_page, total_record } } = data || {};
                setPerPage(per_page);
                setTotalRecords(total_record);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            // setFilterErr();
        }
    }

    const handleSearch = event => {
        const { value } = event.target;
        setParameter(value);
    };

    const getClickedPageNo = (pageNumber) => {
        setCurrentPage(pageNumber);
        parameter !== '' ? getFilterData(pageNumber) : getData(pageNumber);
    }

    useEffect(() => {
        getData(currentPage)
    }, []);

    return (
        <AdminAuthLayout>
            <SearchBr 
                Pageheading="Payment list"
                getFilterData={getFilterData}
                handleSearch={handleSearch}
                parameter={parameter}
            />
            <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header pb-0">
                                    <div className="d-flex align-items-center">
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table align-items-center mb-0">
                                                    <thead>
                                                        <tr>    
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Order No
                                                            </th>    
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                User Name
                                                            </th>                               
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                No. Of HVAM
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Price Of HVAM
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Total Price
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Currency
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                                                                Transaction ID
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                                                                Hash ID
                                                            </th>
                                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Wallet address
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                DATE
                                                            </th>
                                                            <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                Status
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            orders && orders.length > 0 && orders.map((order, indx) => {
                                                                return(
                                                                    <FiatListTableTr
                                                                        order={order}
                                                                        key={indx}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center justify-content-md-end pt-3">
                                            {
                                                // totalRecords > 10 &&
                                                <Pagination
                                                    activePage={currentPage}
                                                    prevPageText='prev'
                                                    nextPageText='next'
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    pageRangeDisplayed={5}
                                                    itemsCountPerPage={perPage}
                                                    totalItemsCount={totalRecords}
                                                    onChange={getClickedPageNo}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </AdminAuthLayout>
    )
}

export default TransferHvamFiat