import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import WalletConnect from './WalletConnect';
import { Offcanvas, Dropdown } from "react-bootstrap";
import SidebarUser from "../Components/SidebarUser";
import Logo from "../assets/img/logo.png";
import { UserContext } from '../Context/UserContext';
import NotificationDropdown from './NotificationDropdown';
import { AuthUserApi } from '../utils/api';
import { useEffect } from 'react';
import { onMessageListener } from '../utils/firebase';
import { useRef } from 'react';

const Header = (props) => {
    const { logoutHandle } = props;
    const [show, setShow] = useState(false);
    const { user } = useContext(UserContext);
    const [unreadNotificationCount, setUnreadNetnotificationCount] = useState(0);
    const [notificationList, setNotificationList] = useState([]);
    const { name, role } = user?.user || {};
    const notificationCountRef = useRef(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getNotifications = async () => {
        try {
            const notificationResponse = await AuthUserApi.get(`/user/get-notifications`);;
            const { status, data } = notificationResponse;
            if (status === 200) {
                if (data.notifications !== undefined) {
                    setNotificationList(data.notifications);
                    setUnreadNetnotificationCount(data.notifications.length);
                }
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    // useEffect(() => {
    //     onMessageListener(onNotificationReceived);
    // }, []);

    function onNotificationReceived(payload) {
        const data = payload.notification;
        const notificationTitle = data.title;
        const notificationOptions = {
            body: data.body
        };

        if (!('Notification' in window)) {
            console.log("Browser does not support system notification");

        } else if (Notification.permission === "granted") {
            const notif = new Notification(notificationTitle, notificationOptions);
        }

        notificationCountRef.current = notificationCountRef.current + 1;
        setUnreadNetnotificationCount(notificationCountRef.current);

    }

    // //  fetch notification list 
    useEffect(() => {
        getNotifications();
    }, [unreadNotificationCount]);

    return (
        <>
            <nav
                className="navbar navbar-main navbar-expand-lg px-3 px-xs-3 px-sm-4 shadow-none border-radius-xl"
                id="navbarBlur"
                data-scroll="false"
            >
                <div className="container-fluid py-1 px-0">
                    <div
                        className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
                        id="navbar"
                    >
                        <Link to="/dashboard"className="navbar-brand m-0 w-25 d-block d-md-none">
                            <img src={Logo} className="img-fluid w-75" alt="main_logo"/>
                        </Link>
                        <ul className="navbar-nav ms-md-auto justify-content-end">
                            <li className="nav-item dropdown pe-0 pe-md-2 d-flex align-items-center me-0 me-md-1">
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="mb-0 noti-icon bg-transparent border-0 shadow-none py-1">
                                        <i className="fa fa-bell cursor-pointer"></i>
                                        <span className="badge badge-md badge-circle badge-floating badge-danger border-white bg-dark d-block">{unreadNotificationCount}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="mt-2">
                                        <NotificationDropdown
                                            notificationList={notificationList}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li className="nav-item d-flex align-items-center">
                                <WalletConnect/>
                            </li>
                            <li className="nav-item pe-2 pe-md-0 d-flex align-items-center d-none d-md-block">
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="mb-0">
                                    <i className="fa fa-user me-sm-1"></i> { name ? name.split(' ')[0] : '' } {`( ${role} )`} 
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="mt-2">
                                        <Link to="/user-profile" className="dropdown-item">Profile</Link>
                                        <Dropdown.Item onClick={logoutHandle}>Sign Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                                <a
                                    href="javascript:;"
                                    className="nav-link text-white p-0"
                                    id="iconNavbarSidenav"
                                    onClick={handleShow}
                                >
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line bg-white"></i>
                                        <i className="sidenav-toggler-line bg-white"></i>
                                        <i className="sidenav-toggler-line bg-white"></i>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Offcanvas show={show} onHide={handleClose} className="mobilesidebar">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <div className="d-flex align-items-center">
                            <div className="text-capitalize">
                                <i className="fa fa-user me-sm-1 me-2"></i> { name ? name.split(' ')[0] : '' } {`( ${role} )`} 
                            </div>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SidebarUser
                        logoutHandle={logoutHandle}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Header