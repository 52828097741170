import React from 'react'

const StatusDealRefunded = (props) => {
    const { order } = props;

	return (
		<>
			<div className="card mb-4 mb-md-5">
				<div className="card-header bg-light rounded-0 pb-0 px-3">
					<h5 className="mb-2">Status</h5>
					<p className="mb-4 text-dark fw-bold"><span className="badge badge-sm bg-gradient-dark me-3">Refunded</span>Refund Confirm</p>
				</div>
				<div className="card-body py-4 p-3">
					<p className="mb-3">Deal rejected by {order?.rejectedBy}, payment refunded to the buyer.</p>
				</div>
			</div>
		</>
	)
}

export default StatusDealRefunded