import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import RegisterForm from "./RegisterForm";
import { GuestApi } from "../../../utils/api";
import useForm from "../../../useForm";
import { toast } from "react-toastify";
import GuestLayout from "../../../Layout/GuestLayout";
import { validateRegister } from "../../../validate/validateUserRegister";

const Register = (props) => {
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);

    const getData = async () => {
        try {
        const listResponse = await GuestApi.get(`/user/countries`);
        const { status, data } = listResponse;
        if (status === 200 && data.countries !== undefined) {
            setCountries(data.countries);
        }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }
    
    const submit = async () => {
        try {
            var saveResponse = await GuestApi.post('/user/register', values);
            const { status, data } = saveResponse;
            
            if (status === 201 && data.user !== undefined) {
                setValues({ ...formInputObj });
                navigate("/");
                toast.success('Register Successfully..');
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    const formInputObj = {
        name: "",
        username: "",
        email: "",
        mobile_no: "",
        country_id: "",
        password: "",
        confirm_password: "",
        role: "",
    };

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } = useForm(submit, validateRegister, formInputObj);

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <GuestLayout
                Title="Attention is the new currency"
                Subtitle="The more effortless the writing looks, the more effort the
                        writer actually put into the process."
                FormTitle="Join HVAM"
                FormSubTitle="Enter your Information"
            >
                <RegisterForm
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    setValues={setValues}
                    values={values}
                    errors={errors}
                    countries={countries}
                />
            </GuestLayout>
        </>
    );
};

export default Register;
