import React from 'react'
import useForm from "../../../useForm";
import validate from "../../../validate/validateCountry";
import { AuthApi } from '../../../utils/api';
import {  Form } from "react-bootstrap";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const CountryForm = (props) => {

    const { editcountry, getCountries } = props;
    const [actionName, setActionName] = useState('Add');

    const submit = async () => {
        try {
            let saveResponse = null;
            let sucMsg = null;
            if (Object.keys(editcountry).length !== 0) {
                saveResponse = await AuthApi.put(`/admin/update-country/${editcountry.id}`, values);
                sucMsg = "Country Details Updated Successfully.";
            } else {
                saveResponse = await AuthApi.post("/admin/add-country", values);
                sucMsg = "Country Details Added Successfully.";
            }

            const { status } = saveResponse;
            if (status === 201) {
                toast.success(sucMsg);
                getCountries();
                setValues(formInputObj);
                setActionName('Add');
            }
        } catch (e) {
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            } else {
                // toast.error(`😱 Axios request failed: ${e}`);
            }
        }
    }

    const formInputObj = {
        country_name: "",
        country_code: ""
    };

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } = useForm(
        submit,
        validate,
        formInputObj
    );

    useEffect(() => {
        if (Object.keys(editcountry).length !== 0) {
            console.log(Object.keys(editcountry).length,'editcountry.......',editcountry);
            for (let [key] of Object.entries(formInputObj)) {
                formInputObj[key] = editcountry[key];
            }
            setValues({ ...formInputObj });
            setActionName('Update');
        }
    }, [editcountry]);

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="col-12">
                    <div className="row">
                        <div className="col-6 col-md-4 mb-3">
                            <label for="country">Country Name</label>
                            <input type="text" onChange={handleChange} value={values.country_name} name="country_name" className="form-control" placeholder="Country Name" aria-label="Country Name" />
                            {errors.country_name && <small className="text-danger">{errors.country_name}</small>}
                        </div>
                        <div className="col-6 col-md-4 mb-3">
                            <label for="code">Country Code</label>
                            <input type="text" onChange={handleChange} value={values.country_code} name="country_code" className="form-control" placeholder="Country Code" aria-label="Country Code" />
                            {errors.country_code && <small className="text-danger">{errors.country_code}</small>}
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <div className="text-end mt-3 mb-0 mb-md-3">
                                <button type="submit" className="btn bg-gradient-warning mb-0">{actionName}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default CountryForm