import React, { useState, useEffect } from "react";
import { Button  } from "react-bootstrap";
import { REACT_APP_IMAGE_URL } from '../../utils/api';
import { HiDocumentDownload, HiTrash } from "react-icons/hi";

export default function EscrowDocFromGrid(props) {

    const { document, changeDocumentRow, removeDocumentRow, ind, errors } = props;
    const { escrow_documents } = errors || {};
    
    const { documentName } = document || {}; // doc_url
    const [displayUrl, setDisplayUrl] = useState('');

    const handleDocNameChange = (event) => {
        const { value, name } = event.target;
        // call set value function here for ind
        changeDocumentRow(ind, name, value);
    }

    const handleDocUrlChange = (event) => {
        const { files, name } = event.target;
        let newUrl = ''
        let newSelectedFile = '';
        if (files && files.length) {
            const selectedFile = files[0];
            newUrl = URL.createObjectURL(selectedFile);
            newSelectedFile = selectedFile;
        }
        setDisplayUrl(newUrl);
        // call set values function here for ind
        changeDocumentRow(ind, name, newSelectedFile);
    }

    useEffect(() => {
        const documentUrl = (document && document.documentUrl) ? REACT_APP_IMAGE_URL+document.documentUrl : '';
        setDisplayUrl(documentUrl);
    },[]);

    useEffect(() => {
        let newUrl = ''
        if (document && document.documentUrl && typeof(document.documentUrl) == 'object') {
            newUrl = URL.createObjectURL(document.documentUrl);
            setDisplayUrl(newUrl);
        }
    },[document]);


    return (
        <React.Fragment>
            <div className="col-12 mt-2 py-1 d-flex justify-content-between align-items-center">
                {displayUrl &&
                    <div className="">
                        <a href={displayUrl} target="_blank" className="text-capitalize text-primary h6"><HiDocumentDownload size={26}/> {documentName}</a>
                    </div>
                }
                <div className="ms-4">
                    <Button
                        color="primary"
                        aria-label="add row"
                        className="add-btn btn bg-gradient-danger rounded-circle mb-0 w-100 px-2 py-2 btn-sm text-sm"
                        onClick={() => removeDocumentRow(ind)}
                        >
                        <HiTrash size={19}/>
                    </Button>
                </div>
            </div>
            <div className="col-md-6 mt-2">
                <div className="form-group m-0">
                    <input className="form-control form-control-lg" type="text" value={documentName} name="documentName" onChange={handleDocNameChange} placeholder="Document Name"/>
                    { escrow_documents && escrow_documents[ind] && escrow_documents[ind].documentName &&
                        <small className="text-danger">{escrow_documents[ind].documentName}</small>
                    }
                </div>
            </div>
            <div className="col-md-6 mt-2">
                <div className="custom-file border">
                    <input onChange={handleDocUrlChange} name="documentUrl" type="file" className="form-control form-control-lg" id="customFile" accept="image/*,application/pdf"/>
                </div>
            </div> 
            
        </React.Fragment>
    )
}
