import React from 'react'

const NotifyStatus = (props) => {
    return (
        <>
            <div className="py-3 text-center">
                <i className="ni ni-bell-55 ni-3x"></i>
                <h4 className="text-gradient text-danger mt-4">{props.Status}</h4>
            </div>
        </>
    )
}

export default NotifyStatus