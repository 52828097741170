import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap"
import NotifyStatus from '../../../../Components/NotifyStatus';

const StatusInProgress = (props) => {
    const { order, cancelRequest, role_id } = props;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onCancelDeal = async () => {
        await cancelRequest();
        handleClose();
    }

  return (
    <>
        <div className="card mb-4 mb-md-5">
            <div className="card-header bg-light rounded-0 pb-0 px-3">
                <h5 className="mb-2">Status</h5>
                <p className="mb-4 text-warning fw-bold"><span className="badge badge-sm bg-gradient-warning me-3">{ order?.escrowStatus }</span>Waiting For Payment</p>
            </div>
            <div className="card-body py-4 p-3">
                <p className="mb-4">Escrow Request is accepted by { order?.userRole } waiting for payment.</p>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <button type="button" className="btn bg-gradient-danger w-100 mx-1" onClick={handleShow}>Cancel Deal</button>
                    </div>
                </div>
            </div>
        </div>    
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
                <Modal.Title className="mx-auto">Your attention is required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NotifyStatus Status="Are you sure you want to cancel the deal?" />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                {/* Deal Cancel msg btn */}
                <Button variant="danger" onClick={onCancelDeal}>
                    Cancel Deal
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Back To Summury
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default StatusInProgress