export default function validateDispatch(values) {
    
    let errors = {};
    if (!values.location) {
    	errors.location = "Location is required";
    } 
    if (!values.docket_no) {
    	errors.docket_no = "Docket no is required";
    } 
    if (!values.dispatchDate) {
    	errors.dispatchDate = "Dispatch date is required";
    } 
    
    return errors;
}