import React from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';

const NotificationDropdownAdmin = (props) => {
    const { notificationList } = props;

    return (
        <>
            {notificationList.map((notemsg) => (
                <li className="mb-2">
                    <Link to={`/order-detail/${notemsg?.escrow_id}`} className="dropdown-item border-radius-md">
                        <div className="d-flex py-1">
                            <div className="d-flex flex-column justify-content-center w-100">
                                <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">{notemsg.title}</span>
                                </h6>
                                <p className="text-xs text-secondary mb-0 d-flex justify-content-between">
                                    <span>
                                        <i className="fa fa-clock me-1"></i>
                                        { moment(notemsg?.createdAt).format('DD MMM YYYY') }
                                    </span>
                                    <span className="badge bg-gradient-warning">EID : {(notemsg?.escrow?.escrowNo)}</span>
                                </p>
                            </div>
                        </div>
                    </Link>
                </li>
            ))}
        </>
    )
}

export default NotificationDropdownAdmin