import React from "react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import Footer from "../Components/Footer";
import HeaderUser from "../Components/HeaderUser";
import SidebarUser from "../Components/SidebarUser";
import { UserContext } from "../Context/UserContext";
import { GuestApi } from "../utils/api";

const UserAuthLayout = (props) => { 
    const { children } = props;

    const { user, setUser } = useContext(UserContext);

    const logoutHandle = async () => {
        try {
            const { refreshToken } = user;
            await GuestApi.post("/user/logout", {refreshToken}); // const logoutResponse =
        } catch (e) {
            const { response } = e;
            console.log(`😱 Axios request failed: ${response}`);
        }
        // in any case user data must be removed from client side
        localStorage.removeItem('user_data');
        setUser(null);
        window.localStorage.clear();
        window.location.reload();
    }

    if(user == null) return <Navigate to='/' />;

    return (
        <>
            <div className="min-height-300 bg-warning position-absolute w-100"></div>
            <SidebarUser logoutHandle={logoutHandle}/>
            <main className="main-content position-relative border-radius-lg ">
                <HeaderUser logoutHandle={logoutHandle}/>
                { children }
                <Footer/>
            </main>
        </>
    );
};

export default UserAuthLayout;
