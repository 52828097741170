import React from "react";
import moment from 'moment';
import { UserContext } from "../../Context/UserContext";
import { useContext } from "react";
import TransactionAction from "../../Components/TransactionAction";
import { Link } from "react-router-dom";
import { getEncryptId } from "../../utils/secure";

const EscrowTableTr = (props) => {

    const { escrow } = props;
    console.log(escrow);

    const { user } = useContext(UserContext);

    const { role } = user?.user || {};

    let actionUrl;

    if(escrow?.escrowStatus === 'Pending' || escrow?.escrowStatus === 'Rejected'){
        actionUrl = `/transaction-request/${getEncryptId(escrow?.id)}`;
    }else if(escrow?.escrowStatus === 'In Draft'){
        actionUrl = `/create-eccrow/${getEncryptId(escrow?.id)}`;
    }else{
        actionUrl = `/payment-summary/${getEncryptId(escrow?.id)}`;
    }

    return (
        <>
            <tr>
                <td>
                    <div className="d-flex px-2 py-1">
                        <div className="d-flex flex-column justify-content-center">
                            <Link to={actionUrl} className="text-xs text-primary font-weight-bold mb-0">{escrow?.escrowNo}</Link>
                        </div>
                    </div>
                </td>
                <td>
                    <p className="text-xs text-secondary font-weight-bold mb-0">{role}</p>
                </td>
                <td>
                    <span className="text-secondary text-xs font-weight-bold">{ moment(escrow?.createdAt).format('DD MMM YYYY') }</span>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{escrow?.myEmail}</p>
                </td>
                <td>
                    <p className="text-xs text-secondary mb-0">{escrow?.userEmail}</p>
                </td>
                <td className={`align-middle text-center text-sm text-${escrow?.escrowStatus.replace(' ', '-')}`}>
                    {escrow?.escrowStatus}
                </td>
                <td className="align-middle text-center">
                    <span className="text-secondary text-xs font-weight-bold">{ parseFloat(escrow?.invoiceAmt) +  parseFloat(escrow?.additionalAmt)}</span>
                </td>
                <td className="align-middle text-center">
                    <TransactionAction
                        status={escrow?.escrowStatus}
                        escrow={escrow}
                    />
                </td>
            </tr>
        </>
    );
};

export default EscrowTableTr;
