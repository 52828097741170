import React from "react";

const Hedding = (props) => {
  return (
    <>
        <div className="container-fluid py-4">
            <div className="row">
                <div className="col-12">
                    <div className="card h-100 mb-4 rounded-0">
                        <div className="card-header pb-0 px-4">
                            <h3 className="mb-0 text-capitalize">{props.Hedding}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Hedding;
