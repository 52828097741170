import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom'
import UserAuthLayout from '../../Layout/UserAuthLayout'
import { AuthUserApi } from '../../utils/api'
import EscrowTableTr from './EscrowTableTr'

const EscrowTable = () => {

    const [escrows, setEscrows] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(1);

    const getData = async (pgNO) => {
        try {
        const listResponse = await AuthUserApi.get(`/user/transactions?pageNo=${pgNO}`);
        const { status, data } = listResponse;
        if (status === 200 && data.escrows !== undefined) {
            setEscrows(data.escrows);
            const { pageData: { per_page, total_record } } = data || {};
            setPerPage(per_page);
            setTotalRecords(total_record);
        }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    const getClickedPageNo = (pageNumber) => {
        setCurrentPage(pageNumber);
        getData(pageNumber);
    }

    useEffect(() => {
        getData(currentPage)
    }, []);

    return (
        <>
            <UserAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-lg-12 mb-lg-0 mb-4">
                            <div className="card ">
                                <div className="card-header pb-0 p-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-2">Transactions</h6>
                                        <h6 className="mb-2">
                                            <Link to="/create-eccrow/0" className="btn btn-sm btn-warning mb-0">Place Order</Link>
                                        </h6>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <div className="card-body pt-2 p-3">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                            <tr>
                                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                                                    TRANSACTION NO.
                                                </th>
                                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    MY ROLE
                                                </th>
                                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    DATE
                                                </th>
                                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    BUYER	
                                                </th>
                                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    SUPPLIER	
                                                </th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    STATUS	
                                                </th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    INVOICE AMT
                                                </th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    ACTION
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    escrows && escrows.length > 0 && escrows.map((escrow, indx) => {
                                                       return(
                                                            <EscrowTableTr
                                                                escrow={escrow}
                                                                key={indx}
                                                            />
                                                       )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center justify-content-md-end pt-3">
                                    {
                                        // totalRecords > 10 &&
                                        <Pagination
                                            activePage={currentPage}
                                            prevPageText='prev'
                                            nextPageText='next'
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            pageRangeDisplayed={5}
                                            itemsCountPerPage={perPage}
                                            totalItemsCount={totalRecords}
                                            onChange={getClickedPageNo}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UserAuthLayout>
        </>
    )
}

export default EscrowTable