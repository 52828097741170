import React, { useState, useImperativeHandle, forwardRef } from "react";
import WalletConnect from "../../Components/WalletConnect";
import { getShortAddress } from "../../utils";

const SwappingCardRight = forwardRef((
    {
        adminAddress,
        hvamUSD,
        fromToken,
        totalSupply,
        isAdmin,
        callPrice,
        callToken,
        callUpdateToken,
        adminStatus,
    },
    ref
) => {
    const [price, setPrice] = useState(0);
    const [token, setToken] = useState("");
    const [tokenFields, setTokenFields] = useState({
        address: "",
        tokenId: "",
    });

    useImperativeHandle(ref, () => ({
        resetState() {
            setPrice(0);
            setToken("");
            setTokenFields({
                address: "",
                tokenId: "",
            });
        },
    }));

    return (
        <>
            <div className="card">
                <div className="card-header pb-0 px-3">
                    <div className="row">
                        <div className="col-md-6">
                            <h6 className="mb-0">Admin Operation</h6>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                            <i className="far fa-calendar-alt me-2"></i>
                            <small>
                            1 {fromToken} = {hvamUSD} USD
                            </small>
                        </div>
                    </div>
                </div>
                <div className="card-body p-3">
                    <form role="form">
                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <label for="EndDate">Admin Address</label>
                                <span className="badge bg-gradient-info">{getShortAddress(adminAddress)}</span>
                            </div>
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <label for="EndDate">Token Sold</label>
                                <small>{totalSupply}</small>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label for="EndDate">Price Change</label>
                            </div>
                            <div className="col-12 col-md-8">
                                <input
                                    type="text"
                                    name="hvam_coin"
                                    className="form-control form-control-lg"
                                    placeholder="Price Change"
                                    aria-label="Price Change"
                                    onfocus="focused(this)"
                                    onfocusout="defocused(this)"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <button
                                    type="button"
                                    disabled={!isAdmin || price <= 0}
                                    className="btn btn-warning btn-lg px-3 w-100 mb-0"
                                    onClick={() => callPrice(price)}
                                >
                                    Price Change
                                </button>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-12">
                                <label for="EndDate">Add Token To ICO</label>
                            </div>
                            <div className="col-12 col-md-8">
                                <input
                                    type="text"
                                    name="hvam_coin"
                                    className="form-control form-control-lg"
                                    placeholder="0"
                                    aria-label="0"
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <button
                                    type="button"
                                    className="btn btn-warning btn-lg px-3 w-100 mb-0"
                                >
                                    Add
                                </button>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-12 mt-3">
                                <h6 className="mb-2">Token Stuck</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <label for="EndDate">Reciving Address</label>
                                <input
                                    type="text"
                                    name="hvam_coin"
                                    className="form-control form-control-lg"
                                    placeholder="Reciving Account Address"
                                    aria-label="Reciving Account Address"
                                    onfocus="focused(this)"
                                    onfocusout="defocused(this)"
                                    value={tokenFields.address}
                                    onChange={(e) =>
                                        setTokenFields({ ...tokenFields, address: e.target.value })
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <label for="EndDate">Set Token</label>
                                <input
                                    type="text"
                                    name="hvam_coin"
                                    className="form-control form-control-lg"
                                    placeholder="Set Token"
                                    aria-label="Set Token"
                                    onfocus="focused(this)"
                                    onfocusout="defocused(this)"
                                    value={tokenFields.tokenId}
                                    onChange={(e) =>
                                        setTokenFields({ ...tokenFields, tokenId: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3">
                                <button
                                    type="button"
                                    disabled={
                                        !isAdmin ||
                                        tokenFields.tokenId === "" ||
                                        tokenFields.address === ""
                                    }
                                    onClick={() => {
                                        callUpdateToken(tokenFields.address, tokenFields.tokenId);
                                    }}
                                    className="btn btn-warning btn-lg px-3 w-100 mb-0"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        {/* <div className="row mt-3">
                            <div className="col-12">
                                <label for="EndDate">Token Address</label>
                            </div>
                            <div className="col-12 col-md-8">
                                <input
                                    type="text"
                                    name="hvam_coin"
                                    className="form-control form-control-lg"
                                    placeholder="Token Address"
                                    aria-label="Token Address"
                                    onfocus="focused(this)"
                                    onfocusout="defocused(this)"
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <button
                                    type="button"
                                    disabled={!isAdmin || token === ""}
                                    className="btn btn-warning btn-lg px-3 w-100 mb-0"
                                    onClick={() => callToken(token)}
                                >
                                    Token Address
                                </button>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-12 text-center">
                                <WalletConnect
                                    variant="btn-warning"
                                    className="btn btn-lg btn-lg w-100 mt-4 mb-0"
                                />
                            </div>
                            <div className="col-12 text-center text-success mt-1 h5 text-capitalize">
                                {adminStatus}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
});

export default SwappingCardRight;
