import React from 'react'
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { UserContext } from '../../../Context/UserContext';
import UserAuthLayout from '../../../Layout/UserAuthLayout'
import { AuthUserApi } from '../../../utils/api';
import validateChangePassword from '../../../validate/validateChangePassword';
import useForm from "../../../useForm";

const ChangeUserPassword = () => {
    const { user, setUser } = useContext(UserContext);

    const { id } = user?.user || {};

    const submit = async () => {
        try {
            const getUserResponse = await AuthUserApi.post(`/user/change-password/${id}`, values);
            const { status, data } = getUserResponse;
            if (status === 201 && data.user !== undefined) {
                setValues(formInputObj);
                toast.success(`Password change successfully`);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            // setErrors
            const { response } = e;

            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    const formInputObj = {
        current_password: "",
        password: "",
        confirm_password: ""
    };

    const { handleChange, handleSubmit, values, errors, setErrors, setValues } = useForm(
        submit,
        validateChangePassword,
        formInputObj
    );

    return (
        <>
            <UserAuthLayout>
                <div className="container-fluid py-4">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header pb-0">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0">Change Password</h5>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <p className="text-uppercase text-sm">Password Details</p>
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label>Old Password</label>
                                                    <input
                                                        type="password"
                                                        name="current_password"
                                                        className="form-control"
                                                        placeholder="Old Password" 
                                                        value={values.current_password}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.current_password && <small className="text-danger">{errors.current_password}</small>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label>New Password</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="New Password" 
                                                        value={values.password}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.password && <small className="text-danger">{errors.password}</small>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label>Confirm Password</label>
                                                    <input
                                                        type="password"
                                                        name="confirm_password"
                                                        className="form-control"
                                                        placeholder="Confirm Password" 
                                                        value={values.confirm_password}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.confirm_password && <small className="text-danger">{errors.confirm_password}</small>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-end">
                                                <button type="submit" className="btn bg-gradient-primary me-3 mb-0">Save</button>
                                                <button type="button" onClick={() => setValues(formInputObj)} className="btn bg-gradient-danger mb-0">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </UserAuthLayout>
        </>
    )
}

export default ChangeUserPassword