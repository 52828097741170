import React from "react";
import AdminAuthLayout from "../../../Layout/AdminAuthLayout";
import CoinLogo from "../../../assets/img/usdt.png";
import { GiCancel } from "react-icons/gi";

import { escrowAddress, escrowABI, TOKENS } from "./../../../Contract";
import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import { getShortAddress } from "../../../utils";
import _uniqBy from "lodash/uniqBy";
import { useSigner, useProvider, useAccount } from "wagmi";

const BlockchainSetting = () => {
  const library = useProvider({
    chainId: 56,
  });
  const { data: signer } = useSigner();
  const { isConnected: active, address: account } = useAccount();

  const [nativeToken, setNativeToken] = useState("");
  const [allowedTokens, setAllowedTokens] = useState([]);
  const [adminAccount, setAdminAccount] = useState("");
  const [contract, setContract] = useState(null);
  const [newToken, setNewToken] = useState("");
  const [newAdmin, setNewAdmin] = useState("");
  const [txStatus, setTxStatus] = useState("");
  const [totalDeals, setTotalDeals] = useState(0);
  const [checkAdminAddr, setCheckAdminAddr] = useState("");
  const [checkAdmin, setCheckAdmin] = useState(false);

  const getContract = (address, ABI) => {
    const contract = new ethers.Contract(address, ABI, library);

    return contract.connect(signer);
  };

  const removeToken = async (address) => {
    try {
      setTxStatus("Confirm Transaction on wallet.");

      const tx = await contract.removeFromAllowedTokenList([address]);
      setTxStatus("Waiting for confirmations");

      await tx.wait();
      setTxStatus("Token Removed.");

      updateAllowedTokensList();
    } catch (err) {
      setTxStatus(err?.error?.data?.message || err?.error?.message || "Transaction Reverted");
    }
  };

  const addToken = async () => {
    try {
      setTxStatus("Confirm Transaction on wallet.");

      const tx = await contract.addToAllowedTokenList([newToken]);
      setTxStatus("Waiting for confirmations");

      await tx.wait();
      setTxStatus("Token Added.");

      updateAllowedTokensList();
    } catch (err) {
      setTxStatus(err?.error?.data?.message || err?.error?.message || "Transaction Reverted");
    }
  };

  const addAdmin = async () => {
    try {
      setTxStatus("Confirm Transaction on wallet.");

      const tx = await contract.addNewAdmins([newAdmin]);
      setTxStatus("Waiting for confirmations");

      await tx.wait();
      setTxStatus("New Admin Added.");
    } catch (err) {
      setTxStatus(err?.error?.data?.message || err?.error?.message || "Transaction Reverted");
    }
  };

  const updateAllowedTokensList = () => {
    (Object.keys(TOKENS) || []).map(async (token) => {
      const { ADDRESS } = TOKENS[token];
      
      if (ADDRESS) {
        const isAllowed = await contract.allowedTokens(ADDRESS);
        
        if (isAllowed) {
          setAllowedTokens((oldValue) => _uniqBy([...oldValue, { address: ADDRESS, token: token }], "token"));
        }
      }
    });
  };

  const checkAdminHandler = async () => {
    const isAdmin = await contract.admins(checkAdminAddr);

    setCheckAdmin(isAdmin);
  }

  const removeAdminHandler = async () => {
    await contract.removeAdmins([checkAdminAddr]);

    setCheckAdminAddr("");
    setCheckAdmin(false);
  }

  useEffect(() => {
    if (library && signer) {
      setContract(getContract(escrowAddress, escrowABI));
    } else {
      setContract(null);
    }
  }, [library, account, active, signer]);

  useEffect(() => {
    if (contract !== null) {
      contract
        .platformNativeTokenAddress()
        .then((res) => {
          setNativeToken(res);
        })
        .catch((err) => {
          console.log(err);
        });

      contract
        .superAdmin()
        .then((res) => setAdminAccount(res))
        .catch((err) => {
          console.log(err);
        });

      contract.dealCount().then((res) => { setTotalDeals(res.toNumber()); }).catch((err) => { console.log(err); })

      updateAllowedTokensList();
    }
  }, [contract]);

  useEffect(() => {
    if(checkAdminAddr === "") {
      setCheckAdmin(false);
    }

  }, [checkAdminAddr]);

  return (
    <>
        <AdminAuthLayout>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12">
                    <div className="card">
                        <div className="card-body p-3">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4">
                            <div className="card">
                                <div className="card-body p-3">
                                <div className="row">
                                    <div className="col-8">
                                    <div className="numbers">
                                        <h5 className="font-weight-bolder">{totalDeals}</h5>
                                    </div>
                                    </div>
                                    <div className="col-4 text-end">
                                    <div className="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                                        <i
                                        className="fa fa-list text-lg opacity-10"
                                        aria-hidden="true"
                                        ></i>
                                    </div>
                                    </div>
                                    <div className="col-12">
                                    <p className="text-sm mb-0 text-uppercase font-weight-bold">
                                        Total Deals
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="card mt-3 mb-3 mb-md-0">
                                <div className="card-body p-3">
                                <p className="text-sm mb-3">Native Token Address</p>
                                <div className="d-flex">
                                    <h5 className="font-weight-bolder mb-0 me-2">
                                    HVAM
                                    </h5>
                                    <span className="badge badge-sm bg-gradient-info">
                                    {getShortAddress(nativeToken)}
                                    </span>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4">
                            <div className="card">
                                <div className="card-header d-flex pb-0 p-3 me-2">
                                <h5 className="font-weight-bolder mb-0 me-2">
                                    Admin
                                </h5>
                                <span className="badge badge-sm bg-gradient-info">
                                    {getShortAddress(adminAccount)}
                                </span>
                                </div>
                                <div className="card-body p-3">
                                <ul className="list-group">
                                    {/* <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-0 border-radius-lg">
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-column">
                                        <h6 className="mb-1 text-dark text-sm"><span className="badge badge-sm bg-gradient-secondary">Admin Address one</span></h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <button className="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove Admin" data-container="body" data-animation="true"><GiCancel size={20} /></button>
                                    </div>
                                    </li> */}
                                </ul>
                                </div>
                            </div>
                            
                            <div className="mt-4">
                                <label for="file">Admin Address</label>
                                <input
                                onChange={(e) => setNewAdmin(e.target.value)}
                                type="text"
                                multiple
                                name="address"
                                className="form-control"
                                placeholder="Admin Address"
                                aria-label="Admin Address"
                                />
                            </div>
                            <div className="text-end my-3">
                                <button
                                disabled={!active}
                                onClick={addAdmin}
                                type="button"
                                className="btn bg-gradient-warning"
                                >
                                Add
                                </button>
                            </div>

                            <div className="mt-4">
                                <label for="file">Check Admin</label>
                                <input
                                onChange={(e) => setCheckAdminAddr(e.target.value)}
                                type="text"
                                multiple
                                name="address"
                                className="form-control"
                                placeholder="Admin Address"
                                aria-label="Admin Address"
                                />
                            </div>

                            <div className="text-end my-3">
                                {(checkAdmin === true && checkAdminAddr !== "") && (
                                <button
                                    disabled={!active}
                                    onClick={removeAdminHandler}
                                    type="button"
                                    className="btn bg-gradient-danger mx-2"
                                >
                                    Remove
                                </button>
                                )}

                                <button
                                disabled={!active}
                                onClick={checkAdminHandler}
                                type="button"
                                className="btn bg-gradient-warning"
                                >
                                Check
                                </button>
                            </div>


                            </div>
                            <div className="col-12 col-sm-12 col-md-4">
                                <div className="card">
                                    <div className="card-header pb-0 p-3">
                                    <h6 className="mb-0">List of Allowed Tokens</h6>
                                    </div>
                                    <div className="card-body p-3">
                                        <ul className="list-group">
                                            {(_uniqBy((allowedTokens || []), "token")).map(({ token, address }) => (
                                            <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                <div className="d-flex align-items-center">
                                                <div className="icon icon-shape icon-sm me-3 shadow text-center">
                                                    <img
                                                    src={CoinLogo}
                                                    className="img-fluid"
                                                    alt=""
                                                    />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h6 className="mb-1 text-dark text-sm">
                                                    {token} Coin
                                                    </h6>
                                                    <span className="text-xs text-uppercase">
                                                    {token} <span class="badge bg-gradient-info">{getShortAddress(address)}</span>
                                                    </span>
                                                </div>
                                                </div>
                                                <div className="d-flex">
                                                <button
                                                    disabled={!active}
                                                    onClick={() => removeToken(address)}
                                                    className="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title="Remove Token"
                                                    data-container="body"
                                                    data-animation="true"
                                                >
                                                    <GiCancel size={20} />
                                                </button>
                                                </div>
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            {/* <div className="mt-4">
                                <label for="file">Add Token</label>
                                <input
                                onChange={(e) => setNewToken(e.target.value)}
                                type="text"
                                multiple
                                name="address"
                                className="form-control"
                                placeholder="Token Address"
                                aria-label="Token Address"
                                />
                            </div>
                            <div className="text-end mt-3 mb-0 mb-md-3">
                                <button
                                disabled={!active && newToken !== ""}
                                onClick={addToken}
                                type="button"
                                className="btn bg-gradient-warning"
                                >
                                Add
                                </button>
                            </div> */}
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 text-danger">
                            {txStatus}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </AdminAuthLayout>
    </>
  );
};

export default BlockchainSetting;
