import React, { useEffect, useState } from "react";
import UserAuthLayout from "../../../Layout/UserAuthLayout";
import { Link } from "react-router-dom";
import { FaArrowCircleRight } from "react-icons/fa";
import WallateModal from "../WallateModal/WallateModal";
import { AuthUserApi } from "../../../utils/api";
import ValueUpdateCard from "../../../Components/ValueUpdateCard";
import LatestOrderList from "../../Escrow/LatestEscrowList";

const Dashboard = () => {
    const [data, setData] = useState("");
    const [load, setLoad] = useState(true);
    const [dashboardCount, setDashboardCount] = useState({});
    const [escrows, setEscrows] = useState([]);

    const getData = async () => {
        try {
            const listResponse = await AuthUserApi.get(`/user/dashboard-data`);
            const { status, data } = listResponse;
            if (status === 200 && data.dashboardCount !== undefined) {
                setDashboardCount(data.dashboardCount);
                setEscrows(data.escrows);
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const url = "https://api.spacexdata.com/v4/launches/next";
        //setLoad(true);
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            setData(data);
            setLoad(false);
          });
        //setLoad(false);
      }, []);

    return (
        <>
            <UserAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-8">
                            <div className="row">
                                <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                    <ValueUpdateCard
                                        Count={dashboardCount?.allOrder}
                                        Title="All Order"
                                        Bgcolor="bg-gradient-secondary"
                                        Icon="fa fa-list"
                                    />
                                </div>
                                <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                    <ValueUpdateCard
                                        Count={ dashboardCount?.totalAmt?.invoiceAmt ? parseFloat(dashboardCount?.totalAmt?.invoiceAmt) + parseFloat(dashboardCount?.totalAmt?.additionalAmt) : 0}
                                        Title="Payment Received for sales"
                                        Bgcolor="bg-gradient-success"
                                        Icon="fa fa-list"
                                    />
                                </div>
                                <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                    <ValueUpdateCard
                                        Count={dashboardCount?.completedOrder}
                                        Title="Completed Order"
                                        Bgcolor="bg-gradient-success"
                                        Icon="fa fa-check-double"
                                    />
                                </div> 
                                <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                    <ValueUpdateCard
                                        Count={dashboardCount?.inProgressOrder}
                                        Title="Processing Order"
                                        Bgcolor="bg-gradient-warning"
                                        Icon="fa fa-spinner"
                                    />
                                </div>
                                <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                    <ValueUpdateCard
                                        Count={dashboardCount?.pendingOrder}
                                        Title="Pending Order"
                                        Bgcolor="bg-gradient-info"
                                        Icon="fa fa-clock-o"
                                    />
                                </div>
                                <div className="col-xl-6 col-sm-6 col-12 mb-xl-3 mb-4">
                                    <ValueUpdateCard
                                        Count={dashboardCount?.rejectedOrder}
                                        Title="Rejected Order"
                                        Bgcolor="bg-gradient-danger"
                                        Icon="fa fa-times"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <div className="card">
                                        <div className="card-header p-3 pb-0">
                                            <h5 className="font-weight-bolder mb-0">Escrow</h5>
                                        </div>
                                        <div className="card-body p-3">
                                            <p>Protect your international payments with a fully digital escrow. Buyer’s money is securely held and released to the seller on delivery of goods/service/Freelance.</p>
                                            <Link to="/create-eccrow/0">Create Escrow <FaArrowCircleRight/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 d-flex align-items-strech">
                            <div className="card mb-4 w-100">
                                <div className="card-body pt-4 p-3">
                                    <h6 className="text-uppercase text-body text-xs font-weight-bolder mb-3">Latest Orders</h6>
                                    <ul className="list-group">
                                        <LatestOrderList
                                            escrows={escrows}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WallateModal/>
            </UserAuthLayout>
        </>
    );
};

export default Dashboard;
