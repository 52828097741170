import React, { useState } from "react";
import { GuestApi } from "../../../utils/api";
import {  Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const ForgetPasswordForm = (props) => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState([]);

    const handleChange = event => {
        const { value } = event.target;
        setEmail(value);
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!email) {
            formIsValid = false;
            errors["email"] = "Email is required.";
        }
        setErrors({ ...errors, errors });
        return formIsValid;
    }

    const submit = async (e) => {
        try {
            e.preventDefault();
            if (handleValidation()) {
                var saveResponse = await GuestApi.post('/admin/admin-exist', { email });
                const { status, data } = saveResponse;
                
                if (status === 200 && data.admin !== undefined) {
                    toast.success("Check Email to Reset Password.");
                }
            }
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            }
        }
    }

    return (
        <>
            <Form>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control className="form-control-lg" type="email" name="email" value={email} onChange={handleChange} placeholder="Enter Email" />
                    {errors.email && <small className="text-danger">{errors.email}</small>}
                </Form.Group>
                <Button variant="warning" onClick={e => submit(e)} size="lg" className="w-100 mb-0">
                    Submit
                </Button>
            </Form>
        </>
    );
}
export default ForgetPasswordForm;
