import React, { useState, useEffect } from "react";
import EscowDocFormGrid from './EscowDocFormGrid';
import { Button  } from "react-bootstrap";

export default function EscrowDocForm(props) {

    const {handleSubmit, values, setValues, errors} = props;

    const [documentRows, setDocumentRows] = useState(values.escrow_documents);
    
    const addDocumentRow = () => {
        const pushObj = {
            documentName       : "",
            documentUrl        : ""
        }
        setDocumentRows([...documentRows, pushObj]);
        setValues({
            ...values,
            escrow_documents: [...documentRows, pushObj]
        });
    }

    const changeDocumentRow = (indx, name, val) => {
        documentRows.map((docRow, ind) => {
            if(ind === indx){
                docRow[name] = val;
            }
        });
        setDocumentRows([...documentRows]);
        setValues({
            ...values,
            escrow_documents: [...documentRows]
        });
    }

    const removeDocumentRow = (id) => {
        const newDocRows = documentRows.filter((document, index) => index !== id);
        setDocumentRows([...newDocRows]);
        setValues({
            ...values,
            escrow_documents: [...newDocRows]
        });
    }

    useEffect(() => {
        if (values.escrow_documents && values.escrow_documents.length !== 0) {
            let filterDoc = values.escrow_documents.filter(vehDoc => vehDoc.documentName !== null);
            setDocumentRows(filterDoc);
        }
    }, [values.escrow_documents]);

    return (
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="row justify-content-xl-start">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h6>Additional Document</h6>
                </div>
                {documentRows && documentRows.map((document, k) =>
                    <EscowDocFormGrid
                        key={k}
                        document={document}
                        changeDocumentRow={changeDocumentRow}
                        removeDocumentRow={removeDocumentRow}
                        ind={k}
                        errors={errors}
                    />
                )}
                 <div className="col-12 text-end mt-3">
                    <Button
                        color="primary"
                        aria-label="add row"
                        className="add-btn mb-4 btn btn-primary px-5"
                        onClick={addDocumentRow}
                        title="Add Documents"
                        >
                        Add
                    </Button>
                </div>
                {/* <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                    <div className="form-group m-0 mb-0">
                        <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn-danger brown-btn fs-14 px-4">{actionName}</button>
                        <button type="button" className="btn btn-secondary fs-14 px-4 ml-4" onClick={toggleDrawer}>Cancel</button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
