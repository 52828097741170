import React from "react";
import GuestLayout from "../../../Layout/GuestLayout";
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = (props) => {

    return (
        <>
            <GuestLayout
                Title="Attention is the new currency"
                Subtitle="The more effortless the writing looks, the more effort the
                        writer actually put into the process."
                FormTitle= {"Forgot Password"}
                FormSubTitle= {"Enter your email to check your account"}
            >
                <ForgotPasswordForm/>            
            </GuestLayout>
        </>
    );
}
export default ForgotPassword;
