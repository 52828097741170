import React from "react";
import { useParams } from 'react-router-dom';
import GuestLayout from "../../../Layout/GuestLayout";
import ResetPasswordForm from "./ResetPasswordForm";

const AdminResetPassword = (props) => {

    const { email } = useParams();

    return (
        <>
            <GuestLayout
                Title="Attention is the new currency"
                Subtitle="The more effortless the writing looks, the more effort the
                        writer actually put into the process."
                FormTitle={'Reset Password'}
                FormSubTitle={'Enter your password and confirm password'}
            >
                <ResetPasswordForm
                    email={email}
                />
            </GuestLayout>
        </>
    );
}
export default AdminResetPassword;
