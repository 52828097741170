import React from "react";
import AdminAuthLayout from "../../../Layout/AdminAuthLayout";

const MyProfile = () => {
    return (
        <>
            <AdminAuthLayout>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header pb-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0">My Profile</h5>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="text-uppercase text-sm">Profile Details</p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label
                                                    for="example-text-input"
                                                    className="form-control-label"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value="lucky.jesse"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label
                                                    for="example-text-input"
                                                    className="form-control-label"
                                                >
                                                    Email address
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    value="jesse@example.com"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label
                                                    for="example-text-input"
                                                    className="form-control-label"
                                                >
                                                    Mobile Number
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value="Jesse"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-end">
                                            <button type="button" className="btn bg-gradient-primary me-3 mb-0">Save</button>
                                            <button type="button" className="btn bg-gradient-danger mb-0">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminAuthLayout>
        </>
    );
};

export default MyProfile;
