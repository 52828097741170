import React from 'react'
import AdminAuthLayout from '../../Layout/AdminAuthLayout'
import SwappingCard from '../Swapping/SwappingCard'

const SwappAdmin = () => {
  return (
    <>
        <AdminAuthLayout>
            <section>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            <SwappingCard
                                show={true}
                            />
                        </div>
                    </div>
                </div>
            </section>  
        </AdminAuthLayout> 
    </>
  )
}

export default SwappAdmin
