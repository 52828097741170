import React, { useState, useEffect } from 'react'
import PaymentTransferStatus from './PaymentTransferStatus'
import { escrowAddress, escrowABI, TOKENS, expoURL } from "../../../Contract";
import { ethers } from "ethers";
import { AuthUserApi } from '../../../utils/api';
import { toast } from 'react-toastify';
import { getEncryptId } from '../../../utils/secure';
import { useSigner, useProvider, useAccount } from "wagmi";

const BuyerStatus = ({ order, role }) => {

    const { data: signer } = useSigner();
    const library = useProvider({
    chainId: 56,
  });
    const { isConnected: active, address: account } = useAccount();

    const [contract, setContract] = useState(null);
    const [transStatus, setTransStatus] = useState("");

    const getContract = (address, ABI) => {
        const contract = new ethers.Contract(address, ABI, library);
    
        return contract.connect(signer);
      };

    const cancelDeal = async () => {
        try {

            setTransStatus("Confirm transaction on wallet");

            const tx = await contract.cancelDeal(Number(order.escrowNo || 0));

            setTransStatus("Waiting For Confirmation");

            await tx.wait();

            let values = { 'escrowStatus' : 'Rejected', rejectedBy: role }
            var saveResponse = await AuthUserApi.put(`/user/transaction-update/${getEncryptId(order.id)}`, values);
            const { status, data } = saveResponse;
            
            if (status === 201 && data.escrow !== undefined) {
                toast.success('Deal Cance Successfully..');
                window.location.reload();
            }
            setTransStatus("Deal Cancelled");
        } catch (err) {
            setTransStatus(err?.error?.data?.message || err?.error?.message || "Transaction Reverted");
        }
    }

    const confirmDelivery = async () => {
        try {
            setTransStatus("Confirm transaction on wallet");

            const tx = await contract.confirmDelivery(Number(order.escrowNo || 0));

            setTransStatus("Waiting For Confirmation");

            await tx.wait();

            let values = { 'escrowStatus' : 'Delivered', deliveryDate: new Date() }
            var saveResponse = await AuthUserApi.put(`/user/transaction-update/${getEncryptId(order.id)}`, values);
            const { status, data } = saveResponse;
            
            if (status === 201 && data.escrow !== undefined) {
                toast.success('Delivery Details Updated Successfully..');
                window.location.reload();
            }

            setTransStatus("Order delivered");
        } catch (err) {
            setTransStatus(err?.error?.data?.message || err?.error?.message || "Transaction Reverted");
        }
    }

    useEffect(() => {
        if (library && signer) {
            setContract(getContract(escrowAddress, escrowABI));
        } else {
            setContract(null);
        }
    }, [library, account, active, signer]);

    return (
        <>
            <PaymentTransferStatus
                cancelDeal={cancelDeal}
                confirmDelivery={confirmDelivery}
                isConnected={active}
                transStatus={transStatus}
                role={role}
                order={order}
            />
        </>
    )
}

export default BuyerStatus