export default function validateAdmin(values) {
	let errors = {};
	if (!values.name) {
		errors.name = "Name is required";
	}
    if (!values.email) {
		errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    	errors.email = "Email address is invalid";
	}
	if (!values.mobile_no) {
		errors.mobile_no = "mobile is required";
	}
	else if (values.mobile_no.length <10) {
    	errors.mobile_no = "mobile no. need to be 10 numbers or more than 10";
	}
	if (!values.role_id) {
		errors.role_id = "Role is required";
	}
	if(values.id == undefined || values.password !== undefined){
		const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,15})");
		if (!values.password) {
			errors.password = "Password is required";
		}  else if (!strongRegex.test(values.password)) {
			errors.password = "Password should be between 6 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
		}  else if (values.password.length < 6) {
			errors.password = "Password must be min 6 characters & max 15 characters";
		}
		if (!values.confirm_password) {
			errors.confirm_password = "Confirm password is required";
		}  else if (values.confirm_password != values.password) {
			errors.confirm_password = "Password and Confirm Password not match";
		} 
	}
    return errors;
}