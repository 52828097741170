import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import { getEncryptId } from "../utils/secure";

export default function TransactionAction({ status, escrow }) {

  const { user } = useContext(UserContext);
  const { role } = user?.user || {};

  let actionText;
  let actionUrl;
  let encId = getEncryptId(escrow?.id);

  switch (status) {
    case "In Draft":
      actionText = "Complete Draft";
      actionUrl = `/create-eccrow/${encId}`;
      break;

    case "Pending":
      actionText = role == escrow?.myRole ? "View" : "Accept Request";
      actionUrl = `/transaction-request/${encId}`;
      break;

    case "In Progress":
      actionText = role == "Supplier" ? "View" : "Make Payment";
      actionUrl = `/payment-summary/${encId}`;
      break;

    case "Rejected":
      actionText = "View";
      actionUrl = `/transaction-request/${encId}`;
      break;

    case "Credited":
      actionText = "View";
      actionUrl = `/payment-summary/${encId}`;
      break;

    case "Dispatched":
      actionText = "View";
      actionUrl = `/payment-summary/${encId}`;
      break;

    case "Release Payment":
      actionText = "View";
      actionUrl = `/payment-summary/${encId}`;
      break;

    case "Delivered":
      actionText = "View";
      actionUrl = `/payment-summary/${encId}`;
      break;

    case "Deal Cancel":
      actionText = "View";
      actionUrl = `/payment-summary/${encId}`;
      break;

    case "Deal Closed":
      actionText = "View";
      actionUrl = `/payment-summary/${encId}`;
      break;

    case "Refunded":
      actionText = "View";
      actionUrl = `/payment-summary/${encId}`;
      break;

    default:
      actionText = "View";
      actionUrl = `/create-eccrow/${encId}`;
      break;
  }

  return (
    <>
      <Link
        to={actionUrl}
        type="button"
        className={`text-xs mb-0 ${actionText} ${escrow?.escrowStatus.replace(' ', '-')}`}
      >
        {actionText}
      </Link>
      {/* <p
        className={`text-xs mb-0 ${actionText} ${escrow?.escrowStatus.replace(
          " ",
          "-"
        )}`}
      >
        {actionText}f
      </p> */}
    </>
  );
}
