import React from 'react'
import LatestOrderList from './LatestEscrowList'

const LatestOrder = (props) => {
    const { escrows } = props; 

    return (
        <>
            <div className="card mb-4">
                <div className="card-body pt-4 p-3">
                    <h6 className="text-uppercase text-body text-xs font-weight-bolder mb-3">Latest Orders</h6>
                    <ul className="list-group">
                        <LatestOrderList
                            escrows={escrows}
                        />
                    </ul>
                </div>
            </div>
        </>
    )
}

export default LatestOrder